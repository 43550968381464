import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

type featureFlagValue = {
  flag: boolean,
  suspended: boolean
}

export type GetFeatureFlagResponse = { [K in string]: featureFlagValue }

export const FeatureFlagInitStateFactory: GetFeatureFlagResponse = {
  feature_flag_schoolpass_link: {
    flag: false,
    suspended: false
  }
}
export class GetFeatureFlag implements APIRequest<GetFeatureFlagResponse> {
  response: GetFeatureFlagResponse
  path = '/'
  method = HTTPMethod.GET
}
