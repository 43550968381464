import Vuex from 'vuex'

// modules
import * as meta from '@/store/modules/meta'
import * as memberAddress from '@/store/modules/memberAddress'
import * as event from '@/store/modules/event'
import * as family from '@/store/modules/family'
import * as group from '@/store/modules/group'
import * as cart from '@/store/modules/cart'
import * as order from '@/store/modules/order'
import * as orderSummary from '@/store/modules/orderSummary'
import * as orderItem from '@/store/modules/orderItem'
import * as faq from '@/store/modules/faq'
import * as pager from '@/store/modules/pager'
import * as price from '@/store/modules/price'
import * as voice from '@/store/modules/voice'
import * as profile from '@/store/modules/profile'
import * as news from '@/store/modules/news'
import * as subscription from '@/store/modules/subscription'
import * as summary from '@/store/modules/summary'
import * as presentation from '@/store/modules/presentation'

export interface RootState {
  meta: meta.IMetaState
  event: event.IEventState
  family: family.IFamilyState
  memberAddress: memberAddress.IMemberAddressState
  group: group.IGroupState
  cart: cart.ICartState
  order: order.IOrderState
  orderSummary: orderSummary.IOrderSummaryState
  orderItem: orderItem.IOrderItemState
  faq: faq.IFAQState
  pager: pager.IPagerState
  price: price.IPriceState
  voice: voice.IVoiceState
  profile: profile.IProfileState
  news: news.INewsState
  subscription: subscription.ISubscriptionState
  summary: summary.ISummaryState
  presentation: presentation.IPresentationState
}

export default () =>
  new Vuex.Store<RootState>({
    modules: {
      meta: meta.store,
      event: event.store,
      family: family.store,
      memberAddress: memberAddress.store,
      group: group.store,
      cart: cart.store,
      order: order.store,
      orderSummary: orderSummary.store,
      orderItem: orderItem.store,
      faq: faq.store,
      pager: pager.store,
      price: price.store,
      voice: voice.store,
      profile: profile.store,
      news: news.store,
      subscription: subscription.store,
      summary: summary.store,
      presentation: presentation.store
    }
  })
