import { ZipCodeSearchResult } from '@photocreate/snapsnap-api'
import { isJapanese } from '@/utils/validators/helper'

const prefecturePattern = new RegExp('(...??[都道府県])(.+)')

export const toAddress = (res: ZipCodeSearchResult) => {
  if (res.ResultInfo.Total === 0) throw new Error('Not found!')
  const Address = res.Feature[0].Property.Address
  const match = Address ? prefecturePattern.exec(Address) : null
  const prefecture = match ? match[1] : ''
  const district = match ? match[2] : ''
  // APIの仕様上「その他」「（２〜３丁目）」「（１階）」「５丁目」等が返ってくるので削除
  let formattedDistrict = district.replace(/その他|（.*）/g, '')
  if (!isJapanese(formattedDistrict)) {
    const index = formattedDistrict.split('').findIndex(char => !isJapanese(char))
    formattedDistrict = formattedDistrict.slice(0, index)
  }

  return {
    prefecture,
    district: formattedDistrict
  }
}
