import { SnapsnapGatewayBase } from './base'
import {
  PostPasswordForgot,
  Logout,
  PostPasswordReset,
  PostVerifyPasswordResetToken,
  PostSignup,
  PostVerifySignupStore,
  PostVerifyGroupInvitationToken,
  PostAcceptGroupInvitation,
  LoginCheck,
  Certify,
  PostSignoff
} from '@/infra/network/Snapsnap/requests/auth'
import { IProfileSeed } from '@/entities/Profile'
import { toProfileProps, toProfileSeedSchema } from './translator/profile'

export default class Auth extends SnapsnapGatewayBase {
  async logout() {
    return await this.apiClient.request(new Logout())
  }

  async loggedInCheck() {
    const { profile } = await this.apiClient.request(new LoginCheck())
    if (profile) {
      return {
        profile: toProfileProps(profile)
      }
    }
    return {
      profile
    }
  }

  async forgotPassword(params: { email: string }) {
    await this.apiClient.request(new PostPasswordForgot(params))
  }

  async resetPassword(params: { token: string; email: string; password: string; birthdate: string }) {
    const { token, email, password, birthdate } = params
    await this.apiClient.request(
      new PostPasswordReset({
        token,
        email,
        password,
        birthdate
      })
    )
  }

  async signup(seed: IProfileSeed) {
    const schema = toProfileSeedSchema(seed)
    return await this.apiClient.request(new PostSignup(schema))
  }

  async verifySignupStore(params: { email: string; password: string }) {
    const { store_name } = await this.apiClient.request(new PostVerifySignupStore(params))
    return store_name
  }

  async acceptGroupInvitation(params: { token: string }) {
    return await this.apiClient.request(new PostAcceptGroupInvitation(params))
  }

  async verifyGroupInvitationToken(params: { token: string; email: string }) {
    const { group_id, group_admin_name } = await this.apiClient.request(new PostVerifyGroupInvitationToken(params))
    return {
      groupId: group_id,
      groupAdminName: group_admin_name
    }
  }

  async verifyPasswordResetToken(params: { token: string; email: string }) {
    return await this.apiClient.request(new PostVerifyPasswordResetToken(params))
  }

  async certify(token: string) {
    return await this.apiClient.request(new Certify(token))
  }

  async signoff() {
    return await this.apiClient.request(new PostSignoff())
  }
}
