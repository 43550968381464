import { Snapsnap, ChildSeed } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'
import { childCacheGroup } from '@/const/cacheGroups'

export class GetChildren implements APIRequest<Snapsnap.Group.GetGroupChildrenResponse> {
  response: Snapsnap.Group.GetGroupChildrenResponse
  path: string
  method = HTTPMethod.GET
  constructor(groupId: number) {
    this.path = `/families/${groupId}/children`
  }
}

export class GetChild implements APIRequest<Snapsnap.Group.GetGroupChildResponse> {
  response: Snapsnap.Group.GetGroupChildResponse
  path: string
  method = HTTPMethod.GET
  constructor({ groupId, childId }: { groupId: number; childId: number }) {
    this.path = `/families/${groupId}/children/${childId}`
  }
}

export class PostChild implements APIRequest<Snapsnap.Group.CreateGroupChildResponse> {
  response: Snapsnap.Group.CreateGroupChildResponse
  path: string
  method = HTTPMethod.POST
  params: Snapsnap.Group.CreateGroupChildRequest
  deleteCachesAfterSuccess = childCacheGroup

  constructor({ child, groupId }: { child: ChildSeed; groupId: number }) {
    this.path = `/families/${groupId}/children`
    this.params = {
      child
    }
  }
}

export class PutChild implements APIRequest<Snapsnap.Group.UpdateGroupChildResponse> {
  response: Snapsnap.Group.UpdateGroupChildResponse
  path: string
  params: Snapsnap.Group.UpdateGroupChildRequest
  method = HTTPMethod.PUT
  deleteCachesAfterSuccess = childCacheGroup

  constructor(parmas: { groupId: number; childId: number; child: Snapsnap.Group.UpdateGroupChildRequest['child'] }) {
    const { groupId, childId, child } = parmas
    this.params = { child }
    this.path = `/families/${groupId}/children/${childId}`
  }
}

export class DeleteChild implements APIRequest<Snapsnap.Group.DeleteGroupChildResponse> {
  response: Snapsnap.Group.DeleteGroupChildResponse
  method = HTTPMethod.DELETE
  path: string
  deleteCachesAfterSuccess = childCacheGroup

  constructor(params: { groupId: number; childId: number }) {
    const { groupId, childId } = params
    this.path = `/families/${groupId}/children/${childId}`
  }
}

export class DeleteProfileImage implements APIRequest<Snapsnap.Group.DeleteGroupChildImageResponse> {
  response: Snapsnap.Group.DeleteGroupChildImageResponse
  method = HTTPMethod.DELETE
  path: string

  constructor(params: { groupId: number; childId: number }) {
    const { groupId, childId } = params
    this.path = `/families/${groupId}/children/${childId}/profile_image`
  }
}
