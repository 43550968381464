import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '@/const'

export interface ICartItemProps {
  id: number
  eventId: number
  albumId: number
  photoId: number
  priceId: number
  amount: number
  updateTs: string
  createTs: string
  deleteTs: string | null
  originalLogoUrl?: string
  hasImageEdit: boolean
  captionStr: string | null
  downloadSetDiscount: number
  downloadSetDiscounting: number
  downloadSetPrice: number | null
}

export default class CartItemEntity {
  private _props: ICartItemProps

  constructor(props: ICartItemProps) {
    this._props = props
  }

  get props() {
    return this._props
  }

  get notEdited(): boolean {
    const { originalLogoUrl, captionStr } = this._props
    return !originalLogoUrl && !captionStr
  }

  get updateTsUnixtime(): number {
    return dayjs(this._props.updateTs, DATE_TIME_FORMAT).unix()
  }

  get createTsUnixtime(): number {
    return dayjs(this._props.createTs, DATE_TIME_FORMAT).unix()
  }
}

export const CartItemPropsFactory = (props?: Partial<ICartItemProps>): ICartItemProps => {
  return {
    id: 12074,
    eventId: 10,
    albumId: 100,
    photoId: 1,
    priceId: 1,
    amount: 1,
    updateTs: '2018-12-07 07:00:00.000000',
    createTs: '2018-12-07 07:00:00.000000',
    deleteTs: null,
    hasImageEdit: false,
    originalLogoUrl: 'http://logo-sample.jpg',
    captionStr: null,
    downloadSetDiscount: 0,
    downloadSetDiscounting: 0,
    downloadSetPrice: null,
    ...props
  }
}

export const CartItemEntityFactory = (props?: Partial<ICartItemProps>): CartItemEntity => {
  return new CartItemEntity(CartItemPropsFactory(props))
}
