import { Group } from '@photocreate/snapsnap-api'
import { IGroupProps } from '@/entities/Group'

export const toGroupProps = (group: Group): IGroupProps => {
  const { id, name, admin } = group
  return {
    id,
    name,
    isAdmin: admin
  }
}

export const toGroupSchema = (group: Partial<IGroupProps>): Partial<Group> => {
  const { id, name, isAdmin } = group
  return {
    id,
    name,
    admin: isAdmin
  }
}
