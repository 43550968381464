import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

/**
 * Orders
 */
export class GetOrderSummaries implements APIRequest<Snapsnap.Order.GetOrderSummariesResponse> {
  response: Snapsnap.Order.GetOrderSummariesResponse
  params: Snapsnap.Order.GetOrderSummariesRequest
  path = '/orders'
  method = HTTPMethod.GET
  constructor({ page, perPage }: { page?: number; perPage?: number }) {
    this.params = {
      page,
      per_page: perPage
    }
  }
}
