import { Cart, CartItem } from '@photocreate/snapsnap-api'
import { ICartProps } from '@/entities/Cart'
import { IPhotoProps } from '@/entities/Photo'
import { IPriceProps } from '@/entities/Price'
import { ICartItemProps } from '@/entities/CartItem'
import { toPhotoProps } from './photo'
import { toPriceProps } from './price'

export const toCartProps = (cart: Cart): ICartProps => {
  const { subtotal, is_download, is_print, is_mailbin_available, payment, shipping, ikkatsu, selflabo, check_sum, download_set_cart_item_ids } = cart

  return {
    subtotal,
    isDownloadable: is_download,
    isPrintable: is_print,
    isMailbinAvailable: is_mailbin_available,
    payments: payment.map(({ code, fee_amount }) => ({ code, feeAmount: fee_amount })),
    shipments: shipping.map(({ code, fee_amount }) => ({ code, feeAmount: fee_amount })),
    checkSum: check_sum,
    ikkatsu,
    selflabo,
    downloadSetCartItemIds: download_set_cart_item_ids
  }
}

export const toCartItemProps = (cartItem: CartItem): { cartItem: ICartItemProps; photo: IPhotoProps; price: IPriceProps } => {
  const {
    id,
    event_id,
    album_id,
    photo,
    price,
    amount,
    delete_ts,
    has_image_edit,
    original_logo_url,
    caption_str,
    update_ts,
    create_ts,
    download_set_discount,
    download_set_discounting,
    download_data_price
  } = cartItem

  return {
    cartItem: {
      id,
      eventId: event_id,
      albumId: album_id,
      photoId: photo.id,
      priceId: price.id,
      amount,
      updateTs: update_ts,
      createTs: create_ts,
      deleteTs: delete_ts,
      hasImageEdit: has_image_edit,
      originalLogoUrl: original_logo_url,
      captionStr: caption_str || null,
      downloadSetDiscount: download_set_discount,
      downloadSetDiscounting: download_set_discounting,
      downloadSetPrice: download_data_price
    },
    photo: toPhotoProps(photo),
    price: toPriceProps(price)
  }
}
