






















import Vue from 'vue'
import Header from '@/containers/Header'
import Footer from '@/components/shared/organisms/Footer'
import AdSide from '@/components/shared/atoms/Ad/AdSide'
import AdFoot from '@/components/shared/atoms/Ad/AdFoot'
import ToastContainer from '@/containers/Toast'
import BreadCrumb from '@/containers/BreadCrumb'
import ChatBot from '@/containers/ChatBot'
import { RouteMeta } from '@/router'
import { isDesktop } from '@/utils/helper'
const DevMenu = () => import('@/containers/DevMenu')

export default Vue.extend({
  components: {
    Header,
    Footer,
    AdSide,
    AdFoot,
    DevMenu,
    BreadCrumb,
    ChatBot,
    ToastContainer
  },
  head() {
    const meta = (this.$route.meta || {}) as RouteMeta
    const { head, title } = meta
    if (!title) {
      return {
        titleTemplate: ''
      }
    }
    if (head && head.meta) {
      return {
        title,
        meta: head.meta
      }
    }
    return {
      title
    }
  },
  computed: {
    isDesktop(): boolean {
      if (isDesktop) return true
      return false
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path && ((!this.$route.meta.hideAdSide && isDesktop) || !this.$route.meta.hideAdFoot)) {
        // adManagerが入っているページに遷移した時のみ、destroySlotsを実行
        try {
          window.googletag.destroySlots()
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
})
