import { IFamilyProps } from '@/entities/Family'

export const Types = {
  STORE_FAMILIES: 'family/store_families',
  STORE_CURRENT_FAMILY: 'family/store_current_family'
}

export class StoreFamilies implements FluxStandardAction {
  type = Types.STORE_FAMILIES
  constructor(public payload: IFamilyProps[]) {}
}

export class StoreCurrentFamily implements FluxStandardAction {
  type = Types.STORE_CURRENT_FAMILY
  constructor(public payload: number) {}
}
