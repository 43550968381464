import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '@/const'

export interface IMetaState {
  latestServerTimestamp: string | null
}

export const initialState = (): IMetaState => ({
  latestServerTimestamp: process.env.NODE_ENV === 'test' ? null : dayjs().format(DATE_TIME_FORMAT)
})
