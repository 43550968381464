import { OrderSummary } from '@photocreate/snapsnap-api'
import { IOrderSummaryProps } from '@/entities/OrderSummary'

export const toOrderSummaryProps = (orderSummary: OrderSummary): IOrderSummaryProps => {
  const { id, num, create_ts, event_names } = orderSummary
  return {
    id,
    num,
    createTs: create_ts,
    eventNames: event_names
  }
}

export const toOrderSchema = (orderSummary: Partial<IOrderSummaryProps>): Partial<OrderSummary> => {
  const { id, num, createTs, eventNames } = orderSummary
  return {
    id,
    num,
    create_ts: createTs,
    event_names: eventNames
  }
}
