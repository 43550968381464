export interface IPager {
  page: number
  total: number | null
  size: number
}

export interface IPagerState {
  orderSummaries: IPager
  orderItems: {
    [orderId: number]: IPager
  }
  downloadableOrderItems: {
    [orderId: number]: IPager
  }
  photos: {
    [eventId: number]: {
      [albumId: number]: IPager
    }
  }
  voices: IPager
  news: IPager
}

export const initialState = (): IPagerState => ({
  orderSummaries: {
    page: 1,
    total: null,
    size: 10
  },
  orderItems: {},
  downloadableOrderItems: {},
  photos: {},
  voices: {
    page: 1,
    total: null,
    size: 10
  },
  news: {
    page: 1,
    total: null,
    size: 10
  }
})
