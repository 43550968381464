import { IGroupProps } from '@/entities/Group'
import { IGroupMemberProps } from '@/entities/GroupMember'
import { IChildProps } from '@/entities/Child'
import SchoolEntity, { ISchoolProps } from '@/entities/School'
import { IGroupInvitationProps } from '@/entities/GroupInvitation'
import { IGroupSetting } from '@/types/domain/group'
import { Fields as childInputFieldProps } from '@/utils/validators/form/Group/Child'

export interface IGroupState {
  byId: {
    [id: number]: IGroupProps
  }
  settings: {
    [groupId: number]: IGroupSetting
  }
  groupMembers: {
    // Use groupId as the index key
    [groupId: number]: {
      [id: number]: IGroupMemberProps
    }
  }
  children: {
    // Use groupId as the index key
    [groupId: number]: {
      [id: number]: IChildProps
    }
  }
  schools: {
    // Use groupId as the index key
    [groupId: number]: {
      [id: number]: ISchoolProps
    }
  }
  groupInvitations: {
    // Use groupId as the index key
    [groupId: number]: {
      [id: number]: IGroupInvitationProps
    }
  }
  schoolpassInputField: {
    childId: number | null
    schools: SchoolEntity[]
    schoolpassHint: string | null
    schoolCheckList: { [key: number]: boolean }
    isRegisteringNew: boolean
  } | null
  childInputField: {
    field: childInputFieldProps
    isImageDeleted: boolean
  } | null
}

export const initialState = (): IGroupState => {
  return {
    byId: {},
    settings: {},
    groupMembers: {},
    children: {},
    schools: {},
    groupInvitations: {},
    schoolpassInputField: null,
    childInputField: null
  }
}
