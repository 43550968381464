import { SnapsnapGatewayBase } from './base'
import { GetGroupMember, PostGroupMember, GetGroupMembers, DeleteGroupMember, PutGroupMember, SignupGroupMember } from '@/infra/network/Snapsnap/requests/groupMember'
import { IGroupMemberProps, IGroupMemberSeed } from '@/entities/GroupMember'
import { IProfileSeed } from '@/entities/Profile'
import { toGroupMemberProps, toGroupMemberSeed } from './translator/groupMember'
import { toProfileSeedSchema, toProfileProps } from './translator/profile'

export default class GroupMember extends SnapsnapGatewayBase {
  async getGroupMembers({ groupId }: { groupId: number }): Promise<IGroupMemberProps[]> {
    const { members } = await this.apiClient.request(new GetGroupMembers(groupId))
    return members.map(toGroupMemberProps)
  }

  async getGroupMember(params: { groupId: number; groupMemberId: number }): Promise<IGroupMemberProps> {
    const { member } = await this.apiClient.request(new GetGroupMember(params))
    return toGroupMemberProps(member)
  }

  async createGroupMember(params: { groupId: number; groupMember: IGroupMemberSeed }) {
    const { groupId, groupMember } = params
    const seed = toGroupMemberSeed(groupMember)
    await this.apiClient.request(new PostGroupMember({ groupId, member: seed }))
  }

  async deleteGroupMember(params: { groupId: number; groupMemberId: number }) {
    await this.apiClient.request(new DeleteGroupMember(params))
  }

  async updateGroupMember(params: { groupId: number; groupMemberId: number; groupMember: Partial<IGroupMemberProps> }): Promise<IGroupMemberProps> {
    const { groupId, groupMemberId, groupMember } = params
    const { member } = await this.apiClient.request(new PutGroupMember({ groupId, groupMemberId, member: groupMember }))
    return toGroupMemberProps(member)
  }

  async signupGroupMember(params: { token: string; seed: IProfileSeed }) {
    const { token, seed } = params
    const profile = toProfileSeedSchema(seed)
    const response = await this.apiClient.request(new SignupGroupMember({ token, profile }))
    return toProfileProps(response.profile)
  }
}
