import { APIRequest } from '@/infra/network/APIRequest'
import { Snapsnap } from '@photocreate/snapsnap-api'
import { HTTPMethod } from '@/infra/network/types'

export class GetSchools implements APIRequest<Snapsnap.Group.GetGroupSchoolsResponse> {
  response: Snapsnap.Group.GetGroupSchoolsResponse
  path: string
  method = HTTPMethod.GET
  constructor(groupId: number) {
    this.path = `/families/${groupId}/schools`
  }
}

export class PostSchool implements APIRequest<Snapsnap.Group.PostGroupSchoolResponse> {
  response: Snapsnap.Group.PostGroupSchoolResponse
  path: string
  params: Snapsnap.Group.PostGroupSchoolRequest
  method = HTTPMethod.POST
  constructor(params: { groupId: number; schoolPass: string }) {
    const { groupId, schoolPass } = params
    this.params = {
      school_pass: schoolPass
    }
    this.path = `/families/${groupId}/schools`
  }
}

export class VerifySchool implements APIRequest<Snapsnap.Group.VerifySchoolResponse> {
  response: Snapsnap.Group.VerifySchoolResponse
  path = '/verify_school'
  method = HTTPMethod.POST
  params: Snapsnap.Group.VerifySchoolRequest

  constructor({ hint }: { hint: string }) {
    this.params = {
      school_pass_hint: hint
    }
  }
}

export class RegisterSchoolsToChild implements APIRequest<Snapsnap.Group.RegisterSchoolsResponse> {
  response: Snapsnap.Group.RegisterSchoolsResponse
  path: string
  params: Snapsnap.Group.RegisterSchoolsRequest
  method = HTTPMethod.PUT
  constructor(params: { groupId: number; childId: number; schoolIds: number[] }) {
    const { groupId, childId, schoolIds } = params
    this.params = {
      schools: schoolIds
    }
    this.path = `/families/${groupId}/children/${childId}/schools`
  }
}

export class GetEventsBySchoolIds implements APIRequest<Snapsnap.School.GetEventsBySchoolIdsResponse> {
  response: Snapsnap.School.GetEventsBySchoolIdsResponse
  path: string
  params: Snapsnap.School.GetEventsBySchoolIdsRequest
  method = HTTPMethod.GET
  constructor(params: { schoolIds: number[] }) {
    const { schoolIds } = params
    this.params = {
      school_ids: schoolIds
    }
    this.path = '/schools/events'
  }
}
