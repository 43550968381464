import { SnapsnapGatewayBase } from './base'
import { SearchEventPhotos, GetEvent, UnlockEvent, GetEventThumbnails, GetCartQueryStatus } from '@/infra/network/Snapsnap/requests/event'
import { GetEventAlbums, GetEventCampaigns, GetCampaignStatus, GetEventPhotoPrices, GetPhotoBinary } from '@photocreate/snapsnap-api'
import { IAlbumProps } from '@/entities/Album'
import { IPhotoProps } from '@/entities/Photo'
import { IEventProps } from '@/entities/Event'
import { IEventCampaignProps } from '@/entities/EventCampaign'
import { IEventThumbnail, IEventPhotoPrices } from '@/types/domain/event'
import { ICampaignStatusProps } from '@/entities/CampaignStatus'
import { ICartQueryStatusProps } from '@/entities/C4'
import { toAlbumProps } from './translator/album'
import { toPhotoProps } from './translator/photo'
import { toEventProps, toEventThumbnail, toEventCampaignProps, toCampaignStatusProps, toEventPhotoPricesProps, toCartQueryProps } from './translator/event'

export default class Event extends SnapsnapGatewayBase {
  async getAlbums(eventId: number): Promise<IAlbumProps[]> {
    const { albums } = await this.apiClient.request(new GetEventAlbums({ pathParameter: { eventId } }))
    return albums.map(toAlbumProps)
  }

  async searchEventPhotos(params: { eventId: number; photoNos: string[] }): Promise<IPhotoProps[]> {
    const { photos } = await this.apiClient.request(new SearchEventPhotos(params))
    return photos.map(toPhotoProps)
  }

  async getEvent(eventId: number): Promise<IEventProps> {
    const { event } = await this.apiClient.request(new GetEvent(eventId))
    return toEventProps(event)
  }

  async unlockEvent(params: { eventId: number; password: string }): Promise<IEventProps> {
    const { event } = await this.apiClient.request(new UnlockEvent(params))
    return toEventProps(event)
  }

  async getEventThumbnails(params: { eventIds: number[] }): Promise<IEventThumbnail[]> {
    const { thumbnails } = await this.apiClient.request(new GetEventThumbnails(params))
    return thumbnails.map(toEventThumbnail)
  }

  async getEventCampaigns(params: { eventId: number }): Promise<IEventCampaignProps[]> {
    const request = new GetEventCampaigns({ pathParameter: params })
    const { campaigns } = await this.apiClient.request(request)

    return campaigns.map(toEventCampaignProps)
  }

  async getCampaignStatus(params: { eventId: number; campaignIds: number[] }): Promise<ICampaignStatusProps[]> {
    const request = new GetCampaignStatus({ params })
    const { campaign_status } = await this.apiClient.request(new GetCampaignStatus(request))
    return campaign_status.map(toCampaignStatusProps)
  }

  async getEventPrices(eventId: number): Promise<IEventPhotoPrices[]> {
    const { photo_prices } = await this.apiClient.request(new GetEventPhotoPrices({ pathParameter: { eventId } }))
    return photo_prices.map(toEventPhotoPricesProps)
  }

  async getBinary(params: { photoId: number }): Promise<string> {
    const request = new GetPhotoBinary({ pathParameter: params })
    const { data } = await this.apiClient.request(request)
    return data
  }

  async getCartQueryStatus(eventId: number): Promise<ICartQueryStatusProps> {
    const response = await this.apiClient.request(new GetCartQueryStatus(eventId))
    return toCartQueryProps(response)
  }
}
