import { Entry, Asset, EntryCollection } from 'contentful'
export * from './voice'
export * from './news'

// eslint-disable-next-line
export function transtormEntry<T extends U & { id: string }, U>(entry: Entry<U>): T {
  const { id } = entry.sys
  const fields = entry.fields

  return {
    ...fields,
    id
  } as T
}

export function getAssetUrl<T>(id: string, data: EntryCollection<T>): string {
  const assets: Asset[] = data.includes['Asset'] || []
  const asset = assets.find(a => a.sys.id === id)
  if (!asset) return ''
  return asset.fields.file.url
}
