import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'
import { Snapsnap, OrderSeed, PaypayOrderSeed, Ikkatsu, Selflabo, Subscription } from '@photocreate/snapsnap-api'

export class GetOrder implements APIRequest<Snapsnap.Order.GetOrderResponse> {
  response: Snapsnap.Order.GetOrderResponse
  path: string
  method = HTTPMethod.GET
  constructor(orderId: number) {
    this.path = `/orders/${orderId}`
  }
}

export class VerifyCouponCode implements APIRequest<Snapsnap.Order.VerifyCouponCodeResponse> {
  response: Snapsnap.Order.VerifyCouponCodeResponse
  method = HTTPMethod.POST
  path = '/verify_coupon_code'
  params: Snapsnap.Order.VerifyCouponCodeRequest

  constructor(code: string) {
    this.params = {
      coupon_code: code
    }
  }
}

export class CreateOrder implements APIRequest<Snapsnap.Order.CreateOrderResponse> {
  response: Snapsnap.Order.CreateOrderResponse
  method = HTTPMethod.POST
  path = '/orders'
  params: Snapsnap.Order.CreateOrderRequest

  constructor(params: { order: OrderSeed; subscription: Subscription; ikkatsu: Ikkatsu | null; selflabo: Selflabo | null }) {
    const { order, subscription, ikkatsu, selflabo } = params
    this.params = {
      order,
      subscription,
      ikkatsu: ikkatsu || undefined,
      selflabo: selflabo || undefined
    }
  }
}

export class CreatePayPayOrder implements APIRequest<Snapsnap.Order.CreatePaypayOrderResponse> {
  response: Snapsnap.Order.CreatePaypayOrderResponse
  method = HTTPMethod.POST
  path = '/orders/paypay'
  params: Snapsnap.Order.CreatePaypayOrderRequest

  constructor(params: { order: PaypayOrderSeed; subscription: Subscription; ikkatsu: Ikkatsu | null; selflabo: Selflabo | null }) {
    const { order, subscription, ikkatsu, selflabo } = params
    this.params = {
      order,
      subscription,
      ikkatsu: ikkatsu || undefined,
      selflabo: selflabo || undefined
    }
  }
}
