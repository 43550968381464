import 'reflect-metadata'
import { singleton } from 'tsyringe'
import { APIClient } from '@/infra/network/Snapsnap/APIClient'
import { IGroupProps } from '@/entities/Group'
import { Login } from '@/infra/network/Snapsnap/requests/auth'
import { GetGroups } from '@/infra/network/Snapsnap/requests/group'
import * as translator from './translator'
import Child from './child'
import C4 from './c4'
import Group from './group'
import GroupMember from './groupMember'
import Yolp from './yolp'
import Auth from './auth'
import Cart from './cart'
import School from './school'
import Profile from './profile'
import Order from './order'
import OrderSummary from './orderSummary'
import OrderItem from './orderItem'
import PaypayOrder from './paypayOrder'
import GroupInvitation from './groupInvitation'
import FAQ from './faq'
import Album from './album'
import Photo from './photo'
import Event from './event'
import News from './news'
import Voice from './voice'
import Tpoint from './tpoint'
import Credit from './credit'
import Inquiry from './inquiry'

@singleton()
export default class SnapsnapGateway {
  apiClient: APIClient
  Child: Child
  C4: C4
  Group: Group
  GroupMember: GroupMember
  Yolp: Yolp
  Auth: Auth
  Cart: Cart
  School: School
  Profile: Profile
  Order: Order
  OrderSummary: OrderSummary
  OrderItem: OrderItem
  PaypayOrder: PaypayOrder
  GroupInvitation: GroupInvitation
  FAQ: FAQ
  Album: Album
  Photo: Photo
  Event: Event
  Voice: Voice
  News: News
  Tpoint: Tpoint
  Credit: Credit
  Inquiry: Inquiry

  constructor(apiClient: APIClient) {
    this.apiClient = apiClient

    this.Child = new Child(apiClient)
    this.C4 = new C4(apiClient)
    this.Group = new Group(apiClient)
    this.GroupMember = new GroupMember(apiClient)
    this.Yolp = new Yolp(apiClient)
    this.Auth = new Auth(apiClient)
    this.Cart = new Cart(apiClient)
    this.Profile = new Profile(apiClient)
    this.School = new School(apiClient)
    this.Order = new Order(apiClient)
    this.OrderSummary = new OrderSummary(apiClient)
    this.OrderItem = new OrderItem(apiClient)
    this.PaypayOrder = new PaypayOrder(apiClient)
    this.GroupInvitation = new GroupInvitation(apiClient)
    this.FAQ = new FAQ(apiClient)
    this.Album = new Album(apiClient)
    this.Photo = new Photo(apiClient)
    this.Event = new Event(apiClient)
    this.Voice = new Voice(apiClient)
    this.News = new News(apiClient)
    this.Tpoint = new Tpoint(apiClient)
    this.Credit = new Credit(apiClient)
    this.Inquiry = new Inquiry(apiClient)
  }

  async login(params: { email: string; password: string; rememberMe?: boolean }) {
    const { email, password, rememberMe } = params
    const { profile } = await this.apiClient.request(
      new Login({
        email,
        password,
        remember_me: rememberMe
      })
    )
    return {
      profile: translator.toProfileProps(profile)
    }
  }

  // Group
  async getGroups(): Promise<IGroupProps[]> {
    const { groups } = await this.apiClient.request(new GetGroups())
    return groups.map(group => translator.toGroupProps(group))
  }
}

export const SnapsnapGatewayFactory = (): SnapsnapGateway => {
  const apiClient = new APIClient()
  return new SnapsnapGateway(apiClient)
}
