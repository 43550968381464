











import Vue from 'vue'
import LinkButton from '@/components/shared/atoms/Button/LinkButton'

export default Vue.extend({
  components: {
    LinkButton
  }
})
