import { IOrderSummaryProps } from '@/entities/OrderSummary'

export interface IOrderSummaryState {
  byId: {
    [id: number]: IOrderSummaryProps
  }
  byPage: {
    [page: number]: IOrderSummaryProps['id'][]
  }
}

export const initialState = (): IOrderSummaryState => ({
  byId: {},
  byPage: {}
})
