import { APIClient } from '@/infra/network/FeatureFlag/APIClient'
import { GetFeatureFlag } from '@/infra/network/FeatureFlag/requests'

export default class FeatureFlagGateway {
  apiClient: APIClient

  constructor(apiClient: APIClient) {
    this.apiClient = apiClient
  }

  async getFeatureFlag() {
    const request = new GetFeatureFlag()
    return await this.apiClient.request(request)
  }
}

export const FeatureFlagGatewayFactory = (): FeatureFlagGateway => {
  const apiClient = new APIClient()
  return new FeatureFlagGateway(apiClient)
}
