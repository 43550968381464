


































































import Vue from 'vue'

export default Vue.extend({
  props: {
    hideFooterMenu: {
      type: Boolean,
      default: false
    }
  }
})
