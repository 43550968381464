import { Snapsnap, GroupMemberSeed } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

export class GetGroupInvitations implements APIRequest<Snapsnap.Group.GetGroupInvitationsResponse> {
  response: Snapsnap.Group.GetGroupInvitationsResponse
  path: string
  method = HTTPMethod.GET
  constructor(groupId: number) {
    this.path = `/families/${groupId}/invitations`
  }
}

export class GetGroupInvitation implements APIRequest<Snapsnap.Group.GetGroupInvitationResponse> {
  response: Snapsnap.Group.GetGroupInvitationResponse
  method = HTTPMethod.GET
  path: string

  constructor(params: { groupId: number; groupInvitationId: number }) {
    const { groupId, groupInvitationId } = params
    this.path = `/families/${groupId}/invitations/${groupInvitationId}`
  }
}

export class PostGroupInvitation implements APIRequest<Snapsnap.Group.PostGroupInvitationResponse> {
  response: Snapsnap.Group.PostGroupInvitationResponse
  path: string
  method = HTTPMethod.POST
  params: Snapsnap.Group.PostGroupInvitationRequest

  constructor({ groupId, member }: { groupId: number; member: GroupMemberSeed }) {
    this.path = `/families/${groupId}/invitations`
    this.params = member
  }
}

export class DeleteGroupInvitation implements APIRequest<Snapsnap.Group.DeleteGroupInvitationResponse> {
  response: Snapsnap.Group.DeleteGroupInvitationResponse
  path: string
  method = HTTPMethod.DELETE
  constructor({ groupId, invitationId }: { groupId: number; invitationId: number }) {
    this.path = `/families/${groupId}/invitations/${invitationId}`
  }
}

export class AcceptGroupInvitation implements APIRequest<Snapsnap.Group.AcceptGroupInvitationResponse> {
  response: Snapsnap.Group.AcceptGroupInvitationResponse
  path = '/family_accept_invitation'
  method = HTTPMethod.POST
  params: Snapsnap.Group.AcceptGroupInvitationRequest

  constructor(token: string) {
    this.params = {
      invitation_token: token
    }
  }
}

export class ResendInvitation implements APIRequest<Snapsnap.Group.ResendGroupInvitationEmailResponse> {
  response: Snapsnap.Group.ResendGroupInvitationEmailResponse
  path: string
  method = HTTPMethod.POST

  constructor(params: { groupId: number; groupInvitationId: number }) {
    const { groupId, groupInvitationId } = params
    this.path = `/families/${groupId}/invitations/${groupInvitationId}/resend`
  }
}
