













import Vue from 'vue'
import AdManager from '@/components/shared/atoms/Ad/AdManager'

export default Vue.extend({
  components: {
    AdManager
  }
})
