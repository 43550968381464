import { SnapsnapGatewayBase } from './base'
import {
  GetCart,
  GetCartItems,
  GetCartItem,
  GetCartSummary,
  CreateCartItem,
  DeleteCartItem,
  DeleteCartItems,
  UpdateCartItem,
  GetCartEvents,
  AddCartItemLogo,
  DeleteCartItemLogo,
  DeleteCartItemCaption,
  AddCaptions,
  GetCartArticles,
  GetCartPhotosByArticle,
  ResizeCartItems,
  AddCartItemsOtherArticle
} from '@/infra/network/Snapsnap/requests/cart'
import { toCartProps, toCartItemProps } from './translator/cart'
import { ICartProps } from '@/entities/Cart'
import { CartSummary } from '@/types/domain/cartSummary'
import { ICartArticle } from '@/types/domain/cart'
import { toEventProps } from './translator/event'

export default class Cart extends SnapsnapGatewayBase {
  async getCart(): Promise<ICartProps> {
    const { cart } = await this.apiClient.request(new GetCart())
    return toCartProps(cart)
  }

  async getCartArticles(): Promise<ICartArticle[]> {
    const { articles } = await this.apiClient.request(new GetCartArticles())
    return articles.map(({ id, name, is_download, is_moovin }) => ({ id, name, isMoovin: is_moovin, isDownload: is_download }))
  }

  async getCartItems(params?: { photoId?: number; deleted?: boolean }) {
    const { items } = await this.apiClient.request(new GetCartItems(params))
    return items.map(toCartItemProps)
  }

  async getCartItem(cartItemId: number) {
    const { item } = await this.apiClient.request(new GetCartItem(cartItemId))
    return toCartItemProps(item)
  }

  async addCartItems(params: { photoId: number; photoHash: string }) {
    const { item } = await this.apiClient.request(new CreateCartItem(params))
    return toCartItemProps(item)
  }

  async getCartSummary(): Promise<{ summary: CartSummary }> {
    const { summary } = await this.apiClient.request(new GetCartSummary())

    return {
      summary: {
        totalItemsCount: summary.total_items_count
      }
    }
  }

  async deleteCartItem(cartItemId: number) {
    const { item } = await this.apiClient.request(new DeleteCartItem(cartItemId))
    return toCartItemProps(item)
  }

  async deleteCartItems(params: { photoId: number }) {
    return await this.apiClient.request(new DeleteCartItems(params))
  }

  async updateCartItem(params: { cartItemId: number; amount: number; priceId: number }) {
    const { item } = await this.apiClient.request(new UpdateCartItem(params))
    return toCartItemProps(item)
  }

  async getCartEvents() {
    const { events } = await this.apiClient.request(new GetCartEvents())
    return events.map(toEventProps)
  }

  async addCartItemLogo(cartItemId: number) {
    return await this.apiClient.request(new AddCartItemLogo(cartItemId))
  }

  async deleteCartItemLogo(cartItemId: number) {
    return await this.apiClient.request(new DeleteCartItemLogo(cartItemId))
  }

  async deleteCartItemCaption(cartItemId: number) {
    return await this.apiClient.request(new DeleteCartItemCaption(cartItemId))
  }

  async addCaptions(params: { cartItemIds: number[]; captionStr: string }) {
    return await this.apiClient.request(new AddCaptions(params))
  }

  async getPhotosByArticle(cartItemId: number): Promise<number[]> {
    const { photo_ids } = await this.apiClient.request(new GetCartPhotosByArticle(cartItemId))
    return photo_ids
  }

  async resizeCartItems(params: { articleId: number; cartItemIds: number[] }) {
    return await this.apiClient.request(new ResizeCartItems(params))
  }

  async addCartItemsOtherArticle(params: { articleId: number; cartItemIds: number[] }) {
    return await this.apiClient.request(new AddCartItemsOtherArticle(params))
  }
}
