import { SnapsnapGatewayBase } from './base'
import { toInquirySeed } from './translator/inquiry'
import { PostInquiry } from '@/infra/network/Snapsnap/requests/inquiry'

export default class Inquiry extends SnapsnapGatewayBase {
  async PostInquiry(params: { category: string; name: string | null; email: string | null; orderNumber: string | null; content: string }) {
    const seed = toInquirySeed(params)
    return await this.apiClient.request(new PostInquiry({ inquiry: seed }))
  }
}
