import { Subscription } from '@photocreate/snapsnap-api'
import { ISubscription } from '@/types/domain/member'
import { ISubscriptionProps } from '@/entities/Subscription'

export const toSubscriptionProps = (subscription: Subscription): ISubscriptionProps => {
  const { magazine_id, read_flag } = subscription
  return {
    magazineId: magazine_id,
    readFlag: read_flag
  }
}

export const toSubscriptionSchema = (subscription: ISubscription): Subscription => {
  const { magazineId, readFlag } = subscription

  return {
    magazine_id: magazineId,
    read_flag: readFlag
  }
}
