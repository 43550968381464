export interface ISchoolProps {
  id: number
  name: string
  year: number
  children: number[]
  eventIds: number[]
}

export default class SchoolEntity {
  private _props: ISchoolProps

  constructor(props: ISchoolProps) {
    this._props = props
  }

  get props(): ISchoolProps {
    return this._props
  }

  get schoolYearString(): string {
    return `${this._props.name} ${this._props.year}年度`
  }

  isRelatedToChild(childId: number): boolean {
    return this._props.children.indexOf(childId) >= 0
  }
}

export const SchoolPropsFactory = (props?: Partial<ISchoolProps>): ISchoolProps => {
  return {
    id: 1,
    name: 'フォトクリエイト幼稚園',
    year: 2008,
    children: [],
    eventIds: [],
    ...props
  }
}

export const SchoolEntityFactory = (props?: Partial<ISchoolProps>): SchoolEntity => {
  return new SchoolEntity(SchoolPropsFactory(props))
}
