import { IPager } from '@/store/modules/pager/state'

export const Types = {
  SET_ORDER_SUMMARIES_PAGER: 'pager/set_order_summaries_pager',
  SET_ORDER_ITEMS_PAGER: 'pager/set_order_items_pager',
  SET_DOWNLOADABLE_ORDER_ITEMS_PAGER: 'pager/set_downloadable_order_items_pager',
  SET_PHOTOS_PAGER: 'pager/set_photos_pager',
  SET_VOICES_PAGER: 'pager/set_voices_pager',
  SET_NEWS_PAGER: 'pager/set_news_pager'
}

export class SetOrderSummariesPager implements FluxStandardAction {
  type = Types.SET_ORDER_SUMMARIES_PAGER
  constructor(public payload: Partial<IPager>) {}
}

export class SetOrderItemsPager implements FluxStandardAction {
  type = Types.SET_ORDER_ITEMS_PAGER
  constructor(public payload: { orderId: number; pager: Partial<IPager> }) {}
}

export class SetDownloadableOrderItemsPager implements FluxStandardAction {
  type = Types.SET_DOWNLOADABLE_ORDER_ITEMS_PAGER
  constructor(public payload: { orderId: number; pager: Partial<IPager> }) {}
}

export class SetPhotosPager implements FluxStandardAction {
  type = Types.SET_PHOTOS_PAGER
  constructor(public payload: { eventId: number; albumId: number; pager: Partial<IPager> }) {}
}

export class SetVoicesPager implements FluxStandardAction {
  type = Types.SET_VOICES_PAGER
  constructor(public payload: Partial<IPager>) {}
}

export class SetNewsPager implements FluxStandardAction {
  type = Types.SET_NEWS_PAGER
  constructor(public payload: Partial<IPager>) {}
}
