import { MutationTree } from 'vuex'
import { IOrderItemState } from '@/store/modules/orderItem/state'
import { Types, StoreOrderItems, StoreOrderItemsByPage, StoreDownloadableOrderItemsByPage } from '@/store/modules/orderItem/types'

export const mutations: MutationTree<IOrderItemState> = {
  [Types.STORE_ORDER_ITEMS]: (state, action: StoreOrderItems) => {
    const { orderItems } = action.payload
    for (const orderItem of orderItems) {
      const { orderId, id } = orderItem
      state.orderItems = {
        ...state.orderItems,
        [orderId]: {
          ...state.orderItems[orderId],
          [id]: orderItem
        }
      }
    }
  },
  [Types.STORE_ORDER_ITEMS_BY_PAGE]: (state, action: StoreOrderItemsByPage) => {
    const { page, ids, orderId } = action.paylod
    state.orderItemsByPage = {
      ...state.orderItemsByPage,
      [orderId]: {
        ...state.orderItemsByPage[orderId],
        [page]: ids
      }
    }
  },
  [Types.STORE_DOWNLOADABLE_ORDER_ITEMS_BY_PAGE]: (state, action: StoreDownloadableOrderItemsByPage) => {
    const { page, ids, orderId } = action.paylod
    state.downloadableOrderItemsByPage = {
      ...state.downloadableOrderItemsByPage,
      [orderId]: {
        ...state.downloadableOrderItemsByPage[orderId],
        [page]: ids
      }
    }
  }
}
