import { ISubscriptionProps } from '@/entities/Subscription'

export interface ISubscriptionState {
  byId: {
    [magazineId: number]: ISubscriptionProps
  }
}

export const initialState = (): ISubscriptionState => ({
  byId: {}
})
