import { SnapsnapGatewayBase } from './base'
import { INewsProps, NewsPropsFactory } from '@/entities/News'

export default class News extends SnapsnapGatewayBase {
  // FIXME: Contentfulを利用する
  // eslint-disable-next-line
  async getNewsList(params: { page?: number; perPage?: number }): Promise<INewsProps[]> {
    return []
  }

  // eslint-disable-next-line
  async getNews(newsId: number): Promise<INewsProps> {
    // FIXME: Contentfulを利用する
    return NewsPropsFactory()
  }
}
