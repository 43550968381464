import { IVoiceProps } from '@/entities/Voice'

export const Types = {
  STORE_VOICES: 'voice/store_voices',
  STORE_VOICES_BY_PAGE: 'voice/store_voices_by_page'
}

export class StoreVoices implements FluxStandardAction {
  type = Types.STORE_VOICES
  constructor(public payload: IVoiceProps[]) {}
}

export class StoreVoicesByPage implements FluxStandardAction {
  type = Types.STORE_VOICES_BY_PAGE
  constructor(public payload: { page: number; ids: IVoiceProps['id'][] }) {}
}
