import { Store } from 'vuex'
import { RootState } from '@/store'
import * as types from '@/store/modules/faq/types'
import FAQContentEntity, { IFAQContentProps } from '@/entities/FAQContent'
import FAQSectionEntity, { IFAQSectionProps } from '@/entities/FAQSection'
import FAQCategoryEntity, { IFAQCategoryProps } from '@/entities/FAQCategory'

export default class FAQRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  saveContents(contents: IFAQContentProps[]) {
    this._store.commit(new types.StoreContents(contents))
  }

  /**
   * 2019/4/12 - Shin Ando
   * Content単体で直接取り出せるようなStore設計になっているため、filteringするような実装にしています
   *
   * 2019.4現在の想定Contents数総計は~200件くらいなので許容していますが、過度に増加する際はStoreの
   * 設計を見直してください。
   */
  getContentsBySection(sectionId: number): FAQContentEntity[] {
    const contents = Object.values(this._store.state.faq.contents)
    const bySection = contents.filter(content => content.sectionId === sectionId)
    return bySection.map(content => new FAQContentEntity(content))
  }

  getContents(): FAQContentEntity[] {
    const contents = Object.values(this._store.state.faq.contents)
    return contents.map(content => new FAQContentEntity(content))
  }

  getPromotedContents(): FAQContentEntity[] {
    const contents = Object.values(this._store.state.faq.contents)
    const promoted = contents.filter(content => content.promoted)
    return promoted.map(props => new FAQContentEntity(props))
  }

  saveContent(content: IFAQContentProps) {
    this.saveContents([content])
  }

  getContent(id: number): FAQContentEntity | null {
    const props = this._store.state.faq.contents[id]
    return props ? new FAQContentEntity(props) : null
  }

  saveSections(params: { categoryId: number; sections: IFAQSectionProps[] }) {
    this._store.commit(new types.StoreSections(params))
  }

  getSections(categoryId: number): FAQSectionEntity[] {
    const sections = this._store.state.faq.sections[categoryId]
    if (!sections) return []
    return Object.values(sections).map(section => new FAQSectionEntity(section))
  }

  saveSection(params: { categoryId: number; section: IFAQSectionProps }) {
    const { categoryId, section } = params
    this.saveSections({ categoryId, sections: [section] })
  }

  getSection(params: { categoryId: number; sectionId: number }): FAQSectionEntity | null {
    const { categoryId, sectionId } = params
    const sections = this._store.state.faq.sections[categoryId]
    if (!sections) return null
    const props = sections[sectionId]
    return props ? new FAQSectionEntity(props) : null
  }

  saveCategories(categories: IFAQCategoryProps[]) {
    this._store.commit(new types.StoreCategories(categories))
  }

  saveCategory(category: IFAQCategoryProps) {
    this.saveCategories([category])
  }

  getCategories(): FAQCategoryEntity[] {
    const categories = Object.values(this._store.state.faq.categories)
    return categories.map(props => new FAQCategoryEntity(props))
  }

  getCategory(categoryId: number): FAQCategoryEntity | null {
    const props = this._store.state.faq.categories[categoryId]
    return props ? new FAQCategoryEntity(props) : null
  }
}

export const FAQRepositoryFactory = (store: Store<RootState>): FAQRepository => {
  return new FAQRepository(store)
}
