import { SnapsnapGatewayBase } from './base'
import { GetTpoints } from '@/infra/network/Snapsnap/requests/tpoint'
import { Tpoint } from '@/types/domain/tpoint'

export default class TpointGateway extends SnapsnapGatewayBase {
  async getTpoints(): Promise<{ tpoints: { normal: Tpoint } }> {
    const { normal } = await this.apiClient.request(new GetTpoints())

    // 2019.5現在、期間限定ポイントは利用していない - 20190525 Shin Ando
    return {
      tpoints: {
        normal: {
          expirationDate: normal.expiration_date,
          amount: normal.amount
        }
      }
    }
  }
}
