import { Store } from 'vuex'
import { RootState } from '@/store'
import SubscriptionEntity, { ISubscriptionProps } from '@/entities/Subscription'
import { StoreSubscription } from '@/store/modules/subscription/types'

export default class SubscriptionRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getSubscriptions(): SubscriptionEntity[] {
    const data = Object.values(this._store.state.subscription.byId)
    return data.map(props => new SubscriptionEntity(props))
  }

  saveSubscriptions(subscriptions: ISubscriptionProps[]) {
    this._store.commit(new StoreSubscription(subscriptions))
  }

  getSubscriptionById(subscriptionId: number): SubscriptionEntity | null {
    const props = this._store.state.subscription.byId[subscriptionId]
    if (!props) return null
    return new SubscriptionEntity(props)
  }
}

export const SubscriptionRepositoryFactory = (store: Store<RootState>): SubscriptionRepository => {
  return new SubscriptionRepository(store)
}
