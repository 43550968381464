import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

/**
 * orderItem
 */
export class GetOrderItem implements APIRequest<Snapsnap.Order.GetOrderItemResponse> {
  response: Snapsnap.Order.GetOrderItemResponse
  path: string
  method = HTTPMethod.GET

  constructor({ orderId, orderItemId }: { orderId: number; orderItemId: number }) {
    this.path = `/orders/${orderId}/items/${orderItemId}`
  }
}
export class GetOrderItems implements APIRequest<Snapsnap.Order.GetOrderItemsResponse> {
  response: Snapsnap.Order.GetOrderItemsResponse
  path: string
  method = HTTPMethod.GET
  params: Snapsnap.Order.GetOrderItemsRequest
  constructor({ orderId, page, perPage, downloadable }: { orderId: number; page?: number; perPage?: number; downloadable?: boolean }) {
    this.path = `/orders/${orderId}/items/`
    this.params = {
      page,
      per_page: perPage,
      downloadable
    }
  }
}

export class GetOrderItemsByPhotos implements APIRequest<Snapsnap.Order.GetOrderItemsByPhotosResponse> {
  response: Snapsnap.Order.GetOrderItemsByPhotosResponse
  path = '/orders/items'
  method = HTTPMethod.GET
  params: Snapsnap.Order.GetOrderItemsByPhotosRequest

  constructor({ photoIds, eventId }: { photoIds?: number[]; eventId: number }) {
    this.params = {
      photo_ids: photoIds,
      event_id: eventId
    }
  }
}
