













import Vue from 'vue'
import { oneOf } from '@/utils/helper'

export default Vue.extend({
  props: {
    setColor: {
      type: String,
      default: 'black',
      validator: oneOf(['black', 'grey', 'white', 'orange'])
    },
    setDirection: {
      type: String,
      default: 'right',
      validator: oneOf(['right', 'left'])
    },
    size: {
      type: String,
      default: 'md',
      validator: oneOf(['md', 'sm'])
    }
  }
})
