export interface IFAQCategoryProps {
  id: number
  name: string
  outdated: boolean
}

export default class FAQCategoryEntity {
  private _props: IFAQCategoryProps

  constructor(props: IFAQCategoryProps) {
    this._props = props
  }

  get props(): IFAQCategoryProps {
    return this._props
  }
}

export const FAQCategoryPropsFactory = (props?: Partial<IFAQCategoryProps>): IFAQCategoryProps => {
  return {
    id: 877937,
    name: '会員登録について',
    outdated: false,
    ...props
  }
}

export const FAQCategoryEntityFactory = (props?: Partial<IFAQCategoryProps>): FAQCategoryEntity => {
  return new FAQCategoryEntity(FAQCategoryPropsFactory(props))
}
