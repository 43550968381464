import { MutationTree } from 'vuex'
import { IOrderSummaryState } from '@/store/modules/orderSummary/state'
import { Types, StoreOrderSummaries, StoreOrderSummariesByPage } from '@/store/modules/orderSummary/types'

export const mutations: MutationTree<IOrderSummaryState> = {
  [Types.STORE_ORDER_SUMMARIES]: (state, action: StoreOrderSummaries) => {
    const orderSummaries = action.payload
    const hash = orderSummaries.reduce((acc: IOrderSummaryState['byId'], ac) => ((acc[ac.id] = ac), acc), {})

    state.byId = {
      ...state.byId,
      ...hash
    }
  },
  [Types.STORE_ORDER_SUMMARIES_BY_PAGE]: (state, action: StoreOrderSummariesByPage) => {
    const { ids, page } = action.payload

    state.byPage = {
      ...state.byPage,
      [page]: ids
    }
  }
}
