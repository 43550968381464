import { Snapsnap, Group } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'
import { IGroupSetting } from '@/types/domain/group'
import { groupCacheGroup, groupSettingsGroup } from '@/const/cacheGroups'

/**
 * Groups
 */
export class GetGroups implements APIRequest<Snapsnap.Group.GetGroupsResponse> {
  response: Snapsnap.Group.GetGroupsResponse
  path = '/families'
  method = HTTPMethod.GET
}

export class PostGroups implements APIRequest<Snapsnap.Group.PostGroupResponse> {
  response: Snapsnap.Group.PostGroupResponse
  params: Snapsnap.Group.PostGroupRequest
  path: string
  method = HTTPMethod.POST
  deleteCachesAfterSuccess = groupCacheGroup

  constructor(relationshipId: number) {
    this.path = '/families'
    this.params = { relationship_id: relationshipId }
  }
}

export class GetGroup implements APIRequest<Snapsnap.Group.GetGroupResponse> {
  response: Snapsnap.Group.GetGroupResponse
  path: string
  method = HTTPMethod.GET

  constructor(groupId: number) {
    this.path = `/families/${groupId}`
  }
}

export class PutGroup implements APIRequest<Snapsnap.Group.UpdateGroupResponse> {
  response: Snapsnap.Group.UpdateGroupResponse
  path: string
  params: Snapsnap.Group.UpdateGroupRequest
  method = HTTPMethod.PUT
  deleteCachesAfterSuccess = groupCacheGroup

  constructor({ groupId, group }: { groupId: number; group: Partial<Group> }) {
    this.params = { group }
    this.path = `/families/${groupId}`
  }
}

export class DeleteGroup implements APIRequest<Snapsnap.Group.DeleteGroupResponse> {
  response: Snapsnap.Group.DeleteGroupResponse
  path: string
  method = HTTPMethod.DELETE
  constructor(groupId: number) {
    this.path = `/families/${groupId}`
  }
}

export class GetGroupSetting implements APIRequest<Snapsnap.Group.GetGroupSettingResponse> {
  response: Snapsnap.Group.GetGroupSettingResponse
  path: string
  method = HTTPMethod.GET

  constructor(groupId: number) {
    this.path = `/families/${groupId}/setting`
  }
}

export class UpdateGroupSetting implements APIRequest<Snapsnap.Group.UpdateGroupSettingResponse> {
  response: Snapsnap.Group.UpdateGroupSettingResponse
  path: string
  method = HTTPMethod.PUT
  params: Snapsnap.Group.UpdateGroupSettingRequest
  deleteCachesAfterSuccess = groupSettingsGroup

  constructor(params: { groupId: number; setting: IGroupSetting }) {
    const { groupId, setting } = params
    const { isUchinokoSearchEnabled, children } = setting
    this.path = `/families/${groupId}/setting`
    this.params = {
      is_uchinoko_search_enabled: isUchinokoSearchEnabled,
      children
    }
  }
}
