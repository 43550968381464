import { Module } from 'vuex'
import { RootState } from '@/store'
import { mutations } from '@/store/modules/presentation/mutations'
import { IPresentationState, initialState } from '@/store/modules/presentation/state'

export * from '@/store/modules/presentation/state'

export const store: Module<IPresentationState, RootState> = {
  state: initialState,
  mutations
}
