import { Snapsnap, GroupMemberSeed, ProfileSeed } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'
import { IGroupMemberProps } from '@/entities/GroupMember'

export class GetGroupMembers implements APIRequest<Snapsnap.Group.GetGroupMembersResponse> {
  response: Snapsnap.Group.GetGroupMembersResponse
  path: string
  method = HTTPMethod.GET
  constructor(groupId: number) {
    this.path = `/families/${groupId}/members`
  }
}

export class GetGroupMember implements APIRequest<Snapsnap.Group.GetGroupMemberResponse> {
  response: Snapsnap.Group.GetGroupMemberResponse
  path: string
  method = HTTPMethod.GET
  constructor({ groupId, groupMemberId }: { groupId: number; groupMemberId: number }) {
    this.path = `/families/${groupId}/members/${groupMemberId}`
  }
}

export class PostGroupMember implements APIRequest<Snapsnap.Group.PostGroupInvitationResponse> {
  response: Snapsnap.Group.PostGroupInvitationResponse
  path: string
  method = HTTPMethod.POST
  params: Snapsnap.Group.PostGroupInvitationRequest

  constructor({ member, groupId }: { member: GroupMemberSeed; groupId: number }) {
    this.path = `/families/${groupId}/invitations`
    this.params = member
  }
}

export class PutGroupMember implements APIRequest<Snapsnap.Group.PutGroupMemberResponse> {
  response: Snapsnap.Group.PutGroupMemberResponse
  path: string
  params: Snapsnap.Group.PutGroupMemberRequest
  method = HTTPMethod.PUT
  constructor({ groupId, groupMemberId, member }: { groupId: number; groupMemberId: number; member: Partial<Pick<IGroupMemberProps, 'relationshipId'>> }) {
    this.path = `/families/${groupId}/members/${groupMemberId}`
    this.params = {
      member: {
        relationship_id: member.relationshipId
      }
    }
  }
}

export class DeleteGroupMember implements APIRequest<Snapsnap.Group.DeleteGroupMemberResponse> {
  response: Snapsnap.Group.DeleteGroupMemberResponse
  path: string
  method = HTTPMethod.DELETE
  constructor({ groupId, groupMemberId }: { groupId: number; groupMemberId: number }) {
    this.path = `/families/${groupId}/members/${groupMemberId}`
  }
}

export class SignupGroupMember implements APIRequest<Snapsnap.Group.SignupGroupMemberResponse> {
  response: Snapsnap.Group.SignupGroupMemberResponse
  params: Snapsnap.Group.SignupGroupMemberRequest
  path = '/family_member_signup'
  method = HTTPMethod.POST
  constructor(params: { token: string; profile: ProfileSeed }) {
    this.params = params
  }
}
