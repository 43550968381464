import { RELATIONSHIPS } from '@/const'

export interface IGroupInvitationProps {
  id: number
  memberId: number
  email: string
  nickname: string
  relationshipId: number
  inviteeMemberId: number | null
  expireTs: string
  status: '招待済み' | '招待中' | '招待期限切れ'
}

export default class GroupInvitationEntity {
  private _props: IGroupInvitationProps

  constructor(props: IGroupInvitationProps) {
    this._props = props
  }

  get props(): IGroupInvitationProps {
    return this._props
  }

  get relationship(): { key: string; name: string } {
    let convertedRelationship: number
    if (this.props.relationshipId === 3) {
      // id3: 祖母を変換
      convertedRelationship = 4
    } else if (this.props.relationshipId === 5) {
      // id5: 祖父を変換
      convertedRelationship = 6
    } else if (this.props.relationshipId === 7) {
      // id7: おばを変換
      convertedRelationship = 8
    } else if (this.props.relationshipId === 9) {
      // id9: おじを変換
      convertedRelationship = 10
    } else {
      convertedRelationship = this.props.relationshipId
    }
    const relationship = RELATIONSHIPS[convertedRelationship]
    return relationship || { key: 'others', name: 'その他' }
  }
}

export const GroupInvitationPropsFactory = (props?: Partial<IGroupInvitationProps>): IGroupInvitationProps => {
  return {
    id: 1,
    memberId: 3385914,
    email: 'invitation_test@photocreate.co.jp',
    nickname: 'じいじ',
    relationshipId: 6,
    inviteeMemberId: 279784,
    expireTs: '2019-06-07 00:00:00.000000',
    status: '招待済み',
    ...props
  }
}

export const GroupInvitationEntityFactory = (props?: Partial<IGroupInvitationProps>): GroupInvitationEntity => {
  return new GroupInvitationEntity(GroupInvitationPropsFactory(props))
}
