import { IOrderItemProps } from '@/entities/OrderItem'

type orderItemId = number
export interface IOrderItemState {
  orderItems: {
    [orderId: number]: {
      [id: number]: IOrderItemProps
    }
  }
  orderItemsByPage: {
    [orderId: number]: {
      [page: number]: orderItemId[]
    }
  }
  downloadableOrderItemsByPage: {
    [orderId: number]: {
      [page: number]: orderItemId[]
    }
  }
}

export const initialState = (): IOrderItemState => ({
  orderItems: {},
  orderItemsByPage: {},
  downloadableOrderItemsByPage: {}
})
