import { Album } from '@photocreate/snapsnap-api'
import { IAlbumProps } from '@/entities/Album'

export const toAlbumProps = (album: Album): IAlbumProps => {
  const { id, name, parent_id, display_order } = album

  return {
    id,
    name,
    parentId: parent_id,
    displayOrder: display_order
  }
}
