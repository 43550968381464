/* eslint-disable */

/*
 ** userHeatのscriptは /support 配下のパスにのみ適用する
 */

export default ({ app }) => {
  /*
   ** クライアントサイドかつプロダクションモードでのみ実行
   */
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  // UserInsightのスクリプトは /support 配下のページを訪れた初回のみ発火させる。
  let calledUserInsightScript = false

  /*
   ** ルートが変更されるたびに毎回実行（初期化も実行される）
   */
  app.router.afterEach((to, from) => {
    if (!to.path.includes('/support', 0)) {
      return
    }

    if (!calledUserInsightScript) {
      /*
       ** UserInsightのスクリプトをインクルード
       */
      window._uic = window._uic || {}
      window._uih = window._uih || {}
      window._uih['id'] = 55105
      window._uih['lg_id'] = ''
      window._uih['fb_id'] = ''
      window._uih['tw_id'] = ''
      window._uih['uigr_1'] = ''
      window._uih['uigr_2'] = ''
      window._uih['uigr_3'] = ''
      window._uih['uigr_4'] = ''
      window._uih['uigr_5'] = ''
      window._uih['uigr_6'] = ''
      window._uih['uigr_7'] = ''
      window._uih['uigr_8'] = ''
      window._uih['uigr_9'] = ''
      window._uih['uigr_10'] = ''
      window._uic['uls'] = 1

      /* DO NOT ALTER BELOW THIS LINE */
      /* WITH FIRST PARTY COOKIE */
      ;(function() {
        var bi = document.createElement('script')
        bi.type = 'text/javascript'
        bi.async = true
        bi.src = '//cs.nakanohito.jp/b3/bi.js'
        var s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(bi, s)
      })()

      calledUserInsightScript = true
    }

    /*
     ** 遷移先のpathに/supportが含まれない場合はreturn
     */
    if (!to.path.includes('/support', 0)) {
      return
    }

    /*
     ** User Insight にページビューが追加されたことを伝える
     */
    if (typeof window._uic['send_pv'] !== 'undefined') {
      window._uih['url'] = document.URL
      window._uih['sb'] = document.title
      window._uih['ref'] = document.referrer
      window._uic['send_pv'](window._uih, window._uic)
    }
  })
}
