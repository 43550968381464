import { Face, Photo } from '@photocreate/snapsnap-api'
import { UchinokoData } from '@/types/domain/uchinoko'
import { toFaceProps } from './face'
import { toPhotoProps } from './photo'

export const toUchinokoData = (params: { face: Face; photos: Photo[]; albums: { id: number; photo_ids: number[] }[]; similarities: { photo_id: number; similarity: number }[] }): UchinokoData => {
  const { face, photos, albums, similarities } = params

  return {
    face: toFaceProps(face),
    photos: photos.map(toPhotoProps),
    albums: albums.map(({ id, photo_ids }) => ({ id, photoIds: photo_ids })),
    similarities
  }
}
