import { IProfileProps, IProfileFields, IProfileSeed } from '@/entities/Profile'

export const Types = {
  STORE_PROFILE: 'profile/store_profile',
  CLEAR_PROFILE: 'profile/clear_profile',
  STORE_SIGNUP_INPUT_FIELD_MAILADDRESS: 'profile/store_signup_input_field_mailaddress',
  CLEAR_SIGNUP_INPUT_FIELD_MAILADDRESS: 'profile/clear_signup_input_field_mailaddress',
  STORE_PROFILE_INPUT_FIELD_CREATE: 'profile/store_profile_input_field_create',
  CLEAR_PROFILE_INPUT_FIELD_CREATE: 'profile/clear_profile_input_field_create',
  STORE_PROFILE_INPUT_FIELD_UPDATE: 'profile/store_profile_input_field_update',
  CLEAR_PROFILE_INPUT_FIELD_UPDATE: 'profile/clear_profile_input_field_update'
} as const

export class StoreProfile implements FluxStandardAction {
  type = Types.STORE_PROFILE
  constructor(public payload: IProfileProps) {}
}

export class ClearProfile implements FluxStandardAction {
  type = Types.CLEAR_PROFILE
}

export class StoreInputSignupMailaddress implements FluxStandardAction {
  type = Types.STORE_SIGNUP_INPUT_FIELD_MAILADDRESS
  constructor(public payload: Pick<IProfileSeed, 'email'>) {}
}

export class ClearInputSignupMailAddress implements FluxStandardAction {
  type = Types.CLEAR_SIGNUP_INPUT_FIELD_MAILADDRESS
}

// 会員情報新規登録
export class StoreInputProfileCreate implements FluxStandardAction {
  type = Types.STORE_PROFILE_INPUT_FIELD_CREATE
  constructor(public payload: Omit<IProfileSeed, 'email' | 'password' | 'tlsc'>) {}
}

export class ClearInputProfileCreate implements FluxStandardAction {
  type = Types.CLEAR_PROFILE_INPUT_FIELD_CREATE
}

// 会員情報変更
export class StoreInputProfileUpdate implements FluxStandardAction {
  type = Types.STORE_PROFILE_INPUT_FIELD_UPDATE
  constructor(public payload: IProfileFields) {}
}

export class ClearInputProfileUpdate implements FluxStandardAction {
  type = Types.CLEAR_PROFILE_INPUT_FIELD_UPDATE
}
