import { ZendeskSection, ZendeskArticle, ZendeskCategory } from '@photocreate/snapsnap-api'
import { IFAQContentProps } from '@/entities/FAQContent'
import { IFAQSectionProps } from '@/entities/FAQSection'
import { IFAQCategoryProps } from '@/entities/FAQCategory'

export const toSectionProps = (section: ZendeskSection): IFAQSectionProps => {
  const { id, name, category_id, outdated } = section
  return {
    id,
    name,
    categoryId: category_id || null,
    outdated
  }
}

export const toContentProps = (article: ZendeskArticle): IFAQContentProps => {
  const { id, title, body, promoted, section_id, label_names } = article
  return {
    id,
    title,
    body,
    promoted,
    sectionId: section_id || null,
    labelNames: label_names
  }
}

export const toCategoryProps = (category: ZendeskCategory): IFAQCategoryProps => {
  const { id, name, outdated } = category
  return {
    id,
    name,
    outdated
  }
}
