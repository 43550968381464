import { Module } from 'vuex'
import { RootState } from '@/store/index.ts'
import { mutations } from '@/store/modules/meta/mutations'
import { IMetaState, initialState } from '@/store/modules/meta/state'

export * from '@/store/modules/meta/state'

export const store: Module<IMetaState, RootState> = {
  state: initialState,
  mutations
}
