import { MutationTree } from 'vuex'
import { IOrderState } from '@/store/modules/order/state'
import { Types, StoreOrders, StorePayPayOrders } from '@/store/modules/order/types'

export const mutations: MutationTree<IOrderState> = {
  [Types.STORE_ORDERS]: (state, action: StoreOrders) => {
    const orders = action.payload
    const hash = orders.reduce((acc: IOrderState['byId'], ac) => ((acc[ac.id] = ac), acc), {})

    state.byId = {
      ...state.byId,
      ...hash
    }
  },
  [Types.STORE_PAYPAY_ORDERS]: (state, action: StorePayPayOrders) => {
    state.paypayOrderResponse = action.payload
  }
}
