import { Snapsnap, InquirySeed } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

export class PostInquiry implements APIRequest<Snapsnap.Inquiry.PostInquiryResponse> {
  response: Snapsnap.Inquiry.PostInquiryResponse
  path = '/inquiries'
  method = HTTPMethod.POST
  params: Snapsnap.Inquiry.PostInquiryRequest

  constructor({ inquiry }: { inquiry: InquirySeed }) {
    this.params = {
      inquiry
    }
  }
}
