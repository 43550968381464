import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

export class GetAlbumPhotos implements APIRequest<Snapsnap.Album.GetAlbumPhotosResponse> {
  response: Snapsnap.Album.GetAlbumPhotosResponse
  path: string
  method = HTTPMethod.GET
  params: Snapsnap.Album.GetAlbumPhotosRequest
  constructor({ albumId, page, include }: { albumId: number; page?: number; include?: number }) {
    this.path = `/albums/${albumId}/photos`
    this.params = {
      page,
      include
    }
  }
}
