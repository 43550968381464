import { EntryCollection, Entry } from 'contentful'
import { IVoiceProps } from '@/entities/Voice'
import Voice from '@/gateways/ContentfulGateway/types/voice'
import { transtormEntry, getAssetUrl } from '@/gateways/ContentfulGateway/translator'
import { Raw } from '@/gateways/ContentfulGateway/types'

export function toVoiceProps(data: Entry<Raw<Voice>>, collection: EntryCollection<object>): IVoiceProps {
  const { id, content, title, author, article_image }: Voice = transtormEntry(data)
  return {
    id,
    content,
    title,
    author,
    articleImage: getAssetUrl(article_image.sys.id, collection)
  }
}
