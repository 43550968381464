import { render, staticRenderFns } from "./orderComplete.vue?vue&type=template&id=0a2a8cc4&scoped=true&"
import script from "./orderComplete.vue?vue&type=script&lang=ts&"
export * from "./orderComplete.vue?vue&type=script&lang=ts&"
import style0 from "./orderComplete.vue?vue&type=style&index=0&id=0a2a8cc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a2a8cc4",
  null
  
)

export default component.exports