import { Nullable } from '@/types/utils'

export interface IMemberAddressProps {
  id: number
  lastname: string
  firstname: string
  lastnameRuby: string
  firstnameRuby: string
  tel: string | null
  zipcode: string | null
  prefecture: string
  address1: string | null
  address2: string | null
  address3: string | null
  hash: string
}

export interface IMemberAddressSeed {
  lastname: string
  firstname: string
  lastnameRuby: string
  firstnameRuby: string
  tel: string
  zipcode: string
  prefecture: string
  address1: string
  address2: string
  address3: string | null
}

export type IMemberAddressFields = IMemberAddressSeed

export default class MemberAddressEntity {
  private _props: IMemberAddressProps

  constructor(props: IMemberAddressProps) {
    this._props = props
  }

  get props(): IMemberAddressProps {
    return this._props
  }

  get fullnameWithRuby(): string {
    const { lastname, firstname, lastnameRuby, firstnameRuby } = this._props
    return `${lastname} ${firstname}（${lastnameRuby} ${firstnameRuby}）`
  }

  get isHalf(): boolean {
    // 会員登録時に自動生成されたMemberAddressに情報が設定されているかどうか判定
    const { tel, zipcode, address1, address2 } = this._props
    return !tel || !zipcode || !address1 || !address2
  }

  // Deprecate
  get zipCode(): string {
    const { zipcode } = this._props
    if (!zipcode) return ''
    return `〒${zipcode.slice(0, 3)}-${zipcode.slice(3, zipcode.length)}`
  }

  get zipcodeStr(): string {
    return this.zipCode
  }

  // Deprecate
  get address(): string {
    const { address1, address2 } = this._props
    return `${address1} ${address2}`
  }

  get isMemberAddressValid(): boolean {
    const { lastname, firstname, lastnameRuby, firstnameRuby, tel, zipcode, prefecture, address1, address2 } = this._props
    if (!lastname || !firstname || !lastnameRuby || !firstnameRuby || !tel || !zipcode || !prefecture || !address1 || !address2) return false
    return true
  }
}

export function isMemberAddressSeed(fields: Nullable<IMemberAddressSeed>): fields is IMemberAddressSeed {
  const { lastname, firstname, lastnameRuby, firstnameRuby, tel, zipcode, prefecture, address1, address2, address3 } = fields

  if (
    typeof lastname === 'string' &&
    typeof firstname === 'string' &&
    typeof lastnameRuby === 'string' &&
    typeof firstnameRuby === 'string' &&
    typeof tel === 'string' &&
    typeof zipcode === 'string' &&
    typeof prefecture === 'string' &&
    typeof address1 === 'string' &&
    typeof address2 === 'string'
  ) {
    // for type assertion use only
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data: IMemberAddressSeed = {
      lastname,
      firstname,
      lastnameRuby,
      firstnameRuby,
      tel,
      zipcode,
      prefecture,
      address1,
      address2,
      address3
    }

    return true
  }

  return false
}

export function isMemberAddressFields(fields: Nullable<IMemberAddressFields>): fields is IMemberAddressFields {
  const { lastname, firstname, lastnameRuby, firstnameRuby, tel, zipcode, prefecture, address1, address2, address3 } = fields

  if (
    typeof lastname === 'string' &&
    typeof firstname === 'string' &&
    typeof lastnameRuby === 'string' &&
    typeof firstnameRuby === 'string' &&
    typeof tel === 'string' &&
    typeof zipcode === 'string' &&
    typeof prefecture === 'string' &&
    typeof address1 === 'string' &&
    typeof address2 === 'string'
  ) {
    // for type assertion use only
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data: IMemberAddressFields = {
      lastname,
      firstname,
      lastnameRuby,
      firstnameRuby,
      tel,
      zipcode,
      prefecture,
      address1,
      address2,
      address3
    }

    return true
  }

  return false
}

export const MemberAddressSeedFactory = (props?: Partial<IMemberAddressSeed>): IMemberAddressSeed => ({
  lastname: '大澤',
  firstname: '朋陸',
  lastnameRuby: 'オオサワ',
  firstnameRuby: 'トモタカ',
  tel: '0423837389',
  zipcode: '1840012',
  prefecture: '東京都',
  address1: '小金井市中町',
  address2: '2-11-26',
  address3: 'アーバンステージ武蔵小金井211',
  ...props
})

export const MemberAddressPropsFactory = (props?: Partial<IMemberAddressProps>): IMemberAddressProps => ({
  id: 1,
  lastname: '大澤',
  firstname: '朋陸',
  lastnameRuby: 'オオサワ',
  firstnameRuby: 'トモタカ',
  tel: '0423837389',
  zipcode: '1840012',
  prefecture: '東京都',
  address1: '小金井市中町',
  address2: '2-11-26',
  address3: 'アーバンステージ武蔵小金井211',
  hash: 'askngajshjlfa',
  ...props
})

export const MemberAddressEntityFactory = (props?: Partial<IMemberAddressProps>): MemberAddressEntity => {
  return new MemberAddressEntity(MemberAddressPropsFactory(props))
}
