import { Photo } from '@photocreate/snapsnap-api'
import { IPhotoProps } from '@/entities/Photo'

export const toPhotoProps = (photo: Photo): IPhotoProps => {
  const {
    id,
    album_id,
    is_group_photo,
    logo_code,
    not4caption_flag,
    not4sale_flag,
    not4trim_flag,
    page_open_ts,
    page_public_code,
    photo_hash,
    photo_url,
    photographer_id,
    num,
    preview_no,
    type_code,
    visible_flag,
    visible_code,
    moovin_flag,
    spotlight_flag
  } = photo

  return {
    id,
    albumId: album_id,
    isGroupPhoto: is_group_photo,
    logoCode: logo_code,
    not4captionFlag: not4caption_flag,
    not4saleFlag: not4sale_flag,
    not4trimFlag: not4trim_flag,
    num,
    pageOpenTs: page_open_ts,
    pagePublicCode: page_public_code,
    photoHash: photo_hash,
    photoUrl: photo_url,
    photographerId: photographer_id,
    previewNo: preview_no,
    typeCode: type_code,
    visibleFlag: visible_flag,
    visibleCode: visible_code,
    moovinFlag: moovin_flag,
    spotlightFlag: spotlight_flag
  }
}
