import { Store } from 'vuex'
import { RootState } from '@/store'
import OrderEntity, { IOrderProps, IPaypayOrderProps } from '@/entities/Order'
import { StoreOrders, StorePayPayOrders } from '@/store/modules/order/types'

export default class OrderRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  saveOrder(data: IOrderProps) {
    this._store.commit(new StoreOrders([data]))
  }

  savePayPayOrder(data: IPaypayOrderProps) {
    this._store.commit(new StorePayPayOrders(data))
  }

  getOrderById(id: number) {
    const target = this._store.state.order.byId[id]
    if (!target) return null
    return new OrderEntity(target)
  }

  getPayPayOrder() {
    return this._store.state.order.paypayOrderResponse
  }
}

export const OrderRepositoryFactory = (store: Store<RootState>): OrderRepository => {
  return new OrderRepository(store)
}
