import { MutationTree } from 'vuex'
import { FACE_THRESHOLD } from '@/const'
import { IEventState, initialState } from '@/store/modules/event/state'
import { IAlbumProps } from '@/entities/Album'
import { IFaceProps } from '@/entities/Face'
import { IEventCampaignProps } from '@/entities/EventCampaign'
import {
  Types,
  StoreEvents,
  StoreEventThumbnails,
  StoreLatestEvents,
  StoreAlbums,
  StorePhotos,
  StorePhotosByPage,
  StoreSearchResult,
  StoreUchinokoFaces,
  StoreResetUchinokoFaces,
  StoreResetUchinokoPhotos,
  StoreUchinokoPhotos,
  StoreSimilarities,
  UpdateSelectedUchinokoFaces,
  InitSelectedUchinokoFaces,
  StoreCartQueryStatus,
  StoreEventCampaigns,
  StoreCampaignStatus
} from '@/store/modules/event/types'
import { IPhotoProps } from '@/entities/Photo'

export const mutations: MutationTree<IEventState> = {
  [Types.STORE_EVENTS]: (state, action: StoreEvents) => {
    const events = action.payload
    events.forEach(event => {
      state.byIds = {
        ...state.byIds,
        [event.id]: event
      }
    })
  },
  [Types.STORE_EVENT_THUMBNAILS]: (state, action: StoreEventThumbnails) => {
    const thumbnails = action.payload
    const hash = thumbnails.reduce((acc, { eventId, albumPhotoUrls, uchinokoUrls }) => ((acc[eventId] = { albumPhotoUrls, uchinokoUrls }), acc), {} as IEventState['thumbnails'])

    state.thumbnails = {
      ...state.thumbnails,
      ...hash
    }
  },
  [Types.STORE_LATEST_EVENTS]: (state, action: StoreLatestEvents) => {
    const latestEvents = action.payload
    state.latestEvents = latestEvents
  },
  [Types.STORE_ALBUMS]: (state, action: StoreAlbums) => {
    const { eventId, albums } = action.payload
    const hash = albums.reduce((acc, ac) => ((acc[ac.id] = ac), acc), {} as Record<number, IAlbumProps>)

    state.albums = {
      ...state.albums,
      [eventId]: {
        ...state.albums[eventId],
        ...hash
      }
    }
  },
  [Types.STORE_PHOTOS]: (state, action: StorePhotos) => {
    const { eventId, albumId, photos } = action.payload
    const hash = photos.reduce((acc: { [id: number]: IPhotoProps }, ac) => ((acc[ac.id] = ac), acc), {})

    state.photos = {
      ...state.photos,
      [eventId]: {
        ...state.photos[eventId],
        [albumId]: {
          ...(state.photos[eventId] ? state.photos[eventId][albumId] : []),
          ...hash
        }
      }
    }
  },
  [Types.STORE_PHOTOS_BY_PAGE]: (state, action: StorePhotosByPage) => {
    const { page, ids, eventId, albumId } = action.paylod
    state.photosByPage = {
      ...state.photosByPage,
      [eventId]: {
        ...state.photosByPage[eventId],
        [albumId]: {
          ...(state.photosByPage[eventId] ? state.photosByPage[eventId][albumId] : {}),
          [page]: ids
        }
      }
    }
  },
  [Types.STORE_SEARCH_RESULT]: (state, action: StoreSearchResult) => {
    const result = action.payload
    state.searchResult = result
  },
  [Types.CLEAR_SEARCH_RESULT]: state => {
    state.searchResult = initialState().searchResult
  },
  [Types.STORE_UCHINOKO_FACES]: (state, action: StoreUchinokoFaces) => {
    const { eventId, faces } = action.paylod
    const hash = faces.reduce((acc, ac) => ((acc[ac.id] = ac), acc), {} as Record<string, IFaceProps>)

    state.uchinokoFaces = {
      ...state.uchinokoFaces,
      [eventId]: {
        ...state.uchinokoFaces[eventId],
        ...hash
      }
    }
  },
  [Types.STORE_RESET_UCHINOKO_FACES]: (state, action: StoreResetUchinokoFaces) => {
    const { eventId } = action.paylod
    state.uchinokoFaces = {
      ...state.uchinokoFaces,
      [eventId]: {}
    }
  },
  [Types.STORE_RESET_UCHINOKO_PHOTOS]: (state, action: StoreResetUchinokoPhotos) => {
    const { eventId } = action.paylod

    state.uchinokoPhotos = {
      ...state.uchinokoPhotos,
      [eventId]: {}
    }
  },
  [Types.STORE_SIMILARITIES]: (state, action: StoreSimilarities) => {
    state.similarities = action.paylod
  },
  [Types.STORE_UCHINOKO_PHOTOS]: (state, action: StoreUchinokoPhotos) => {
    const { eventId, faceId, albumId, photoIds } = action.paylod
    const byEvent = state.uchinokoPhotos[eventId] || {}
    const byFace = byEvent[faceId] || {}
    const byAlbum = byFace[albumId] || []

    state.uchinokoPhotos = {
      ...state.uchinokoPhotos,
      [eventId]: {
        ...byEvent,
        [faceId]: {
          ...byFace,
          [albumId]: [...byAlbum, ...photoIds]
        }
      }
    }
  },
  [Types.INIT_SELECTED_UCHINOKO_FACES]: (state, action: InitSelectedUchinokoFaces) => {
    const eventId = action.payload
    const faces = state.uchinokoFaces[eventId] || {}
    // TODO: similarityが閾値を上回るものについてはtrueを指定しているが、ビジネスロジックの1つなのでStoreに書きたくない - 20190523 Shin Ando
    const data = Object.entries(faces).reduce((acc, [key, val]) => {
      acc[key] = val.similarity >= FACE_THRESHOLD
      return acc
    }, {} as { [faceId: string]: boolean })

    state.selectedUchinokoFaces = {
      ...state.selectedUchinokoFaces,
      [eventId]: {
        ...data,
        ...state.selectedUchinokoFaces[eventId]
      }
    }
  },
  [Types.UPDATE_SELECTED_UCHINOKO_FACES]: (state, action: UpdateSelectedUchinokoFaces) => {
    const { eventId, data } = action.payload

    state.selectedUchinokoFaces = {
      ...state.selectedUchinokoFaces,
      [eventId]: {
        ...state.selectedUchinokoFaces[eventId],
        ...data
      }
    }
  },
  [Types.STORE_CART_QUERY_STATUS]: (state, action: StoreCartQueryStatus) => {
    const { eventId, cartQueryStatus } = action.payload

    state.cartQueryStatus = {
      [eventId]: {
        ...cartQueryStatus
      }
    }
  },
  [Types.STORE_EVENT_CAMPAIGNS]: (state, action: StoreEventCampaigns) => {
    const { eventId, campaigns } = action.paylod

    const hash = campaigns.reduce((acc, ac) => {
      acc[ac.id] = ac
      return acc
    }, {} as { [campaignId: number]: IEventCampaignProps })

    // expireの管理がシビアなので、毎回eventごとにキャンペーン情報は全て上書き
    state.eventCampaigns = {
      ...state.eventCampaigns,
      [eventId]: hash
    }
  },
  [Types.STORE_CAMPAIGN_STATUS]: (state, action: StoreCampaignStatus) => {
    const convertedPayload = action.payload.reduce((acc, ac) => {
      acc[ac.campaignId] = ac
      return acc
    }, {} as IEventState['campaignStatus'])
    state.campaignStatus = {
      ...state.campaignStatus,
      ...convertedPayload
    }
  }
}
