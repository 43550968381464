import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '@/const'

export interface IOrderSummaryProps {
  id: number
  num: number
  createTs: string
  eventNames: string[]
}
export default class OrderSummaryEntity {
  private _props: IOrderSummaryProps

  constructor(props: IOrderSummaryProps) {
    this._props = props
  }

  get props(): IOrderSummaryProps {
    return this._props
  }

  get orderDate(): string {
    const date = dayjs(this._props.createTs, DATE_TIME_FORMAT)
    return date.format('YYYY年MM月DD日')
  }

  get orderDateUnixtime(): number {
    return dayjs(this._props.createTs, DATE_TIME_FORMAT).unix()
  }
}

export const OrderSummaryPropsFactory = (props?: Partial<IOrderSummaryProps>): IOrderSummaryProps => {
  return {
    id: 1,
    num: 1,
    createTs: '2019-03-18 10:00:00.000',
    eventNames: ['舞子幼稚園 遠足（うめ１・うめ４・さくら２）', '舞子幼稚園 遠足'],
    ...props
  }
}

export const OrderSummaryEntityFactory = (props?: Partial<IOrderSummaryProps>): OrderSummaryEntity => {
  return new OrderSummaryEntity(OrderSummaryPropsFactory(props))
}
