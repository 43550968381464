import { IMemberAddressProps, IMemberAddressSeed } from '@/entities/MemberAddress'

export const Types = {
  STORE_MEMBER_ADDRESSES: 'memberAddress/store_member_addresses',
  REMOVE_MEMBER_ADDRESS: 'memberAddress/remove_member_address',
  STORE_MEMBER_ADDRESS_INPUT_FIELD: 'memberAddress/store_member_address_input_field',
  CLEAR_MEMBER_ADDRESS_INPUT_FIELD: 'memberAddress/clear_member_address_input_field'
}

export class StoreMemberAddresses implements FluxStandardAction {
  type = Types.STORE_MEMBER_ADDRESSES
  constructor(public payload: IMemberAddressProps[]) {}
}

export class RemoveMemberAddress implements FluxStandardAction {
  type = Types.REMOVE_MEMBER_ADDRESS
  constructor(public payload: IMemberAddressProps['id']) {}
}

export class StoreInputMemberAddress implements FluxStandardAction {
  type = Types.STORE_MEMBER_ADDRESS_INPUT_FIELD
  constructor(public payload: IMemberAddressSeed) {}
}

export class ClearInputMemberAddress implements FluxStandardAction {
  type = Types.CLEAR_MEMBER_ADDRESS_INPUT_FIELD
}
