const middleware = {}

middleware['adminOnly'] = require('../app/middleware/adminOnly.ts');
middleware['adminOnly'] = middleware['adminOnly'].default || middleware['adminOnly']

middleware['checkAuth'] = require('../app/middleware/checkAuth.ts');
middleware['checkAuth'] = middleware['checkAuth'].default || middleware['checkAuth']

middleware['loginCheck'] = require('../app/middleware/loginCheck.ts');
middleware['loginCheck'] = middleware['loginCheck'].default || middleware['loginCheck']

middleware['requireAuth'] = require('../app/middleware/requireAuth.ts');
middleware['requireAuth'] = middleware['requireAuth'].default || middleware['requireAuth']

middleware['retentionAuth'] = require('../app/middleware/retentionAuth.ts');
middleware['retentionAuth'] = middleware['retentionAuth'].default || middleware['retentionAuth']

export default middleware
