import { GroupInvitation } from '@photocreate/snapsnap-api'
import { IGroupInvitationProps } from '@/entities/GroupInvitation'

export const toGroupInvitationProps = (member: GroupInvitation): IGroupInvitationProps => {
  const { id, email, nickname, member_id, relationship_id, invitee_member_id, expire_ts, status } = member
  return {
    id,
    email,
    nickname,
    memberId: member_id,
    inviteeMemberId: invitee_member_id,
    expireTs: expire_ts,
    // @ts-ignore
    status,
    relationshipId: relationship_id
  }
}
