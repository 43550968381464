import { SnapsnapGatewayBase } from './base'
import { GetAlbumPhotos } from '@/infra/network/Snapsnap/requests/album'
import { toPhotoProps } from './translator'

export default class Album extends SnapsnapGatewayBase {
  async getAlbumPhotos(params: { albumId: number; page?: number; include?: number }) {
    const { photos, total, current_page } = await this.apiClient.request(new GetAlbumPhotos(params))
    return {
      photos: photos.map(toPhotoProps),
      total,
      currentPage: current_page
    }
  }
}
