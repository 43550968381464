export const NON_EDITABLE_ARTICLE_IDS = [25]

export interface IPriceProps {
  id: number
  name: string
  articleId: number
  isDownload: boolean
  isMoovin: boolean
  price: number
  displayOrder: number
}

export default class PriceEntity {
  private _props: IPriceProps

  constructor(props: IPriceProps) {
    this._props = props
  }

  get props(): IPriceProps {
    return this._props
  }

  get isEditable(): boolean {
    return !NON_EDITABLE_ARTICLE_IDS.includes(this._props.articleId)
  }
}

export const PricePropsFactory = (props?: Partial<IPriceProps>): IPriceProps => ({
  id: 5083,
  name: 'L判 プリント',
  articleId: 1,
  isDownload: false,
  isMoovin: false,
  price: 324,
  displayOrder: 1,
  ...props
})

export const PriceEntityFactory = (props?: Partial<IPriceProps>): PriceEntity => {
  return new PriceEntity(PricePropsFactory(props))
}
