import { SnapsnapGatewayBase } from './base'
import { DeleteGroup, GetGroup, PutGroup, GetGroupSetting, UpdateGroupSetting, PostGroups } from '@/infra/network/Snapsnap/requests/group'
import { toGroupProps, toGroupSchema } from './translator/group'
import { IGroupProps } from '@/entities/Group'
import { IGroupSetting } from '@/types/domain/group'

export default class Group extends SnapsnapGatewayBase {
  async getGroup(groupId: number) {
    const { group } = await this.apiClient.request(new GetGroup(groupId))
    return toGroupProps(group)
  }

  async deleteGroup(groupId: number) {
    await this.apiClient.request(new DeleteGroup(groupId))
  }

  async updateGroup(params: { groupId: number; group: Partial<IGroupProps> }): Promise<IGroupProps> {
    const { groupId, group } = params
    const schema = toGroupSchema(group)
    const response = await this.apiClient.request(new PutGroup({ groupId, group: schema }))
    return toGroupProps(response.group)
  }

  async getGroupSetting(groupId: number): Promise<IGroupSetting> {
    const { setting } = await this.apiClient.request(new GetGroupSetting(groupId))
    const { is_uchinoko_search_enabled, children } = setting
    return {
      isUchinokoSearchEnabled: is_uchinoko_search_enabled,
      children
    }
  }

  async updateGroupSetting(params: { groupId: number; setting: IGroupSetting }): Promise<IGroupSetting> {
    const { setting } = await this.apiClient.request(new UpdateGroupSetting(params))
    const { is_uchinoko_search_enabled, children } = setting
    return {
      isUchinokoSearchEnabled: is_uchinoko_search_enabled,
      children
    }
  }

  async createGroups(params: { relationshipId: number }): Promise<IGroupProps> {
    const { relationshipId } = params
    const response = await this.apiClient.request(new PostGroups(relationshipId))
    return toGroupProps(response.group)
  }
}
