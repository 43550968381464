export interface ISubscriptionProps {
  magazineId: number
  readFlag: boolean
}

export default class SubscriptionEntity {
  private _props: ISubscriptionProps

  constructor(props: ISubscriptionProps) {
    this._props = props
  }

  get props(): ISubscriptionProps {
    return this._props
  }
}

export const SubscriptionPropsFactory = (props?: Partial<ISubscriptionProps>): ISubscriptionProps => {
  return {
    magazineId: 1,
    readFlag: true,
    ...props
  }
}

export const SubscriptionEntityFactory = (props?: Partial<ISubscriptionProps>): SubscriptionEntity => {
  return new SubscriptionEntity(SubscriptionPropsFactory(props))
}
