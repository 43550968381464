import { OrderItem } from '@photocreate/snapsnap-api'
import { IOrderItemProps } from '@/entities/OrderItem'
import { toOrderItemArticleProps } from './orderItemArticle'

export const toOrderItemProps = (orderItem: OrderItem): IOrderItemProps => {
  const {
    id,
    order_id,
    is_group_photo,
    logo_code,
    not4caption_flag,
    not4sale_flag,
    not4trim_flag,
    num,
    page_open_ts,
    page_public_code,
    photo_hash,
    photo_url,
    photographer_id,
    preview_no,
    type_code,
    visible_flag,
    visible_code,
    articles,
    download_url
  } = orderItem
  return {
    id,
    orderId: order_id,
    isGroupPhoto: is_group_photo,
    logoCode: logo_code,
    not4captionFlag: not4caption_flag,
    not4saleFlag: not4sale_flag,
    not4trimFlag: not4trim_flag,
    num,
    pageOpenTs: page_open_ts,
    pagePublicCode: page_public_code,
    photoHash: photo_hash,
    photoUrl: photo_url,
    photographerId: photographer_id,
    previewNo: preview_no,
    typeCode: type_code,
    visibleFlag: visible_flag,
    visibleCode: visible_code,
    articles: articles.map(toOrderItemArticleProps),
    downloadUrl: download_url
  }
}
