import Vue from 'vue'
import { Values } from '@/types/utils'

export function isDOMAccessible(): boolean {
  return process.client && !!window && !!document
}

// smooth scroll to ref is possible
/* istanbul ignore next */
export function scrollTo(val: Values<Vue['$refs']>) {
  if (!isDOMAccessible()) return
  if (!val) return

  // pick target
  let targetRef: Vue | Element
  if (Array.isArray(val)) {
    if (!val.length) return
    targetRef = val[0]
  } else {
    targetRef = val
  }

  // pick element
  let targetElement: Element
  if ('$el' in targetRef) {
    targetElement = targetRef.$el
  } else {
    targetElement = targetRef
  }

  if (!targetElement) return

  const rectTop = targetElement.getBoundingClientRect().top
  const offsetTop = window.pageYOffset
  const buffer = 50
  const top = rectTop + offsetTop - buffer
  window.scrollTo({
    top,
    behavior: 'smooth'
  })
}
