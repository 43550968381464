import { MutationTree } from 'vuex'
import { IPagerState, IPager } from '@/store/modules/pager/state'
import { Types, SetOrderSummariesPager, SetPhotosPager, SetOrderItemsPager, SetDownloadableOrderItemsPager, SetVoicesPager, SetNewsPager } from '@/store/modules/pager/types'

export const mutations: MutationTree<IPagerState> = {
  [Types.SET_ORDER_SUMMARIES_PAGER]: (state, action: SetOrderSummariesPager) => {
    const pager = action.payload

    state.orderSummaries = {
      ...state.orderSummaries,
      ...pager
    }
  },
  [Types.SET_ORDER_ITEMS_PAGER]: (state, action: SetOrderItemsPager) => {
    const { orderId, pager } = action.payload
    const defaultPager: IPager = {
      size: 10,
      page: 1,
      total: null
    }

    state.orderItems = {
      ...state.orderItems,
      [orderId]: {
        ...(state.orderItems[orderId] || defaultPager),
        ...pager
      }
    }
  },
  [Types.SET_DOWNLOADABLE_ORDER_ITEMS_PAGER]: (state, action: SetDownloadableOrderItemsPager) => {
    const { orderId, pager } = action.payload
    const defaultPager: IPager = {
      size: 10,
      page: 1,
      total: null
    }

    state.downloadableOrderItems = {
      ...state.downloadableOrderItems,
      [orderId]: {
        ...(state.downloadableOrderItems[orderId] || defaultPager),
        ...pager
      }
    }
  },
  [Types.SET_PHOTOS_PAGER]: (state, action: SetPhotosPager) => {
    const { eventId, albumId, pager } = action.payload
    const defaultPager: IPager = {
      size: 30,
      page: 1,
      total: null
    }

    state.photos = {
      ...state.photos,
      [eventId]: {
        ...state.photos[eventId],
        [albumId]: {
          ...defaultPager,
          ...(state.photos[eventId] ? state.photos[eventId][albumId] : defaultPager),
          ...pager
        }
      }
    }
  },
  [Types.SET_VOICES_PAGER]: (state, action: SetVoicesPager) => {
    const pager = action.payload
    state.voices = {
      ...state.voices,
      ...pager
    }
  },
  [Types.SET_NEWS_PAGER]: (state, action: SetNewsPager) => {
    const pager = action.payload

    state.news = {
      ...state.news,
      ...pager
    }
  }
}
