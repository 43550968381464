import { SnapsnapGatewayBase } from './base'
import { IOrderItemProps } from '@/entities/OrderItem'
import { GetOrderItem, GetOrderItems, GetOrderItemsByPhotos } from '@/infra/network/Snapsnap/requests/orderItem'
import { toOrderItemProps } from './translator/orderItem'

export default class OrderItem extends SnapsnapGatewayBase {
  async getOrderItem(params: { orderId: number; orderItemId: number }) {
    const { order_item } = await this.apiClient.request(new GetOrderItem(params))
    return toOrderItemProps(order_item)
  }

  async getOrderItems(params: { orderId: number; page: number; downloadable?: boolean }) {
    const { order_items, total } = await this.apiClient.request(new GetOrderItems(params))
    return {
      orderItems: order_items.map(toOrderItemProps),
      total
    }
  }

  async getOrderItemsByPhotos(params: { photoIds?: number[]; eventId: number }): Promise<IOrderItemProps[]> {
    const { order_items } = await this.apiClient.request(new GetOrderItemsByPhotos(params))
    return order_items.map(toOrderItemProps)
  }
}
