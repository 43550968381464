import 'reflect-metadata'
import Vue from 'vue'
import { container } from 'tsyringe'
import { Context } from '@nuxt/types'
import { Store } from 'vuex'
import { RootState } from '@/store'
// import LogService from '@/services/LogService'

export default (ctx: Context) => {
  const { store } = ctx

  // Base
  container.register<Store<RootState>>(Store, { useValue: store })

  // Inject kernel
  Vue.prototype.$kernel = container
  ctx.$kernel = container
}
