import { Context } from '@nuxt/types'

export default async function ({ $snapsnapGateway, $profileRepository, redirect }: Context) {
  try {
    const { profile } = await $snapsnapGateway.Auth.loggedInCheck()
    if (!profile) return
    $profileRepository.saveProfile(profile)
    redirect('/family')
  } catch (e) {
    // TODO: error typeによる振り分け
  }
}
