import { SnapsnapGatewayBase } from './base'
import { PostGroupInvitation, DeleteGroupInvitation, GetGroupInvitations, AcceptGroupInvitation, GetGroupInvitation, ResendInvitation } from '@/infra/network/Snapsnap/requests/groupInvitation'
import { toGroupMemberSeed } from '@/gateways/SnapsnapGateway/translator/groupMember'
import { IGroupMemberSeed } from '@/entities/GroupMember'
import { IGroupInvitationProps } from '@/entities/GroupInvitation'
import { toGroupInvitationProps } from './translator'

export default class GroupInvitation extends SnapsnapGatewayBase {
  async getGroupInvitations(groupId: number): Promise<IGroupInvitationProps[]> {
    const { group_invitations } = await this.apiClient.request(new GetGroupInvitations(groupId))
    return group_invitations.map(toGroupInvitationProps)
  }

  async getGroupInvitation(params: { groupId: number; groupInvitationId: number }) {
    const { group_invitation } = await this.apiClient.request(new GetGroupInvitation(params))
    return toGroupInvitationProps(group_invitation)
  }

  async createGroupInvitation(params: { groupId: number; groupMember: IGroupMemberSeed }) {
    const { groupId, groupMember } = params
    const seed = toGroupMemberSeed(groupMember)

    await this.apiClient.request(new PostGroupInvitation({ groupId, member: seed }))
  }

  async deleteGroupInvitation(params: { groupId: number; invitationId: number }) {
    return await this.apiClient.request(new DeleteGroupInvitation(params))
  }

  async acceptGroupInvitation(token: string) {
    return await this.apiClient.request(new AcceptGroupInvitation(token))
  }

  async resendGroupInvitation(params: { groupId: number; groupInvitationId: number }) {
    return await this.apiClient.request(new ResendInvitation(params))
  }
}
