import { MutationTree } from 'vuex'
import { INewsState } from '@/store/modules/news/state'
import { Types, StoreNewsList } from '@/store/modules/news/types'

export const mutations: MutationTree<INewsState> = {
  [Types.STORE_NEWS_LIST]: (state, action: StoreNewsList) => {
    const newsList = action.payload
    const hash = newsList.reduce((acc, ac) => ((acc[ac.id] = ac), acc), {} as INewsState['byId'])

    state.byId = {
      ...state.byId,
      ...hash
    }
  }
}
