import { resetStoreCallableType } from '@/taboo'
import SnapsnapGateway from '@/gateways/SnapsnapGateway'

export interface ILogoutUseCaseParams {
  snapsnapGateway: SnapsnapGateway
}

export default class LogoutUseCase implements BaseUseCase {
  snapsnapGateway: SnapsnapGateway

  constructor({ snapsnapGateway }: ILogoutUseCaseParams) {
    this.snapsnapGateway = snapsnapGateway
  }

  async execute(resetStoreFunction: resetStoreCallableType) {
    try {
      await this.snapsnapGateway.Auth.logout()

      // Warning: ログアウトに成功したらStoreの中身を全てリセットする
      resetStoreFunction()
    } catch (error) {
      throw error
    }
  }
}
