import { IFAQSectionProps } from '@/entities/FAQSection'
import { IFAQCategoryProps } from '@/entities/FAQCategory'
import { IFAQContentProps } from '@/entities/FAQContent'

export interface IFAQState {
  sections: {
    // Use categoryId as index
    [id: number]: {
      [id: number]: IFAQSectionProps
    }
  }
  contents: {
    [id: number]: IFAQContentProps
  }
  categories: {
    [id: number]: IFAQCategoryProps
  }
}

export const initialState = (): IFAQState => {
  return {
    sections: {},
    contents: {},
    categories: {}
  }
}
