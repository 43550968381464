import { IEventProps } from '@/entities/Event'
import { IAlbumProps } from '@/entities/Album'
import { IPhotoProps } from '@/entities/Photo'
import { IFaceProps } from '@/entities/Face'
import { IEventCampaignProps } from '@/entities/EventCampaign'
import { ICartQueryStatusProps } from '@/entities/C4'
import { IEventThumbnail } from '@/types/domain/event'
import { ICampaignStatusProps } from '@/entities/CampaignStatus'

export const Types = {
  STORE_EVENTS: 'event/store_events',
  STORE_EVENT_THUMBNAILS: 'event/store_event_thumbnails',
  STORE_LATEST_EVENTS: 'event/store_latest_events',
  STORE_ALBUMS: 'event_store_albums',
  STORE_PHOTOS: 'event/store_photos',
  STORE_PHOTOS_BY_PAGE: 'event/store_photos_by_page',
  STORE_SEARCH_RESULT: 'event/store_search_result',
  CLEAR_SEARCH_RESULT: 'event/clear_search_result',
  STORE_UCHINOKO_FACES: 'event/store_uchinoko_faces',
  STORE_RESET_UCHINOKO_FACES: 'event/store_reset_uchinoko_faces',
  STORE_RESET_UCHINOKO_PHOTOS: 'event/store_reset_uchinoko_photos',
  STORE_SIMILARITIES: 'event/store_similarities',
  STORE_UCHINOKO_PHOTOS: 'event/store_uchinoko_photos',
  INIT_SELECTED_UCHINOKO_FACES: 'event/init_selected_uchinoko_faces',
  UPDATE_SELECTED_UCHINOKO_FACES: 'event/update_selected_uchinoko_faces',
  STORE_CART_QUERY_STATUS: 'event/store_cart_query_status',
  STORE_EVENT_CAMPAIGNS: 'event/store_event_campaigns',
  STORE_CAMPAIGN_STATUS: 'campaign/store_campaign_status'
}

export class StoreEvents implements FluxStandardAction {
  type = Types.STORE_EVENTS
  constructor(public payload: IEventProps[]) {}
}

export class StoreEventThumbnails implements FluxStandardAction {
  type = Types.STORE_EVENT_THUMBNAILS
  constructor(public payload: IEventThumbnail[]) {}
}

export class StoreLatestEvents implements FluxStandardAction {
  type = Types.STORE_LATEST_EVENTS
  constructor(public payload: number[]) {}
}

export class StoreAlbums implements FluxStandardAction {
  type = Types.STORE_ALBUMS
  constructor(public payload: { eventId: number; albums: IAlbumProps[] }) {}
}

export class StorePhotos implements FluxStandardAction {
  type = Types.STORE_PHOTOS
  constructor(public payload: { eventId: number; albumId: number; photos: IPhotoProps[] }) {}
}

export class StorePhotosByPage implements FluxStandardAction {
  type = Types.STORE_PHOTOS_BY_PAGE
  constructor(public paylod: { eventId: number; albumId: number; page: number; ids: number[] }) {}
}

export class StoreSearchResult implements FluxStandardAction {
  type = Types.STORE_SEARCH_RESULT
  constructor(public payload: IPhotoProps[]) {}
}

export class ClearSearchResult implements FluxStandardAction {
  type = Types.CLEAR_SEARCH_RESULT
}

export class StoreUchinokoFaces implements FluxStandardAction {
  type = Types.STORE_UCHINOKO_FACES
  constructor(public paylod: { eventId: number; faces: IFaceProps[] }) {}
}

export class StoreResetUchinokoFaces implements FluxStandardAction {
  type = Types.STORE_RESET_UCHINOKO_FACES
  constructor(public paylod: { eventId: number }) {}
}

export class StoreResetUchinokoPhotos implements FluxStandardAction {
  type = Types.STORE_RESET_UCHINOKO_PHOTOS
  constructor(public paylod: { eventId: number }) {}
}

export class StoreSimilarities implements FluxStandardAction {
  type = Types.STORE_SIMILARITIES
  constructor(public paylod: Record<string, number>) {}
}

export class StoreUchinokoPhotos implements FluxStandardAction {
  type = Types.STORE_UCHINOKO_PHOTOS
  constructor(public paylod: { eventId: number; faceId: string; albumId: number; photoIds: number[] }) {}
}

export class InitSelectedUchinokoFaces implements FluxStandardAction {
  type = Types.INIT_SELECTED_UCHINOKO_FACES
  constructor(public payload: number) {} // eventId
}

export class UpdateSelectedUchinokoFaces implements FluxStandardAction {
  type = Types.UPDATE_SELECTED_UCHINOKO_FACES
  constructor(public payload: { eventId: number; data: { [faceId: string]: boolean } }) {}
}

export class StoreCartQueryStatus implements FluxStandardAction {
  type = Types.STORE_CART_QUERY_STATUS
  constructor(public payload: { eventId: number; cartQueryStatus: ICartQueryStatusProps }) {}
}

export class StoreEventCampaigns implements FluxStandardAction {
  type = Types.STORE_EVENT_CAMPAIGNS
  constructor(public paylod: { eventId: number; campaigns: IEventCampaignProps[] }) {}
}

export class StoreCampaignStatus implements FluxStandardAction {
  type = Types.STORE_CAMPAIGN_STATUS
  constructor(public payload: ICampaignStatusProps[]) {}
}
