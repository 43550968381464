import { SnapsnapGatewayBase } from './base'
import { GetSchools, RegisterSchoolsToChild, GetEventsBySchoolIds, PostSchool, VerifySchool } from '@/infra/network/Snapsnap/requests/school'
import { ISchoolProps } from '@/entities/School'
import { toSchoolProps } from './translator/school'
import { IEventProps } from '@/entities/Event'
import { toEventProps } from './translator/event'

export default class School extends SnapsnapGatewayBase {
  async getSchools(groupId: number): Promise<ISchoolProps[]> {
    const { schools } = await this.apiClient.request(new GetSchools(groupId))
    return schools.map(toSchoolProps)
  }

  async registerSchoolsToChild(params: { groupId: number; childId: number; schoolIds: number[] }) {
    await this.apiClient.request(new RegisterSchoolsToChild(params))
  }

  async getEventsBySchoolIds(params: { schoolIds: number[] }): Promise<IEventProps[]> {
    const { events } = await this.apiClient.request(new GetEventsBySchoolIds(params))
    return events.map(toEventProps)
  }

  async postSchool(params: { groupId: number; schoolPass: string }): Promise<{ id: number; name: string; year: number }> {
    const { school } = await this.apiClient.request(new PostSchool(params))
    const { id, name, year } = school
    return { id, name, year: parseInt(year, 10) }
  }

  async verifySchool(params: { hint: string }): Promise<ISchoolProps> {
    const { school } = await this.apiClient.request(new VerifySchool(params))
    return toSchoolProps({ ...school, children: [], event_ids: [] })
  }
}
