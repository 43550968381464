import { IPriceProps } from '@/entities/Price'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '@/const'

export interface IOrderItemArticle {
  eventId: number
  eventName: string
  price: IPriceProps
  amount: number
  subtotal: number
  discount: number
  hasOriginalLogo: boolean
  hasImageEdit: boolean
  captionStr?: string
}
export interface IOrderItemProps {
  id: number
  orderId: number
  isGroupPhoto: boolean
  logoCode: string
  not4captionFlag: boolean
  not4saleFlag: boolean
  not4trimFlag: boolean
  num: number
  pageOpenTs: string
  pagePublicCode: string
  photoHash: string
  photoUrl: {
    tn?: string
    tn2?: string
    w1?: string
    w2?: string
  }
  photographerId: number
  previewNo?: string
  typeCode?: string
  visibleFlag: boolean
  visibleCode: string
  articles: IOrderItemArticle[]
  downloadUrl: string | null
}

export default class OrderItemEntity {
  private _props: IOrderItemProps

  constructor(props: IOrderItemProps) {
    this._props = props
  }

  get props(): IOrderItemProps {
    return this._props
  }

  get openDate(): string {
    const date = dayjs(this._props.pageOpenTs, DATE_TIME_FORMAT)
    return date.format('YYYY年MM月DD日')
  }

  get photoNumber(): string {
    return this._props.previewNo ? 'No.' + this._props.previewNo : 'No.' + this._props.photographerId + '-' + this._props.num
  }

  get eventName(): string {
    // articles > articleは全て共通のeventNameを持っていると仮定
    const representData = this._props.articles[0]
    if (representData) {
      return representData.eventName
    }
    return ''
  }

  // ダウンロード商品が購入されたかどうかのフラグ
  get hasBeenDownloaded(): boolean {
    const { articles } = this._props
    return articles.some(article => article.price.isDownload)
  }
}

export const OrderItemPropsFactory = (props?: Partial<IOrderItemProps>): IOrderItemProps => {
  return {
    id: 1042686059,
    orderId: 10,
    isGroupPhoto: false,
    logoCode: 'not_set',
    not4captionFlag: false,
    not4saleFlag: false,
    not4trimFlag: false,
    num: 1418437,
    pageOpenTs: '2018-12-07 07:00:00.000000',
    pagePublicCode: 'open',
    photoHash: '0cef7313f75fcb8086af2a701fbc16e5',
    photoUrl: {
      tn: '/static/storybook/photo01.jpg',
      tn2: '/static/storybook/photo01.jpg',
      w1: '/static/storybook/photo01.jpg',
      w2: '/static/storybook/photo01.jpg'
    },
    photographerId: 3107,
    previewNo: '100',
    typeCode: 'snap',
    visibleFlag: true,
    visibleCode: 'visible',
    articles: [
      {
        eventId: 12,
        eventName: '夏のお遊戯会',
        price: {
          id: 6,
          name: 'A4プリント_L付',
          isDownload: false,
          isMoovin: false,
          articleId: 2,
          price: 324,
          displayOrder: 1
        },
        amount: 1,
        discount: 0,
        subtotal: 465,
        hasOriginalLogo: false,
        hasImageEdit: false,
        captionStr: 'フォトクリ幼稚園　夏のお遊戯会'
      }
    ],
    downloadUrl: '/static/storybook/photo01.jpg',
    ...props
  }
}

export const OrderItemEntityFactory = (props?: Partial<IOrderItemProps>): OrderItemEntity => {
  return new OrderItemEntity(OrderItemPropsFactory(props))
}
