import { Store } from 'vuex'
import 'reflect-metadata'
import { singleton } from 'tsyringe'
import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '@/const'
import { calcSchoolYear } from '@/utils/helper'
import { RootState } from '@/store'
import * as types from '@/store/modules/meta/types'

@singleton()
export default class MetaRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  saveLatestServerTimestamp(timestamp: string) {
    this._store.commit(new types.StoreLatestServerTimeStamp(timestamp))
  }

  getLatestServerTimestamp(): string | null {
    return this._store.state.meta.latestServerTimestamp
  }

  getCurrentSchoolYear(): number | null {
    const serverTimestamp = this.getLatestServerTimestamp()
    if (!serverTimestamp) return null

    const currentDate = dayjs(serverTimestamp, DATE_TIME_FORMAT)
    const currentYear = currentDate.year()
    const currentMonth = currentDate.month() + 1
    const currentSchoolYear = calcSchoolYear(currentYear, currentMonth)
    return currentSchoolYear
  }
}

export const MetaRepositoryFactory = (store: Store<RootState>): MetaRepository => {
  return new MetaRepository(store)
}
