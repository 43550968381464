/**
 * ------------------------------------------------------------------------
 * 20190716 - Shin Ando
 * アーキテクチャ原則や依存性原則から外れる超例外的で特異な処理を記述するファイルです。
 * 本ファイルに追記する際は下記のフォーマットに則ったコメントを必ず添えた上で、
 * チームメンバー全員によるレビューを受けてください。
 *
 * --------------
 * - 処理の概要
 * - このファイルに記述せざるを得なかった背景
 * - 処理の影響範囲
 * - 処理の変更にあたっての注意点
 * --------------
 *
 * また例外的な処理の記述を本ファイルだけでは賄えなくなった時や本ファイル以外に同種の処理を記述せざるを得なくなった時には、
 * 必ずテックリードの責任のもとに例外記述範囲の拡大をおこなってください。
 * その際にはそちらが乱用されないように本ファイルと同様もしくはそれ以上の利用制約が併記される事を期待します。
 * ------------------------------------------------------------------------
 */

import { Store } from 'vuex'
import createStore, { RootState } from '@/store'

/**
 * resetStore function
 * 最終更新日: 20190716
 * 実装者：Shin Ando
 *
 * - 処理の概要：
 *
 * オンメモリストア(Vuex)の内容を全て初期状態にリセットする関数です。
 * ユーザーのログアウト時には古いユーザーに依存した情報が残らないようにStoreの情報を適宜削除してあげる必要がありますが、
 * リセット対象の指定が膨大なため安全策をとって全て初期状態に戻す処理を行なっています。
 *
 * - このファイルに記述せざるを得なかった背景：
 *
 * ドメインによるスコープ分割やmeta情報、ユーザー非依存情報等の区別をせずに全てのデータを削除する処理のため、
 * 通常のビジネスロジックと同等のavailabilityを持たせることは危険だと判断しました。
 *
 * - 処理の影響範囲：
 *
 * Storeの格納しているデータを参照している箇所全体が対象です。主に考慮すべきは下記
 *
 * 1. Domain container
 * 2. Server Timestamp
 * 3. Header内カートサマリー
 * 4. ログインユーザー情報
 * 5. FAQ
 * 6. お知らせ
 * 7. お客様の声
 * 8. 各種Pager
 *
 * - 処理の変更にあたっての注意点：
 *
 * 本メソッドを利用するアプリケーションレイヤーは本処理のリセット対象について把握しない代わりに、
 * 本メソッドが実行されることによって全てのStore dataが完全にリセットされることを期待しています。
 * Storeの構造変更やVuex以外のローカルデータストアを利用するようになった際は、
 * 必ずもれなくリセット処理が適用される事を確認してください。
 */

export const resetStore = (store: Store<RootState>) => () => {
  // サーバーサイドのみで初期化するもの(クライアントサイドでリセットする必要がないもの)は除外
  const currentState = store.state
  const metaState = currentState.meta
  const { latestServerTimestamp } = metaState

  const initialState = createStore().state

  const resetState: RootState = {
    ...initialState,
    meta: {
      ...initialState.meta,
      latestServerTimestamp
    }
  }

  store.replaceState(resetState)
}

// TODO: 高階関数のreturn typeに名前ルールを設ける
export type resetStoreCallableType = ReturnType<typeof resetStore>
