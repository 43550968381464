import { RELATIONS, RELATIONSHIPS } from '@/const'

export interface IGroupMemberProps {
  id: number
  email: string
  nickname: string
  isAdmin: boolean
  relationshipId: number
}

export type IGroupMemberSeed = Pick<IGroupMemberProps, 'email' | 'nickname' | 'relationshipId'>

export default class GroupMemberEntity {
  private _props: IGroupMemberProps

  constructor(props: IGroupMemberProps) {
    this._props = props
  }

  get props(): IGroupMemberProps {
    return this._props
  }

  get relationship(): { key: string; name: string } {
    let relationship
    if (this.props.relationshipId === 3) {
      // id3: 祖母を変換
      relationship = RELATIONSHIPS[4]
    } else if (this.props.relationshipId === 5) {
      // id5: 祖父を変換
      relationship = RELATIONSHIPS[6]
    } else if (this.props.relationshipId === 7) {
      // id7: おばを変換
      relationship = RELATIONSHIPS[8]
    } else if (this.props.relationshipId === 9) {
      // id9: おじを変換
      relationship = RELATIONSHIPS[10]
    } else {
      relationship = RELATIONSHIPS[this.props.relationshipId]
    }
    return relationship || { key: 'others', name: 'その他' }
  }

  // Deprecated
  get getRelationName(): string {
    switch (this._props.relationshipId) {
      case 1:
        return RELATIONS.MO
      case 2:
        return RELATIONS.FA
      case 3:
      case 4:
        return RELATIONS.GM
      case 5:
      case 6:
        return RELATIONS.GF
      case 7:
      case 8:
        return RELATIONS.UN
      case 9:
      case 10:
        return RELATIONS.AU
      default:
        return RELATIONS.OT
    }
  }
}

export const GroupMemberPropsFactory = (props?: Partial<IGroupMemberProps>): IGroupMemberProps => {
  return {
    id: 877937,
    email: 'daddy@photocreate.co.jp',
    nickname: 'Daddy',
    isAdmin: true,
    relationshipId: 2,
    ...props
  }
}

export const GroupMemberEntityFactory = (props?: Partial<IGroupMemberProps>): GroupMemberEntity => {
  return new GroupMemberEntity(GroupMemberPropsFactory(props))
}

export const GroupMemberSeedFactory = (props?: Partial<IGroupMemberSeed>): IGroupMemberSeed => {
  return {
    email: 'daddy@photocreate.co.jp',
    nickname: 'Daddy',
    relationshipId: 2,
    ...props
  }
}
