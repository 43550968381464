import SnapsnapGateway from '@/gateways/SnapsnapGateway'
import SummaryRepository from '@/repositories/SummaryRepository'

export interface IFetchCartSummaryUseCaseParams {
  snapsnapGateway: SnapsnapGateway
  summaryRepository: SummaryRepository
}

export default class FetchCartSummaryUseCase implements BaseUseCase {
  snapsnapGateway: SnapsnapGateway
  summaryRepository: SummaryRepository

  constructor({ snapsnapGateway, summaryRepository }: IFetchCartSummaryUseCaseParams) {
    this.snapsnapGateway = snapsnapGateway
    this.summaryRepository = summaryRepository
  }

  async execute() {
    try {
      const {
        summary: { totalItemsCount }
      } = await this.snapsnapGateway.Cart.getCartSummary()
      this.summaryRepository.saveTotalItemsCount(totalItemsCount)
    } catch (error) {
      throw error
    }
  }
}
