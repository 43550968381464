export interface ICampaignStatusProps {
  campaignCode: string
  campaignId: number
  isApplied: boolean
}

export default class CampaignStatusEntity {
  private _props: ICampaignStatusProps

  constructor(props: ICampaignStatusProps) {
    this._props = props
  }

  get props(): ICampaignStatusProps {
    return this._props
  }
}

export const CampaignStatusPropsFactory = (props?: Partial<ICampaignStatusProps>): ICampaignStatusProps => {
  return {
    campaignCode: 'cd02',
    campaignId: 1,
    isApplied: true,
    ...props
  }
}

export const CampaignStatusEntityFactory = (props?: Partial<ICampaignStatusProps>): CampaignStatusEntity => {
  return new CampaignStatusEntity(CampaignStatusPropsFactory(props))
}
