import dayjs from 'dayjs'
import NewsRepository from '@/repositories/NewsRepository'
import ContentfulGateway from '@/gateways/ContentfulGateway'
import { DATE_TIME_FORMAT } from '@/const'
import { NotFoundError } from '@/common/errors'

export interface IFetchNewsUseCaseUseCaseParams {
  newsRepository: NewsRepository
  contentfulGateway: ContentfulGateway
}

export default class FetchNewsUseCaseUseCase implements BaseUseCase {
  newsRepository: NewsRepository
  contentfulGateway: ContentfulGateway

  constructor({ newsRepository, contentfulGateway }: IFetchNewsUseCaseUseCaseParams) {
    this.newsRepository = newsRepository
    this.contentfulGateway = contentfulGateway
  }

  async execute(params: { newsId: string; today: string | null }) {
    try {
      const { newsId, today } = params
      const news = await this.contentfulGateway.getNews(newsId)

      // 掲載期限の切れたお知らせはerrorをthrowする
      if (!today || dayjs(today, DATE_TIME_FORMAT).diff(dayjs(news.endDateTime, DATE_TIME_FORMAT), 'millisecond') >= 0) throw new NotFoundError('not found')

      this.newsRepository.saveNewsList([news])
    } catch (error) {
      throw error
    }
  }
}
