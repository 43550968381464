import { Module } from 'vuex'
import { RootState } from '@/store'
import { mutations } from '@/store/modules/group/mutations'
import { initialState, IGroupState } from '@/store/modules/group/state'

export * from '@/store/modules/group/state'

export const store: Module<IGroupState, RootState> = {
  state: initialState,
  mutations
}
