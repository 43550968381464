import { Store } from 'vuex'
import { RootState } from '@/store'
import * as types from '@/store/modules/price/types'
import PriceEntity, { IPriceProps } from '@/entities/Price'
import { IEventPhotoPrices } from '@/types/domain/event'

export default class PriceRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getPrices(photoId: number): PriceEntity[] {
    const data = this._store.state.price.byPhotoIds[photoId]
    return data ? Object.values(data).map(props => new PriceEntity(props)) : []
  }

  savePrices(params: { photoId: number; prices: IPriceProps[] }) {
    this._store.commit(new types.StorePrices(params))
  }

  savePrice({ photoId, price }: { photoId: number; price: IPriceProps }) {
    this.savePrices({ photoId, prices: [price] })
  }

  getEventPhotoPrices(): IEventPhotoPrices[] | null {
    const data = this._store.state.price.eventPhotoPrices
    return data || null
  }

  saveEventPhotoPrices(data: IEventPhotoPrices[]) {
    this._store.commit(new types.StoreEventPhotoPrices(data))
  }

  clearEventPhotoPrices() {
    this._store.commit(new types.ClearEventPhotoPrices())
  }

  getPrice(photoId: number, priceId: number): PriceEntity | null {
    const byPhoto = this._store.state.price.byPhotoIds[photoId]
    if (!byPhoto) return null

    const props = byPhoto[priceId]
    return props ? new PriceEntity(props) : null
  }
}

export const PriceRepositoryFactory = (store: Store<RootState>): PriceRepository => {
  return new PriceRepository(store)
}
