export interface IVoiceProps {
  id: string
  title: string
  content: string
  articleImage: string
  author: string
}

export default class VoiceEntity {
  private _props: IVoiceProps

  constructor(props: IVoiceProps) {
    this._props = props
  }

  get props(): IVoiceProps {
    return this._props
  }
}

export const VoicePropsFactory = (props?: Partial<IVoiceProps>): IVoiceProps => ({
  id: 'asnglknhsk',
  title: '「祖父母にも喜んでもらえ、何度も嬉しくなりました。」',
  content:
    '私たち両親が参加できなかった遠足に祖父母に行ってもらいました。祖父母からはたくさんの子どもたちの写真が送られてきましたが、祖父母も写った写真を見て、子どもたちも祖父母も楽しんでいたことが分かりとても嬉しくなりました。また、写真を購入しプレゼントしたことで祖父母にも喜んでもらえ、何度も嬉しくなりました。',
  articleImage: '/static/storybook/photo01.jpg',
  author: '花子',
  ...props
})

export const VoiceEntityFactory = (props?: Partial<IVoiceProps>): VoiceEntity => {
  return new VoiceEntity(VoicePropsFactory(props))
}
