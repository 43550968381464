import { UAParser } from 'ua-parser-js'

const ua = new UAParser()
const os = ua.getOS()
export const browser = ua.getBrowser()

let browserName
let browserVersion
const navigatorUA = navigator.userAgent
const targetText = 'SnapSnapApp'
const indexOfTargetText = navigatorUA.indexOf(targetText)
const versionLength = 3
if (indexOfTargetText > 0) {
  /* WevViewアプリの場合
   ** browserNameは 'webkit'
   ** browserVersionは UA内のSnapSnapApp/1.0 から抽出
   */
  const startIndex = indexOfTargetText + targetText.length + 1
  browserName = 'webkit'
  browserVersion = navigatorUA.substring(startIndex, startIndex + versionLength)
} else {
  browserName = browser.name
  browserVersion = browser.version
}

export const userAgent = {
  ua,
  os,
  browser
}

// データレイクに貯める用のユーザーエージェント情報
export const userAgentDedicatedToDataStream = {
  os_name: os.name as string,
  os_version: os.version as string,
  browser_name: browserName as string,
  browser_version: browserVersion as string
}

export const confirmDesignType = (): 'desktop' | 'phone' => {
  return (window.innerWidth || document.documentElement.clientWidth || 0) > 768 ? 'desktop' : 'phone'
}

export const getDeviceType = (): 'phone' | 'tablet' | 'desktop' => {
  const ua = navigator.userAgent
  if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) || ua.indexOf('Windows Phone') > 0) {
    return 'phone'
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    return 'tablet'
  } else {
    return 'desktop'
  }
}

export const isWebViewApp = (): boolean => {
  const ua = navigator.userAgent
  if (ua.indexOf('SnapSnapApp') > 0) {
    return true
  } else {
    return false
  }
}
