import { Order, OrderSeed, PaypayOrderSeed } from '@photocreate/snapsnap-api'
import { IOrderProps, IOrderSeed, IPaypayOrderSeed, IPaypayOrderProps } from '@/entities/Order'

export const toOrderProps = (order: Order): IOrderProps => {
  const {
    id,
    num,
    create_ts,
    shipment_ts,
    is_shipped,
    has_issued_receipt,
    is_receipt_expired,
    has_download_item,
    items_count,
    subtotal,
    download_set_discount,
    coupon_discount,
    tpoint_discount,
    total,
    shipment,
    payment,
    shipping,
    archive_download_url,
    ikkatsu,
    selflabo,
    not4postcard_flag
  } = order

  return {
    id,
    orderNum: num,
    orderDt: create_ts,
    shipmentDt: shipment_ts,
    isShipped: is_shipped,
    hasIssuedReceipt: has_issued_receipt,
    isReceiptExpired: is_receipt_expired,
    hasDownloadItem: has_download_item,
    itemsCount: items_count,
    subtotal,
    downloadSetDiscount: download_set_discount,
    couponDiscount: coupon_discount,
    tpointDiscount: tpoint_discount,
    total,
    shipment: {
      lastname: shipment.ship_lastname,
      firstname: shipment.ship_firstname,
      lastnameRuby: shipment.ship_lastname_ruby,
      firstnameRuby: shipment.ship_firstname_ruby,
      tel: shipment.ship_tel,
      zipcode: shipment.ship_zipcode,
      prefecture: shipment.ship_prefecture,
      address1: shipment.ship_address1,
      address2: shipment.ship_address2,
      address3: shipment.ship_address3
    },
    payment: {
      code: payment.code,
      feeAmount: payment.fee_amount
    },
    shipping: {
      code: shipping.code,
      feeAmount: shipping.fee_amount
    },
    archiveDownloadUrl: archive_download_url,
    ikkatsu,
    selflabo,
    not4postcardFlag: not4postcard_flag
  }
}

export const toOrderSeed = (order: IOrderSeed): OrderSeed => {
  const { shipmentCode, paymentCode, couponCode, tpoint, memberAddressId, notice, creditToken, saveCreditcardFlag, checkSum, memberAddressHash, total } = order

  // FIXME: 型の不整合を修正する。CreateOrderとOrderHistoryでPayment, Shipmentをそれぞれわける予定 - 20190528 Shin Ando
  if (shipmentCode === 'download') throw new Error('shipment code download is not allowed')

  return {
    payment_code: paymentCode,
    coupon_code: couponCode || undefined,
    tpoint,
    ship_member_address_id: memberAddressId || undefined,
    ship_member_address_hash: memberAddressHash || undefined,
    notice: notice || undefined,
    credit_token: creditToken || undefined,
    save_creditcard_flag: saveCreditcardFlag,
    check_sum: checkSum,
    shipping_code: shipmentCode || undefined,
    total
  }
}

export const toPayPayOrderProps = (res: { url: string; deeplink: string; expiry_date: number }): IPaypayOrderProps => {
  const { url, deeplink, expiry_date } = res

  return {
    url,
    deeplink,
    expiryDate: expiry_date
  }
}

export const toPayPayOrderSeed = (order: IPaypayOrderSeed): PaypayOrderSeed => {
  const { shipmentCode, paymentCode, couponCode, tpoint, total, shipMemberAddressId, shipMemberAddressHash, notice, checkSum } = order

  // // FIXME: 型の不整合を修正する。CreateOrderとOrderHistoryでPayment, Shipmentをそれぞれわける予定 - 20190528 Shin Ando
  // if (shipmentCode === 'download') throw new Error('shipment code download is not allowed')

  return {
    shipping_code: shipmentCode || undefined,
    payment_code: paymentCode,
    coupon_code: couponCode || undefined,
    tpoint,
    total,
    ship_member_address_id: shipMemberAddressId || undefined,
    ship_member_address_hash: shipMemberAddressHash || undefined,
    notice: notice || undefined,
    check_sum: checkSum
  }
}
