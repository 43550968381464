import { MutationTree } from 'vuex'
import { IPresentationState } from '@/store/modules/presentation/state'
import { Types, StoreIsModalOpen } from '@/store/modules/presentation/types'

export const mutations: MutationTree<IPresentationState> = {
  [Types.STORE_IS_MODAL_OPEN]: (state, action: StoreIsModalOpen) => {
    const isModalOpen = action.payload
    state.isModalOpen = isModalOpen
  }
}
