import { MutationTree } from 'vuex'
import { IVoiceState } from '@/store/modules/voice/state'
import { Types, StoreVoices, StoreVoicesByPage } from '@/store/modules/voice/types'

export const mutations: MutationTree<IVoiceState> = {
  [Types.STORE_VOICES]: (state, action: StoreVoices) => {
    const voices = action.payload
    voices.forEach(voice => {
      state.byId = {
        ...state.byId,
        [voice.id]: voice
      }
    })
  },
  [Types.STORE_VOICES_BY_PAGE]: (state, action: StoreVoicesByPage) => {
    const { page, ids } = action.payload
    state.voicesByPage = {
      ...state.voicesByPage,
      [page]: ids
    }
  }
}
