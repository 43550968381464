import { Module } from 'vuex'
import { RootState } from '@/store'
import { mutations } from '@/store/modules/faq/mutations'
import { initialState, IFAQState } from '@/store/modules/faq/state'

export * from '@/store/modules/faq/state'

export const store: Module<IFAQState, RootState> = {
  state: initialState,
  mutations
}
