import { SnapsnapGatewayBase } from './base'
import { ICredit } from '@/types/domain/credit'
import * as request from '@/infra/network/Snapsnap/requests/credit'

export default class Credit extends SnapsnapGatewayBase {
  async getCreditCardInfo(): Promise<ICredit> {
    const { card_exp_month, card_exp_year, card_number } = await this.apiClient.request(new request.GetCreditCardInfo())

    return {
      cardExpMonth: card_exp_month,
      cardExpYear: card_exp_year,
      cardNumber: card_number
    }
  }

  async postCreditCardInfo(creditToken: string) {
    return await this.apiClient.request(new request.CreateOrUpdateCreditCardInfo(creditToken))
  }

  async deleteCreditCardInfo() {
    return await this.apiClient.request(new request.DeleteCreditCardInfo())
  }
}
