import { MutationTree } from 'vuex'
import { IPriceState, initialState } from '@/store/modules/price/state'
import { Types, StorePrices, StoreEventPhotoPrices } from '@/store/modules/price/types'
import { IPriceProps } from '@/entities/Price'

export const mutations: MutationTree<IPriceState> = {
  [Types.STORE_PRICES]: (state, action: StorePrices) => {
    const { photoId, prices } = action.payload
    const hash = prices.reduce((acc: { [id: number]: IPriceProps }, ac) => ((acc[ac.id] = ac), acc), {})

    state.byPhotoIds = {
      ...state.byPhotoIds,
      [photoId]: {
        ...state.byPhotoIds[photoId],
        ...hash
      }
    }
  },
  [Types.STORE_EVENT_PHOTO_PRICES]: (state, action: StoreEventPhotoPrices) => {
    state.eventPhotoPrices = action.payload
  },
  [Types.CLEAR_EVENT_PHOTO_PRICES]: state => {
    state.eventPhotoPrices = initialState().eventPhotoPrices
  }
}
