import { Store } from 'vuex'
import { RootState } from '@/store'
import * as types from '@/store/modules/pager/types'

interface IPager {
  page: number
  total: number | null
  size: number
}

export default class PagerRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  updateOrderSummariesPager(pager: Partial<IPager>) {
    this._store.commit(new types.SetOrderSummariesPager(pager))
  }

  getOrderSummariesPager(): IPager {
    return this._store.state.pager.orderSummaries
  }

  updateOrderItemsPager(params: { orderId: number; pager: Partial<IPager> }) {
    this._store.commit(new types.SetOrderItemsPager(params))
  }

  getOrderItemsPager(orderId: number): IPager {
    // TODO: 共通化
    const defaultPager: IPager = {
      total: null,
      size: 30,
      page: 1
    }
    const byOrder = this._store.state.pager.orderItems[orderId]
    return byOrder || defaultPager
  }

  updateDownloadableOrderItemsPager(params: { orderId: number; pager: Partial<IPager> }) {
    this._store.commit(new types.SetDownloadableOrderItemsPager(params))
  }

  getDownloadableOrderItemsPager(orderId: number): IPager {
    // TODO: 共通化
    const defaultPager: IPager = {
      total: null,
      size: 30,
      page: 1
    }
    const byOrder = this._store.state.pager.downloadableOrderItems[orderId]
    return byOrder || defaultPager
  }

  updatePhotosPager(params: { eventId: number; albumId: number; pager: Partial<IPager> }) {
    this._store.commit(new types.SetPhotosPager(params))
  }

  getPhotosPager({ eventId, albumId }: { eventId: number; albumId: number }): IPager {
    // TODO: 共通化
    const defaultPager: IPager = {
      total: null,
      size: 30,
      page: 1
    }
    const byEvent = this._store.state.pager.photos[eventId]
    if (!byEvent) return defaultPager
    const byAlbum = byEvent[albumId]
    return byAlbum || defaultPager
  }

  updateVoicesPager(pager: Partial<IPager>) {
    this._store.commit(new types.SetVoicesPager(pager))
  }

  getVoicesPager(): IPager {
    return this._store.state.pager.voices
  }

  updateNewsPager(pager: Partial<IPager>) {
    this._store.commit(new types.SetNewsPager(pager))
  }

  getNewsPager(): IPager {
    return this._store.state.pager.news
  }
}

export const PagerRepositoryFactory = (store: Store<RootState>): PagerRepository => {
  return new PagerRepository(store)
}
