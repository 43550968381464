import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

export class GetCart implements APIRequest<Snapsnap.Cart.GetCartResponse> {
  response: Snapsnap.Cart.GetCartResponse
  path = '/cart'
  method = HTTPMethod.GET
}

export class GetCartArticles implements APIRequest<Snapsnap.Cart.GetCartArticlesResponse> {
  response: Snapsnap.Cart.GetCartArticlesResponse
  path = '/cart/articles'
  method = HTTPMethod.GET
}

export class GetCartItems implements APIRequest<Snapsnap.Cart.GetCartItemsResponse> {
  response: Snapsnap.Cart.GetCartItemsResponse
  path = '/cart/items'
  method = HTTPMethod.GET
  params: Snapsnap.Cart.GetCartItemsRequest
  constructor(params?: { photoId?: number; deleted?: boolean }) {
    this.params = {
      photo_id: params && params.photoId,
      deleted: params && params.deleted
    }
  }
}

export class GetCartItem implements APIRequest<Snapsnap.Cart.GetCartItemResponse> {
  response: Snapsnap.Cart.GetCartItemResponse
  path: string
  method = HTTPMethod.GET
  constructor(cartItemId: number) {
    this.path = `/cart/items/${cartItemId}`
  }
}

export class GetCartSummary implements APIRequest<Snapsnap.Cart.GetCartSummaryResponse> {
  response: Snapsnap.Cart.GetCartSummaryResponse
  path = '/cart/summary'
  method = HTTPMethod.GET
}

export class CreateCartItem implements APIRequest<Snapsnap.Cart.AddCartItemResponse> {
  response: Snapsnap.Cart.AddCartItemResponse
  path: string
  method = HTTPMethod.POST
  params: Snapsnap.Cart.AddCartItemRequest
  constructor({ photoId, photoHash }: { photoId: number; photoHash: string }) {
    this.path = '/cart/items'
    this.params = {
      photo_id: photoId,
      photo_hash: photoHash
    }
  }
}

export class DeleteCartItem implements APIRequest<Snapsnap.Cart.DeleteCartItemResponse> {
  response: Snapsnap.Cart.DeleteCartItemResponse
  path: string
  method = HTTPMethod.DELETE
  constructor(cartItemId: number) {
    this.path = `/cart/items/${cartItemId}`
  }
}

export class DeleteCartItems implements APIRequest<Snapsnap.Cart.DeleteCartItemsResponse> {
  response: Snapsnap.Cart.DeleteCartItemsResponse
  path = '/cart/items'
  method = HTTPMethod.DELETE
  params: Snapsnap.Cart.DeleteCartItemsRequest
  constructor(params: { photoId: number }) {
    const { photoId } = params
    this.params = {
      photo_id: photoId
    }
  }
}

export class UpdateCartItem implements APIRequest<Snapsnap.Cart.UpdateCartItemResponse> {
  response: Snapsnap.Cart.UpdateCartItemResponse
  path: string
  method = HTTPMethod.PUT
  params: Snapsnap.Cart.UpdateCartItemRequest

  constructor(params: { cartItemId: number; amount: number; priceId: number }) {
    const { cartItemId, amount, priceId } = params
    this.path = `/cart/items/${cartItemId}`
    this.params = {
      item: {
        amount,
        price_id: priceId
      }
    }
  }
}

export class GetCartEvents implements APIRequest<Snapsnap.Cart.GetCartEventsResponse> {
  response: Snapsnap.Cart.GetCartEventsResponse
  path = '/cart/events'
  method = HTTPMethod.GET
}

export class AddCartItemLogo implements APIRequest<Snapsnap.Cart.AddCartItemLogoResponse> {
  response: Snapsnap.Cart.AddCartItemLogoResponse
  path: string
  method = HTTPMethod.POST

  constructor(cartItemId: number) {
    this.path = `/cart/items/${cartItemId}/logo`
  }
}

export class DeleteCartItemLogo implements APIRequest<Snapsnap.Cart.DeleteCartItemLogoResponse> {
  response: Snapsnap.Cart.DeleteCartItemLogoResponse
  path: string
  method = HTTPMethod.DELETE

  constructor(cartItemId: number) {
    this.path = `/cart/items/${cartItemId}/logo`
  }
}

export class DeleteCartItemCaption implements APIRequest<Snapsnap.Cart.DeleteCartItemCaptionResponse> {
  response: Snapsnap.Cart.DeleteCartItemCaptionResponse
  method = HTTPMethod.DELETE
  path: string

  constructor(cartItemId: number) {
    this.path = `/cart/items/${cartItemId}/caption`
  }
}

export class AddCaptions implements APIRequest<Snapsnap.Cart.AddCaptionsRequest> {
  response: Snapsnap.Cart.AddCaptionsRequest
  path = '/cart/items/caption'
  method = HTTPMethod.POST
  params: Snapsnap.Cart.AddCaptionsRequest
  constructor(params: { cartItemIds: number[]; captionStr: string }) {
    this.params = {
      cart_item_ids: params.cartItemIds,
      caption_str: params.captionStr
    }
  }
}

export class GetCartPhotosByArticle implements APIRequest<Snapsnap.Cart.GetCartPhotosByArticleResponse> {
  response: Snapsnap.Cart.GetCartPhotosByArticleResponse
  path: string
  method = HTTPMethod.GET

  constructor(articleId: number) {
    this.path = `/cart/articles/${articleId}/photos`
  }
}

export class ResizeCartItems implements APIRequest<Snapsnap.Cart.ResizeCartItemsResponse> {
  response: Snapsnap.Cart.ResizeCartItemsResponse
  path = '/cart/items/resize'
  method = HTTPMethod.PUT
  params: Snapsnap.Cart.ResizeCartItemsRequest

  constructor(params: { articleId: number; cartItemIds: number[] }) {
    const { articleId, cartItemIds } = params
    this.params = {
      article_id: articleId,
      cart_item_ids: cartItemIds
    }
  }
}

export class AddCartItemsOtherArticle implements APIRequest<Snapsnap.Cart.ResizeCartItemsResponse> {
  response: Snapsnap.Cart.AddCartItemsOtherArticleResponse
  path = '/cart/items/other_article'
  method = HTTPMethod.POST
  params: Snapsnap.Cart.ResizeCartItemsRequest

  constructor(params: { articleId: number; cartItemIds: number[] }) {
    const { articleId, cartItemIds } = params
    this.params = {
      article_id: articleId,
      cart_item_ids: cartItemIds
    }
  }
}
