import { SnapsnapGatewayBase } from './base'
import { IChildProps, IChildSeed } from '@/entities/Child'
import { toChildProps, toChildSeed, toChildSchema } from './translator/child'
import { PostChild, PutChild, DeleteChild, DeleteProfileImage, GetChildren, GetChild } from '@/infra/network/Snapsnap/requests/child'

export default class Child extends SnapsnapGatewayBase {
  async getChildren(groupId: number): Promise<IChildProps[]> {
    const { children } = await this.apiClient.request(new GetChildren(groupId))
    return children.map(child => toChildProps(child))
  }

  async getChild(params: { groupId: number; childId: number }): Promise<IChildProps> {
    const { child } = await this.apiClient.request(new GetChild(params))
    return toChildProps(child)
  }

  async createChild(params: { groupId: number; child: IChildSeed }): Promise<IChildProps> {
    const { groupId, child } = params
    const seed = toChildSeed(child)
    const response = await this.apiClient.request(new PostChild({ groupId, child: seed }))

    return toChildProps(response.child)
  }

  async updateChild(params: { groupId: number; childId: number; child: Partial<IChildProps> }): Promise<IChildProps> {
    const { child, groupId, childId } = params
    const schema = toChildSchema(child)
    const res = await this.apiClient.request(new PutChild({ groupId, childId, child: schema }))
    return toChildProps(res.child)
  }

  async deleteChild(params: { groupId: number; childId: number }) {
    await this.apiClient.request(new DeleteChild(params))
  }

  async deleteProfileImage(params: { groupId: number; childId: number }): Promise<{ message: string }> {
    return await this.apiClient.request(new DeleteProfileImage(params))
  }
}
