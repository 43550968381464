const regExKanji = /[々〇〻]/i
const regExSingleByte = /^[\x32-\x7E\uFF65-\uFF9F0-9]*$/
const regExSingleByteKatakana = /[ｧ-ﾝﾞﾟ]/i
const regExYear = /^\d{1,4}$/i
const regExMonth = /(^0?[1-9]$)|(^1[0-2]$)/
const regExDay = /(^0?[1-9]$)|(^1[0-9]$)|(^2[0-9]$)|(^3[0-1]$)/
const regExEmail = /^[a-zA-Z0-9\.!#$%*+/=?^_`{|}~-]+@[a-zA-Z0-9]+([-_\.]?[a-zA-Z0-9])+\.[a-z]{2,4}$/ // eslint-disable-line
const regExRubyRestriction = /^[ァ-ヴ][ァ-ヴー・]*$/i
const regExPhoneNumber = /^\d{10,11}$/i
const regExZipcode = /^\d{7}$/i
const regExEmoji = /^\u00A9|\u00AE|[\u2600-\u27BF]|\uD83C[\uD000-\uDFFF]|\uD83D[\uD000-\uDFFF]|\uD83E[\uD000-\uDFFF]*$/
// https://www.regextester.com/106421
// https://saba.omnioo.com/note/2864/%E7%B5%B5%E6%96%87%E5%AD%97%E5%89%8A%E9%99%A4/
const regExOrderNumber = /^[0-9０-９]*$/
const regExIndention = /\r?\n/

/**
 * サロゲートペアに対応した文字列を配列に変換する関数
 * @example
 * const array = stringToArrayCompatibleSurrogatePair('');
 * // result = 4
 */
export const stringToArrayCompatibleSurrogatePair = (str: string) => {
  return str.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[^\uD800-\uDFFF]/g) || []
}

export const isKanji = (c: string) => {
  const unicode = c.codePointAt(0)! // eslint-disable-line @typescript-eslint/no-non-null-assertion
  // http://stabucky.com/wp/archives/7594
  // http://d.hatena.ne.jp/favril/20090514/1242280476
  // http://d.hatena.ne.jp/y-kawaz/20100613/1276398799
  // https://ja.wikipedia.org/wiki/Unicode%E4%B8%80%E8%A6%A7_0000-0FFF
  return (
    regExKanji.test(c) ||
    (unicode >= 0x4e00 && unicode <= 0x9fff) || // CJK統合漢字
    (unicode >= 0x3400 && unicode <= 0x4dbf) || // CJK統合漢字拡張A
    (unicode >= 0x20000 && unicode <= 0x2a6df) || // CJK統合漢字拡張B
    (unicode >= 0xf900 && unicode <= 0xfaff) || // CJK互換漢字
    (unicode >= 0x2f800 && unicode <= 0x2fa1f) // CJK互換漢字補助
  )
}

export const isJapanese = (str: string) => {
  // 全角の平仮名でも片仮名でも漢字でもないものを探す
  return stringToArrayCompatibleSurrogatePair(str).every(c => /^[ぁ-んァ-ヶー]+$/i.test(c) || isKanji(c))
}

/* 半角文字を含んでいるか */
export const isSingleByteCharacterIncluded = (str: string) => {
  return !!str && (regExSingleByteKatakana.test(str) || stringToArrayCompatibleSurrogatePair(str).some(_ => escape(_).length < 4))
}

/* すべて半角文字か */
export const isSingleByteCharacterOnly = (str: string) => {
  return !!str && regExSingleByte.test(str)
}

export const isYear = (year: number) => {
  return regExYear.test(String(year))
}

export const isMonth = (month: number) => {
  return regExMonth.test(String(month))
}

export const isDay = (day: number) => {
  return regExDay.test(String(day))
}

export const isEmail = (email: string) => {
  return regExEmail.test(String(email))
}

export const isEmailMaxLength = (email: string) => email.length >= 256

export const isRuby = (ruby: string) => {
  return !isSingleByteCharacterIncluded(ruby) && regExRubyRestriction.test(ruby)
}

export const isTel = (tel: string) => regExPhoneNumber.test(tel)

export const isZipcode = (zipcode: string) => regExZipcode.test(zipcode)

export const isPersonName = (name: string) => {
  return /^[0-9０-９a-zA-Zぁ-んァ-ヴｦ-ﾟ一-龥]+$/.test(name) || isJapanese(name)
}

export const isEmoji = (str: string) => regExEmoji.test(str)

export const isIndention = (str: string) => regExIndention.test(str)

export const isOrderNumber = (orderNumber: string) => {
  return regExOrderNumber.test(String(orderNumber))
}
