import { Store } from 'vuex'
import { RootState } from '@/store'
import NewsEntity, { INewsProps } from '@/entities/News'
import { StoreNewsList } from '@/store/modules/news/types'
import { sortBy, pipe, curriedMap } from '@/utils/helper'

export default class NewsRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getNewsList(params: { limit?: number } = {}): NewsEntity[] {
    const { limit } = params
    const data = pipe(
      () => Object.values(this._store.state.news.byId),
      curriedMap(props => new NewsEntity(props)),
      _ => _.sort(sortBy('dateUnixtime')).reverse()
    )

    if (typeof limit !== 'undefined') {
      return data.splice(0, limit)
    }

    return data
  }

  getNewsById(newsId: string): NewsEntity | null {
    const news = this._store.state.news.byId[newsId]
    if (!news) return null
    return new NewsEntity(news)
  }

  saveNewsList(newslist: INewsProps[]) {
    this._store.commit(new StoreNewsList(newslist))
  }
}

export const NewsRepositoryFactory = (store: Store<RootState>): NewsRepository => {
  return new NewsRepository(store)
}
