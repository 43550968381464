import { School } from '@photocreate/snapsnap-api'
import { ISchoolProps } from '@/entities/School'

export const toSchoolProps = (school: School): ISchoolProps => {
  const { id, name, year, children, event_ids } = school
  return {
    id,
    name,
    year: parseInt(year, 10),
    children,
    eventIds: event_ids
  }
}
