import { Module } from 'vuex'
import { RootState } from '@/store'
import { mutations } from '@/store/modules/summary/mutations'
import { ISummaryState, initialState } from '@/store/modules/summary/state'

export * from '@/store/modules/summary/state'

export const store: Module<ISummaryState, RootState> = {
  state: initialState,
  mutations
}
