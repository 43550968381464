import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

interface GetCreditTokenRequest {
  card_number: string
  card_expire: string // ex: "01/20"
  security_code: string
  token_api_key: string
  lang?: 'ja' | 'en'
}

/* eslint-disable @typescript-eslint/indent */
type GetCreditTokenResponse =
  | {
      token: string
      token_expire_date: string
      req_card_number: string // masked
      status: 'success'
      code: string
      message: string
    }
  | {
      status: 'failure'
      code: string
      message: string
    }
/* eslint-enable @typescript-eslint/indent */

export class GetCreditToken implements APIRequest<GetCreditTokenResponse> {
  response: GetCreditTokenResponse
  path = '/4gtoken'
  method = HTTPMethod.POST
  constructor(public params: GetCreditTokenRequest) {}
}
