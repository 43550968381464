import { Store } from 'vuex'
import { RootState } from '@/store'
import * as types from '@/store/modules/summary/types'

export default class SummaryRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  saveTotalItemsCount(count: number) {
    this._store.commit(new types.StoreTotalItemsCount(count))
  }

  getTotalItemsCount() {
    return this._store.state.summary.totalItemsCount
  }
}

export const SummaryRepositoryFactory = (store: Store<RootState>): SummaryRepository => {
  return new SummaryRepository(store)
}
