import { MutationTree } from 'vuex'
import { ISummaryState } from '@/store/modules/summary/state'
import { Types, StoreTotalItemsCount } from '@/store/modules/summary/types'

export const mutations: MutationTree<ISummaryState> = {
  [Types.STORE_TOTAL_ITEMS_COUNT]: (state, action: StoreTotalItemsCount) => {
    const totalItemsCount = action.payload
    state.totalItemsCount = totalItemsCount
  }
}
