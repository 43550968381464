export interface IFaceProps {
  id: string
  imageUrl: string
  similarity: number // 0.0 ~ 1.0
}

export default class FaceEntity {
  private _props: IFaceProps

  constructor(props: IFaceProps) {
    this._props = props
  }

  get props(): IFaceProps {
    return this._props
  }
}

export const FacePropsFactory = (props?: Partial<IFaceProps>): IFaceProps => {
  return {
    id: 'b3001934-dc7e-40b8-aa19-3a5699361dac',
    imageUrl: '/static/storybook/photo01.jpg',
    similarity: 0.8,
    ...props
  }
}

export const FaceEntityFactory = (props?: Partial<IFaceProps>): FaceEntity => {
  return new FaceEntity(FacePropsFactory(props))
}
