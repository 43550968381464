import { Store } from 'vuex'
import { RootState } from '@/store'
import OrderSummaryEntity, { IOrderSummaryProps } from '@/entities/OrderSummary'
import { StoreOrderSummaries, StoreOrderSummariesByPage } from '@/store/modules/orderSummary/types'
import { pluck } from '@/utils/helper'

export default class OrderSummaryRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getOrderSummaries(): OrderSummaryEntity[] {
    const data = Object.values(this._store.state.orderSummary.byId)
    return data.map(props => new OrderSummaryEntity(props))
  }

  saveOrderSummaries(data: IOrderSummaryProps[]) {
    this._store.commit(new StoreOrderSummaries(data))
  }

  saveOrderSummariesByPage(params: { page: number; orderSummaries: IOrderSummaryProps[] }) {
    const { page, orderSummaries } = params
    const ids = pluck(orderSummaries, 'id')

    this._store.commit(new StoreOrderSummaries(orderSummaries))
    this._store.commit(new StoreOrderSummariesByPage({ page, ids }))
  }

  getOrderSummariesByPage(page: number) {
    const ids = this._store.state.orderSummary.byPage[page] || []
    const propsList = ids.map(id => this._store.state.orderSummary.byId[id]).filter(Boolean)
    return propsList.map(props => new OrderSummaryEntity(props))
  }
}

export const OrderSummaryRepositoryFactory = (store: Store<RootState>): OrderSummaryRepository => {
  return new OrderSummaryRepository(store)
}
