import { IVoiceProps } from '@/entities/Voice'

export interface IVoiceState {
  byId: {
    [id: string]: IVoiceProps
  }
  voicesByPage: {
    [page: number]: IVoiceProps['id'][]
  }
}

export const initialState = (): IVoiceState => ({
  byId: {},
  voicesByPage: {}
})
