import { Module } from 'vuex'
import { RootState } from '@/store/index.ts'
import { mutations } from '@/store/modules/memberAddress/mutations'
import { IMemberAddressState, initialState } from '@/store/modules/memberAddress/state'

export * from '@/store/modules/memberAddress/state'

export const store: Module<IMemberAddressState, RootState> = {
  state: initialState,
  mutations
}
