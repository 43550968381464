import { Face } from '@photocreate/snapsnap-api'
import { IFaceProps } from '@/entities/Face'

export const toFaceProps = (face: Face): IFaceProps => {
  const { id, image_url } = face

  return {
    id,
    imageUrl: image_url,
    similarity: 0.8 // FIXME
  }
}
