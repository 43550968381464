import { IOrderItemProps } from '@/entities/OrderItem'

export const Types = {
  STORE_ORDER_ITEMS: 'order/store_order_items',
  STORE_ORDER_ITEMS_BY_PAGE: 'order/store_order_items_by_page',
  STORE_DOWNLOADABLE_ORDER_ITEMS_BY_PAGE: 'order/store_downloadable_order_items_by_page'
}

export class StoreOrderItems implements FluxStandardAction {
  type = Types.STORE_ORDER_ITEMS
  constructor(public payload: { orderItems: IOrderItemProps[] }) {}
}

export class StoreOrderItemsByPage implements FluxStandardAction {
  type = Types.STORE_ORDER_ITEMS_BY_PAGE
  constructor(public paylod: { orderId: number; page: number; ids: number[] }) {}
}

export class StoreDownloadableOrderItemsByPage implements FluxStandardAction {
  type = Types.STORE_DOWNLOADABLE_ORDER_ITEMS_BY_PAGE
  constructor(public paylod: { orderId: number; page: number; ids: number[] }) {}
}
