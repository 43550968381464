import { SnapsnapGatewayBase } from './base'
import { Detect, EventUchinokoSearch, EventCartQuerySearch, RegisterCartQuery, DeleteCartQuery } from '@/infra/network/Snapsnap/requests/c4'
import { UchinokoData } from '@/types/domain/uchinoko'
import { toUchinokoData } from './translator/c4'
import { IRegisterCartQueryProps } from '@/entities/C4'

export default class C4 extends SnapsnapGatewayBase {
  async detect(s3Uri: string): Promise<{ url: string }[]> {
    const { faces } = await this.apiClient.request(new Detect(s3Uri))
    return faces.map(face => ({ url: face.face_image_url }))
  }

  async registerCartQuery(params: IRegisterCartQueryProps) {
    return await this.apiClient.request(new RegisterCartQuery(params))
  }

  async deleteCartQuery(params: { eventId: number }) {
    return await this.apiClient.request(new DeleteCartQuery(params))
  }

  async eventUchinokoSearch(params: { eventId: number; recommend?: boolean; ignoreOrderHistory?: boolean }): Promise<UchinokoData[]> {
    const { uchinoko_photos } = await this.apiClient.request(new EventUchinokoSearch(params))
    return uchinoko_photos.map(toUchinokoData)
  }

  async eventCartQuerySearch(params: { eventId: number; recommend?: boolean }): Promise<UchinokoData[]> {
    const { cart_query_photos } = await this.apiClient.request(new EventCartQuerySearch(params))
    return cart_query_photos.map(toUchinokoData)
  }
}
