














import Vue from 'vue'
import Footer from '@/components/shared/organisms/Footer'
import AdFoot from '@/components/shared/atoms/Ad/AdFoot'
import BreadCrumb from '@/containers/BreadCrumb'
import ChatBot from '@/containers/ChatBot'
import { isDesktop } from '@/utils/helper'
import { isWebViewApp } from '@/plugins/userAgent'

interface IData {
  isDesktop: boolean
}

export default Vue.extend({
  components: {
    Footer,
    AdFoot,
    BreadCrumb,
    ChatBot
  },
  data(): IData {
    return {
      isDesktop: false
    }
  },
  head() {
    return {
      title: 'スナップスナップ - 導入施設数No1・スクールフォト販売サイト ',
      titleTemplate: ''
    }
  },
  computed: {
    isWebViewApp(): boolean {
      return isWebViewApp()
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path && ((!this.$route.meta.hideAdSide && isDesktop) || !this.$route.meta.hideAdFoot)) {
        // adManagerが入っているページに遷移した時のみ、destroySlotsを実行
        try {
          window.googletag.destroySlots()
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  mounted() {
    const width = window.innerWidth
    if (width > 769) {
      this.isDesktop = true
    }
  }
})
