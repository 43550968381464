import { SnapsnapGatewayBase } from './base'
import { GetOrderSummaries } from '@/infra/network/Snapsnap/requests/orderSummary'
import { toOrderSummaryProps } from './translator/orderSummary'

export default class OrderSummary extends SnapsnapGatewayBase {
  async getOrderSummaries(params: { page?: number; perPage?: number }) {
    const { order_summaries, total, current_page, per_page } = await this.apiClient.request(new GetOrderSummaries(params))
    return {
      total,
      currentPage: current_page,
      perPage: per_page,
      orderSummaries: order_summaries.map(toOrderSummaryProps)
    }
  }
}
