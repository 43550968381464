import { Store } from 'vuex'
import { RootState } from '@/store'
import * as types from '@/store/modules/presentation/types'

export default class PresentationRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  saveIsModalOpen(bool: boolean) {
    this._store.commit(new types.StoreIsModalOpen(bool))
  }

  getIsModalOpen() {
    return this._store.state.presentation.isModalOpen
  }
}

export const PresentationRepositoryFactory = (store: Store<RootState>): PresentationRepository => {
  return new PresentationRepository(store)
}
