import { InquirySeed } from '@photocreate/snapsnap-api'
import { IInquirySeed } from '@/entities/Inquiry'

export const toInquirySeed = (inquiry: IInquirySeed): InquirySeed => {
  const { category, name, email, orderNumber, content } = inquiry

  return {
    category,
    name,
    email,
    order_number: orderNumber || undefined,
    content
  }
}
