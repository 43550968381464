import { Context } from '@nuxt/types'

export default async function({ $snapsnapGateway, redirect, params }: Context) {
  try {
    if (typeof params['groupId'] !== 'string') throw new Error('Parameter :groupId is missing')
    const groupId = parseInt(params['groupId'], 10)
    const group = await $snapsnapGateway.Group.getGroup(groupId)
    if (!group.isAdmin) {
      redirect('/')
    }
  } catch (e) {
    redirect('/')
  }
}
