import { MutationTree } from 'vuex'
import { IProfileState, initialState } from '@/store/modules/profile/state'
import { Types, StoreProfile, StoreInputSignupMailaddress, StoreInputProfileCreate, StoreInputProfileUpdate } from '@/store/modules/profile/types'

export const mutations: MutationTree<IProfileState> = {
  [Types.STORE_PROFILE]: (state, action: StoreProfile) => {
    state.profile = action.payload
  },
  [Types.CLEAR_PROFILE]: state => {
    state.profile = initialState().profile
  },
  [Types.STORE_SIGNUP_INPUT_FIELD_MAILADDRESS]: (state, action: StoreInputSignupMailaddress) => {
    state.profileSignupInputFields = action.payload
  },
  [Types.CLEAR_SIGNUP_INPUT_FIELD_MAILADDRESS]: state => {
    state.profileSignupInputFields = initialState().profileSignupInputFields
  },
  [Types.STORE_PROFILE_INPUT_FIELD_CREATE]: (state, action: StoreInputProfileCreate) => {
    state.profileCreateInputFields = action.payload
  },
  [Types.CLEAR_PROFILE_INPUT_FIELD_CREATE]: state => {
    state.profileCreateInputFields = initialState().profileCreateInputFields
  },
  [Types.STORE_PROFILE_INPUT_FIELD_UPDATE]: (state, action: StoreInputProfileUpdate) => {
    state.profileUpdateInputFields = action.payload
  },
  [Types.CLEAR_PROFILE_INPUT_FIELD_UPDATE]: state => {
    state.profileUpdateInputFields = initialState().profileUpdateInputFields
  }
}
