








import Vue from 'vue'
import { oneOf } from '@/utils/helper'

export default Vue.extend({
  props: {
    position: {
      type: String,
      validator: oneOf(['top', 'bottom']),
      default: 'top'
    },
    transition: {
      type: String,
      validator: oneOf(['up', 'down', 'left', 'right']),
      default: 'up'
    }
  },
  computed: {
    setPosition(): string {
      return `-${this.position}`
    },
    setTransition(): string {
      return `-${this.transition}`
    }
  }
})
