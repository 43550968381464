import dayjs from 'dayjs'
import { sex } from '@/const'
import { Nullable } from '@/types/utils'

export interface IProfileProps {
  id: number
  email: string
  nickname: string
  sex: 'f' | 'm' | null
  birthdate: string
  isTpointConnected: boolean
  lastname: string
  firstname: string
  lastnameRuby: string
  firstnameRuby: string
  tel: string | null
  zipcode: string | null
  prefecture: string
  address1: string | null
  address2: string | null
  address3: string | null
  memberAddressId: number
  addressHash: string
}

export interface IProfileSeed {
  email: string
  password: string
  firstname: string
  lastname: string
  firstnameRuby: string
  lastnameRuby: string
  birthYear: number
  birthMonth: number
  birthDay: number
  prefecture: string
  sex: 'f' | 'm' | null
  readFlag: boolean
  tlsc: string | null // Tポイント連携用token
}

export type IProfileFields = Omit<IProfileProps, 'id' | 'isTpointConnected' | 'memberAddressId' | 'addressHash' | 'email' | 'birthdate' | 'zipcode' | 'address1' | 'address2' | 'tel'> & {
  birthYear: number
  birthMonth: number
  birthDay: number
  zipcode: string
  address1: string
  address2: string
  tel: string
}

export default class ProfileEntity {
  private _props: IProfileProps

  constructor(props: IProfileProps) {
    this._props = props
  }

  get props(): IProfileProps {
    return this._props
  }

  get birthDateStr(): string {
    if (!this._props.birthdate) return '未設定'
    return dayjs(this._props.birthdate, 'YYYY-M-D').format('YYYY年MM月DD日')
  }

  get birthYear(): number | null {
    return Number(dayjs(this._props.birthdate, 'YYYY-M-D').format('YYYY')) || null
  }

  get birthMonth(): number | null {
    return Number(dayjs(this._props.birthdate, 'YYYY-M-D').format('MM')) || null
  }

  get birthDay(): number | null {
    return Number(dayjs(this._props.birthdate, 'YYYY-M-D').format('DD')) || null
  }

  get sexStr() {
    if (!this._props.sex) return '未設定'
    return sex[this._props.sex]
  }

  get isProfileRegisteredAll(): boolean {
    const { birthdate, lastname, firstname, lastnameRuby, firstnameRuby, tel, zipcode, address1, address2 } = this._props
    return !!birthdate && !!lastname && !!firstname && !!lastnameRuby && !!firstnameRuby && !!tel && !!zipcode && !!address1 && !!address2
  }

  get isMemberAddressHalf(): boolean {
    // 会員登録時に自動生成されたMemberAddressに情報が設定されているかどうか判定
    const { tel, zipcode, address1, address2 } = this._props
    return !tel || !zipcode || !address1 || !address2
  }

  get fullnameWithRuby(): string {
    const { lastname, firstname, lastnameRuby, firstnameRuby } = this._props
    return `${lastname} ${firstname}（${lastnameRuby} ${firstnameRuby}）`
  }

  // TODO: FIXME
  get isProfileRequired(): boolean {
    // プロフィール必須項目が入力されているか判定
    const { lastname, firstname, lastnameRuby, firstnameRuby, birthdate, prefecture } = this._props
    return !lastname || !firstname || !lastnameRuby || !firstnameRuby || !birthdate || !prefecture
  }
}

export function isProfileFields(fields: Nullable<IProfileFields>): fields is IProfileFields {
  const { firstname, lastname, firstnameRuby, lastnameRuby, nickname, birthYear, birthMonth, birthDay, zipcode, prefecture, address1, address2, address3, sex, tel } = fields

  if (
    typeof firstname === 'string' &&
    typeof lastname === 'string' &&
    typeof firstnameRuby === 'string' &&
    typeof lastnameRuby === 'string' &&
    typeof nickname === 'string' &&
    typeof birthYear === 'number' &&
    typeof birthMonth === 'number' &&
    typeof birthDay === 'number' &&
    typeof prefecture === 'string' &&
    // (sex === 'f' || sex === 'm') &&
    typeof zipcode === 'string' &&
    typeof address1 === 'string' &&
    typeof address2 === 'string' &&
    typeof tel === 'string'
  ) {
    // for type assertion use only
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data: IProfileFields = {
      firstname,
      lastname,
      firstnameRuby,
      lastnameRuby,
      nickname,
      birthYear,
      birthMonth,
      birthDay,
      zipcode,
      prefecture,
      address1,
      address2,
      address3,
      sex,
      tel
    }

    return true
  }

  return false
}

export const ProfileSeedFactory = (props?: Partial<IProfileSeed>): IProfileSeed => {
  return {
    password: 'oi122ijml',
    email: 'test@photocreate.co.jp',
    sex: 'm',
    birthYear: 1978,
    birthMonth: 6,
    birthDay: 7,
    lastname: '山田',
    firstname: '太郎',
    lastnameRuby: 'ヤマダ',
    firstnameRuby: 'タロウ',
    prefecture: '東京都',
    readFlag: true,
    tlsc: null,
    ...props
  }
}

export const ProfileFieldsFactory = (props?: Partial<IProfileFields>): IProfileFields => {
  return {
    nickname: 'スナスナ太郎',
    sex: 'm',
    birthDay: 21,
    birthMonth: 10,
    birthYear: 1990,
    lastname: '砂砂',
    firstname: '太郎',
    lastnameRuby: 'すなすな',
    firstnameRuby: 'たろう',
    prefecture: '東京都',
    zipcode: '1840012',
    address1: '小金井市中町',
    address2: '2-11-26',
    address3: null,
    tel: '0423837389',
    ...props
  }
}

export const ProfilePropsFactory = (props?: Partial<IProfileProps>): IProfileProps => {
  return {
    id: 1,
    email: 'shin.ando@photocreate.co.jp',
    nickname: 'スナスナ太郎',
    sex: 'm',
    birthdate: '1992-10-21',
    isTpointConnected: true,
    lastname: '砂砂',
    firstname: '太郎',
    lastnameRuby: 'すなすな',
    firstnameRuby: 'たろう',
    prefecture: '東京都',
    zipcode: null,
    address1: null,
    address2: null,
    address3: null,
    tel: null,
    memberAddressId: 1,
    addressHash: 'aklsfgada',
    ...props
  }
}

export const ProfileEntityFactory = (props?: Partial<IProfileProps>): ProfileEntity => {
  return new ProfileEntity(ProfilePropsFactory(props))
}
