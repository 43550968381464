import axios, { AxiosResponse } from 'axios'
import Config from '@/types/config'
import { NetworkClient } from '@/infra/network/NetworkClient'
import { APIRequest } from '@/infra/network/APIRequest'
import { APIError } from '@/infra/network/APIError'
import { HTTPMethod } from '@/infra/network/types'

interface HttpError extends Error {
  response?: {
    status: number
  }
  message: string
}

export class APIClient implements NetworkClient {
  baseURL: string
  timeout: number = 15 * 1000
  appConfig: Config['app']

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.appConfig = process.env.APP_CONFIG as any
    if (process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
      this.baseURL = 'https://kqncfbnbmh.execute-api.ap-northeast-1.amazonaws.com/prod'
    } else if (process.env.NODE_ENV === 'production') {
      this.baseURL = 'https://r22i5g0g5e.execute-api.ap-northeast-1.amazonaws.com/prod'
    }
  }

  request<U>(request: APIRequest<U>): Promise<U> {
    const isRead = request.method === HTTPMethod.GET
    return new Promise<U>((resolve, reject) => {
      axios
        .request({
          url: request.path,
          method: request.method,
          params: isRead && request.params,
          data: !isRead && request.params,
          timeout: this.timeout,
          baseURL: request.baseURL || this.baseURL
        })
        .then(data => {
          const response = request.parse ? request.parse(data) : this.parse<U>(data)
          resolve(response)
        })
        .catch(err => {
          const apiError = this.normalizeError(err)
          reject(apiError)
        })
    })
  }

  // Default parser
  private parse<U>(data: AxiosResponse): U {
    return data.data
  }

  // Convert axios error into APIError
  private normalizeError(error: HttpError): APIError {
    return {
      status: error.response && error.response.status,
      message: error.message,
      raw: error
    }
  }
}
