import dayjs from 'dayjs'
import { Profile, ProfileSeed, Snapsnap } from '@photocreate/snapsnap-api'
import { IProfileProps, IProfileSeed, IProfileFields } from '@/entities/Profile'

export const toProfileProps = (profile: Profile): IProfileProps => {
  const {
    id,
    email,
    nickname,
    sex,
    birthdate,
    is_tpoint_connected,
    lastname,
    firstname,
    lastname_ruby,
    firstname_ruby,
    tel,
    zipcode,
    prefecture,
    address1,
    address2,
    address3,
    address_hash,
    member_address_id
  } = profile

  return {
    id,
    email,
    nickname,
    sex,
    birthdate,
    isTpointConnected: is_tpoint_connected,
    lastname,
    firstname,
    lastnameRuby: lastname_ruby,
    firstnameRuby: firstname_ruby,
    tel: tel || null,
    zipcode: zipcode || null,
    prefecture,
    address1: address1 || null,
    address2: address2 || null,
    address3: address3 || null,
    addressHash: address_hash,
    memberAddressId: member_address_id
  }
}

export const toProfileSeedSchema = (seed: IProfileSeed): ProfileSeed => {
  const { firstname, lastname, firstnameRuby, lastnameRuby, birthYear, birthMonth, birthDay, prefecture, sex, readFlag, password, email, tlsc } = seed

  return {
    email,
    password,
    firstname,
    lastname,
    firstname_ruby: firstnameRuby,
    lastname_ruby: lastnameRuby,
    // TODO FIXME
    birthdate: dayjs(`${birthYear}-${birthMonth}-${birthDay}`, 'YYYY-M-D').format('YYYY-MM-DD'),
    prefecture,
    sex,
    read_flag: readFlag,
    tlsc: tlsc || undefined
  }
}

export const toProfileFields = (fields: IProfileFields): Snapsnap.Member.UpdateProfileRequest['profile'] => {
  // TODO: ProfileFields型を作成したら置き換える
  const { firstname, firstnameRuby, lastname, lastnameRuby, birthYear, birthDay, birthMonth, prefecture, sex, zipcode, address1, address2, address3, tel, nickname } = fields

  return {
    firstname,
    lastname,
    firstname_ruby: firstnameRuby,
    lastname_ruby: lastnameRuby,
    tel: tel || undefined,
    zipcode: zipcode || undefined,
    address1: address1 || undefined,
    address2: address2 || undefined,
    address3: typeof address3 === 'string' ? address3 : undefined, // 空文字でupdateできるように
    // TODO FIXME
    birthdate: dayjs(`${birthYear}-${birthMonth}-${birthDay}`, 'YYYY-M-D').format('YYYY-MM-DD'),
    sex,
    prefecture,
    nickname
  }
}
