import { Snapsnap, MemberAddressSeed, MemberAddress } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

export class PutProfile implements APIRequest<Snapsnap.Member.UpdateProfileResponse> {
  response: Snapsnap.Member.UpdateProfileResponse
  path = '/profile'
  params: Snapsnap.Member.UpdateProfileRequest
  method = HTTPMethod.PUT

  constructor(profile: Snapsnap.Member.UpdateProfileRequest['profile']) {
    // TODO: ProfileFields型を作成したら置き換える
    this.params = {
      profile
    }
  }
}

export class PostMemberAddress implements APIRequest<Snapsnap.Member.CreateMemberAddressResponse> {
  response: Snapsnap.Member.CreateMemberAddressResponse
  path = '/profile/addresses'
  method = HTTPMethod.POST
  params: Snapsnap.Member.CreateMemberAddressRequest

  constructor(address: MemberAddressSeed) {
    this.params = {
      address
    }
  }
}

export class GetMemberAddresses implements APIRequest<Snapsnap.Member.GetMemberAddressesResponse> {
  response: Snapsnap.Member.GetMemberAddressesResponse
  path = '/profile/addresses'
  method = HTTPMethod.GET
}

export class GetMemberAddress implements APIRequest<Snapsnap.Member.GetMemberAddressResponse> {
  response: Snapsnap.Member.GetMemberAddressResponse
  path: string
  method = HTTPMethod.GET
  constructor(memberAddressId: number) {
    this.path = `/profile/addresses/${memberAddressId}`
  }
}

export class PutMemberAddress implements APIRequest<Snapsnap.Member.UpdateMemberAddressResponse> {
  response: Snapsnap.Member.UpdateMemberAddressResponse
  path: string
  params: Snapsnap.Member.UpdateMemberAddressRequest
  method = HTTPMethod.PUT

  constructor({ memberAddressId, memberAddress }: { memberAddressId: number; memberAddress: Partial<MemberAddress> }) {
    this.path = `/profile/addresses/${memberAddressId}`
    this.params = {
      address: memberAddress
    }
  }
}

export class DeleteMemberAddress implements APIRequest<Snapsnap.Member.DeleteMemberAddressResponse> {
  response: Snapsnap.Member.DeleteMemberAddressResponse
  method = HTTPMethod.DELETE
  path: string

  constructor(memberAddressId: number) {
    this.path = `/profile/addresses/${memberAddressId}`
  }
}

export class PutMemberPassword implements APIRequest<Snapsnap.Member.UpdateMemberPasswordResponse> {
  response: Snapsnap.Member.UpdateMemberPasswordResponse
  path = '/profile/password'
  params: Snapsnap.Member.UpdateMemberPasswordRequest
  method = HTTPMethod.PUT

  constructor({ memberPassword }: { memberPassword: { password: string; new_password: string } }) {
    this.params = {
      member: memberPassword
    }
  }
}

export class PostMemberEmailResetRequest implements APIRequest<Snapsnap.Member.RequestMemberEmailResetResponse> {
  response: Snapsnap.Member.RequestMemberEmailResetResponse
  path = '/profile/email_reset_request'
  params: Snapsnap.Member.RequestMemberEmailResetRequest
  method = HTTPMethod.POST
  constructor(email: string) {
    this.params = {
      email
    }
  }
}

export class PostMemberEmailReset implements APIRequest<Snapsnap.Member.ResetMemberEmailResponse> {
  response: Snapsnap.Member.ResetMemberEmailResponse
  path = '/profile/email_reset'
  params: Snapsnap.Member.ResetMemberEmailRequest
  method = HTTPMethod.POST
  constructor({ email, token }: { email: string; token: string }) {
    this.params = {
      email,
      token
    }
  }
}

export class GetMemberSubscriptions implements APIRequest<Snapsnap.Member.GetMemberSubscriptionsResponse> {
  response: Snapsnap.Member.GetMemberSubscriptionsResponse
  path = '/profile/subscriptions'
  method = HTTPMethod.GET
}

export class PutMemberSubscriptions implements APIRequest<Snapsnap.Member.PutMemberSubscriptionsResponse> {
  response: Snapsnap.Member.PutMemberSubscriptionsResponse
  path = '/profile/subscriptions'
  params: Snapsnap.Member.PutMemberSubscriptionsRequest
  method = HTTPMethod.PUT

  constructor({ subscriptions }: { subscriptions: { magazine_id: number; read_flag: boolean }[] }) {
    this.params = {
      subscriptions
    }
  }
}
