import { Store } from 'vuex'
import { RootState } from '@/store'
import { pluck } from '@/utils/helper'
import VoiceEntity, { IVoiceProps } from '@/entities/Voice'
import * as types from '@/store/modules/voice/types'

export default class VoiceRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getVoices(): VoiceEntity[] {
    const state = this._store.state.voice
    return Object.values(state.byId).map(props => new VoiceEntity(props))
  }

  getVoicesByPage(page: number): VoiceEntity[] {
    const byPage = this._store.state.voice.voicesByPage[page] || []
    const propsList = byPage.map(id => this._store.state.voice.byId[id]).filter(Boolean)
    return propsList.map(props => new VoiceEntity(props))
  }

  getVoiceById(voiceId: string): VoiceEntity | null {
    const voice = this._store.state.voice.byId[voiceId]
    if (!voice) return null
    return new VoiceEntity(voice)
  }

  saveVoices(voices: IVoiceProps[]) {
    this._store.commit(new types.StoreVoices(voices))
  }

  saveVoicesByPage({ page, voices }: { page: number; voices: IVoiceProps[] }) {
    const ids = pluck(voices, 'id')
    this.saveVoices(voices)
    this._store.commit(new types.StoreVoicesByPage({ page, ids }))
  }
}

export const VoiceRepositoryFactory = (store: Store<RootState>): VoiceRepository => {
  return new VoiceRepository(store)
}
