import 'reflect-metadata'
import { singleton } from 'tsyringe'
import { Store } from 'vuex'
import { RootState } from '@/store'
import * as types from '@/store/modules/profile/types'
import ProfileEntity, { IProfileProps, IProfileFields, IProfileSeed } from '@/entities/Profile'

@singleton()
export default class ProfileRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getProfile(): ProfileEntity | null {
    const profile = this._store.state.profile.profile
    if (!profile) return null
    return new ProfileEntity(profile)
  }

  saveProfile(profile: IProfileProps) {
    this._store.commit(new types.StoreProfile(profile))
  }

  removeProfile() {
    this._store.commit(new types.ClearProfile())
  }

  saveSignupMailAddressInputField(fields: Pick<IProfileSeed, 'email'>) {
    this._store.commit(new types.StoreInputSignupMailaddress(fields))
  }

  getSignupMailAddressInputField(): Pick<IProfileSeed, 'email'> | null {
    const fields = this._store.state.profile.profileSignupInputFields
    return fields
  }

  clearSignupMailAddressInputField() {
    this._store.commit(new types.ClearInputSignupMailAddress())
  }

  saveCreateProfileInputField(fields: Omit<IProfileSeed, 'email' | 'password' | 'tlsc'>) {
    this._store.commit(new types.StoreInputProfileCreate(fields))
  }

  getCreateProfileInputField(): Omit<IProfileSeed, 'email' | 'password' | 'tlsc'> | null {
    const fields = this._store.state.profile.profileCreateInputFields
    return fields
  }

  clearCreateProfileInputField() {
    this._store.commit(new types.ClearInputProfileCreate())
  }

  saveUpdateProfileInputField(fields: IProfileFields) {
    this._store.commit(new types.StoreInputProfileUpdate(fields))
  }

  getUpdateProfileInputField(): IProfileFields | null {
    const fields = this._store.state.profile.profileUpdateInputFields
    return fields
  }

  clearUpdateProfileInputField() {
    this._store.commit(new types.ClearInputProfileUpdate())
  }
}

export const ProfileRepositoryFactory = (store: Store<RootState>): ProfileRepository => {
  return new ProfileRepository(store)
}
