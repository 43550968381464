export interface IGroupProps {
  id: number
  name: string
  isAdmin: boolean
}

export default class GroupEntity {
  private _props: IGroupProps

  constructor(props: IGroupProps) {
    this._props = props
  }

  get props(): IGroupProps {
    return this._props
  }

  get groupNameString(): string {
    return this._props.name + 'ファミリー'
  }
}

export const GroupPropsFactory = (props?: Partial<IGroupProps>): IGroupProps => {
  return {
    id: 877937,
    name: '水野',
    isAdmin: false,
    ...props
  }
}

export const GroupEntityFactory = (props?: Partial<IGroupProps>): GroupEntity => {
  return new GroupEntity(GroupPropsFactory(props))
}
