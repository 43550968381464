import { SnapsnapGatewayBase } from './base'
import { GetPhoto, GetPhotoPrices, RequestUnPublish } from '@/infra/network/Snapsnap/requests/photo'
import { toPhotoProps } from './translator/photo'
import { toPriceProps } from './translator/price'
import { IPhotoProps } from '@/entities/Photo'
import { IPriceProps } from '@/entities/Price'

export default class Photo extends SnapsnapGatewayBase {
  async getPhoto(photoId: number): Promise<IPhotoProps> {
    const { photo } = await this.apiClient.request(new GetPhoto(photoId))
    return toPhotoProps(photo)
  }

  async getPhotoPrices(photoId: number): Promise<IPriceProps[]> {
    const { prices } = await this.apiClient.request(new GetPhotoPrices(photoId))
    return prices.map(price => toPriceProps(price))
  }

  async postRequestUnPublish(params: { photoId: number; reason: string }) {
    return await this.apiClient.request(new RequestUnPublish(params))
  }
}
