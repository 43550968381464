







































































import Vue from 'vue'
import Logo from '@/components/shared/atoms/Logo'
import HeaderIcon from '@/components/shared/atoms/Icon/HeaderIcon'
import CommonArrow from '@/components/shared/atoms/Icon/CommonArrow'
import TextLink from '@/components/shared/atoms/TextLink'
import Modal from '@/components/shared/atoms/Modal'
import CartIcon from '@/components/shared/atoms/Icon/CartIcon'
import LogoutUseCase from '@/usecases/auth/LogoutUseCase'
import FetchCartSummaryUseCase from '@/usecases/cart/FetchCartSummaryUseCase'
import Presenter, { IPresenter } from './presenter'
import dayjs from 'dayjs'
import { DATE_FORMAT } from '@/const'
import FetchNewsUseCaseUseCase from '@/usecases/news/FetchNewsUseCaseUseCase'
import { sleep } from '@/utils'
// Warning
import { resetStore } from '@/taboo'

interface IData {
  showModal: boolean
  featuredNewsId: string
}

export default Vue.extend({
  components: {
    Logo,
    HeaderIcon,
    CommonArrow,
    TextLink,
    Modal,
    CartIcon
  },
  props: {
    hideHeaderMenu: {
      type: Boolean,
      default: false
    },
    photoHeader: {
      type: Boolean,
      default: false
    },
    showCartSideIcon: {
      type: Boolean,
      default: false
    },
    devProps: {
      type: Object as () => {
        showGlobalMenu?: boolean
      },
      default: null
    }
  },
  data(): IData {
    return {
      showModal: false,
      featuredNewsId: '62Y9jguz3lUoZz4vacCHNN'
    }
  },
  computed: {
    presenter(): IPresenter {
      return Presenter({
        summaryRepository: this.$summaryRepository,
        profileRepository: this.$profileRepository,
        featuredNewsId: this.featuredNewsId,
        newsRepository: this.$newsRepository
      })
    }
  },
  watch: {
    'presenter.isLoggedIn': {
      immediate: true,
      async handler(val: IPresenter['isLoggedIn']) {
        // ログイン状態になったらCartSummaryを取得(クライアントのみ)
        if (val && process.client) {
          await this.fetchCartSummary()
        }
      }
    }
  },
  created() {
    const devProps = this.devProps
    if (devProps) {
      if (devProps.showGlobalMenu) {
        this.showModal = true
      }
    }
  },
  async mounted() {
    const usecase = new FetchNewsUseCaseUseCase({
      contentfulGateway: this.$contentfulGateway,
      newsRepository: this.$newsRepository
    })

    const today = this.$metaRepository.getLatestServerTimestamp()

    await usecase.execute({ newsId: this.featuredNewsId, today })
  },
  methods: {
    formatDate(date: string) {
      const dateFormat = dayjs(date, DATE_FORMAT)
      return dateFormat.format('YYYY.MM.DD')
    },
    async fetchCartSummary() {
      const usecase = new FetchCartSummaryUseCase({
        snapsnapGateway: this.$snapsnapGateway,
        summaryRepository: this.$summaryRepository
      })
      try {
        await usecase.execute()
      } catch (e) {
        console.log(e)
        // TODO: Handle error
      }
    },
    async logout() {
      const usecase = new LogoutUseCase({
        snapsnapGateway: this.$snapsnapGateway
      })
      try {
        // Warning: taboo methodの呼び出しおよびvm.$storeの参照を例外的に許容
        // 背景についてはtaboo method側のコメントを参照のこと
        const resetStoreFunc = resetStore(this.$store)

        await usecase.execute(resetStoreFunc)
        await sleep(1000)

        this.showModal = false
        this.$router.push('/logout')
      } catch (e) {
        console.log(e)
        // TODO: Handle error
      }
    }
  }
})
