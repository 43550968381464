import { APIClient } from '@/infra/network/VeriTrans/APIClient'
import { ICreditSeed } from '@/types/domain/credit'
import { GetCreditToken } from '@/infra/network/VeriTrans/requests'

export default class VeriTransGateway {
  apiClient: APIClient

  constructor(apiClient: APIClient) {
    this.apiClient = apiClient
  }

  async getCreditToken(seed: ICreditSeed) {
    const { cardNumber, cardExpYear, cardExpMonth, securityCode } = seed
    const expMonth = cardExpMonth <= 9 ? `0${cardExpMonth}` : cardExpMonth
    const cardExpire = expMonth + '/' + String(cardExpYear).slice(2, 4)
    const request = new GetCreditToken({
      card_number: cardNumber,
      card_expire: cardExpire,
      security_code: securityCode,
      token_api_key: process.env.VERITRANS_TOKEN_API_KEY as string,
      lang: 'ja'
    })
    return await this.apiClient.request(request)
  }
}

export const VeriTransGatewayFactory = (): VeriTransGateway => {
  const apiClient = new APIClient()
  return new VeriTransGateway(apiClient)
}
