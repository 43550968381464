import { ICartProps } from '@/entities/Cart'
import { ICartItemProps } from '@/entities/CartItem'

export const Types = {
  STORE_CART: 'cart/store_cart',
  STORE_CART_ITEMS: 'cart/store_cart_items',
  CLEAR_CART_ITEMS: 'cart/clear_cart_items',
  REMOVE_CART_ITEM: 'cart/remove_cart_item',
  REMOVE_CART_ITEMS: 'cart/remove_cart_items',
  STORE_DELETED_CART_ITEMS: 'cart/store_deleted_cart_items',
  REMOVE_DELETED_CART_ITEM: 'cart/remove_deleted_cart_item',
  CLEAR_DELETED_CART_ITEMS: 'cart/clear_deleted_cart_items'
}

export class StoreCart implements FluxStandardAction {
  type = Types.STORE_CART
  constructor(public payload: ICartProps) {}
}

export class StoreCartItems implements FluxStandardAction {
  type = Types.STORE_CART_ITEMS
  constructor(public payload: ICartItemProps[]) {}
}

export class ClearCartItems implements FluxStandardAction {
  type = Types.CLEAR_CART_ITEMS
}

export class RemoveCartItem implements FluxStandardAction {
  type = Types.REMOVE_CART_ITEM
  constructor(public payload: { cartItemId: number; photoId: number }) {}
}

export class RemoveCartItems implements FluxStandardAction {
  type = Types.REMOVE_CART_ITEMS
  constructor(public payload: { photoId: number }) {}
}

export class StoreDeletedCartItems implements FluxStandardAction {
  type = Types.STORE_DELETED_CART_ITEMS
  constructor(public payload: ICartItemProps[]) {}
}

export class RemoveDeletedCartItems implements FluxStandardAction {
  type = Types.REMOVE_DELETED_CART_ITEM
  constructor(public payload: { photoId: number }) {}
}

export class ClearDeletedCartItems implements FluxStandardAction {
  type = Types.CLEAR_DELETED_CART_ITEMS
}
