import { IOrderProps, IPaypayOrderProps } from '@/entities/Order'

export const Types = {
  STORE_ORDERS: 'order/store_orders',
  STORE_PAYPAY_ORDERS: 'order/store_paypay_orders'
}

export class StoreOrders implements FluxStandardAction {
  type = Types.STORE_ORDERS
  constructor(public payload: IOrderProps[]) {}
}

export class StorePayPayOrders implements FluxStandardAction {
  type = Types.STORE_PAYPAY_ORDERS
  constructor(public payload: IPaypayOrderProps) {}
}
