






















import Vue from 'vue'
import Header from '@/containers/Header'
import Footer from '@/components/shared/organisms/Footer'
import AdSideOrderComplete from '@/components/shared/atoms/Ad/AdSideOrderComplete'
import AdFootOrderComplete from '@/components/shared/atoms/Ad/AdFootOrderComplete'
import ToastContainer from '@/containers/Toast'
import BreadCrumb from '@/containers/BreadCrumb'
import ChatBot from '@/containers/ChatBot'
import { RouteMeta } from '@/router'
import { isDesktop } from '@/utils/helper'
const DevMenu = () => import('@/containers/DevMenu')

export default Vue.extend({
  components: {
    Header,
    Footer,
    AdSideOrderComplete,
    AdFootOrderComplete,
    DevMenu,
    BreadCrumb,
    ChatBot,
    ToastContainer
  },
  head() {
    const meta = (this.$route.meta || {}) as RouteMeta
    const { head, title } = meta
    if (!title) {
      return {
        titleTemplate: ''
      }
    }
    if (head && head.meta) {
      return {
        title,
        meta: head.meta
      }
    }
    return {
      title
    }
  },
  computed: {
    isDesktop(): boolean {
      if (isDesktop) return true
      return false
    }
  }
  // destroyed() {
  //   window.googletag.cmd.push(() => {
  //     window.googletag.destroySlots()
  //   })
  //   // SPA対策でdestroyのタイミングで一度adSlotsを消す
  // }
})
