import { MutationTree } from 'vuex'
import { IMetaState } from '@/store/modules/meta/state'
import { Types, StoreLatestServerTimeStamp } from '@/store/modules/meta/types'

export const mutations: MutationTree<IMetaState> = {
  [Types.STORE_LATEST_SERVER_TIMESTAMP]: (state, action: StoreLatestServerTimeStamp) => {
    const timestamp = action.payload
    state.latestServerTimestamp = timestamp
  }
}
