export const ErrorType = {
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  CONFLICT: 409,
  UNPROCESSABLE: 422,
  BAD_REQUEST: 400,
  TOO_MANY_REQUESTS: 429
}

export class ErrorBase extends Error {
  constructor(message: string, constructor: Function) {
    super(message)
    this.message = message
    this.constructor = constructor
    // @ts-ignore
    // eslint-disable-next-line
    this.__proto__ = this.constructor.prototype

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}

export class NotFoundError extends ErrorBase {
  constructor(msg: string) {
    super(msg, NotFoundError)
  }
}

export class ForbiddenError extends ErrorBase {
  constructor(msg: string) {
    super(msg, ForbiddenError)
  }
}

export class UnauthorizedError extends ErrorBase {
  constructor(msg: string) {
    super(msg, UnauthorizedError)
  }
}

export class UnprocessableError extends ErrorBase {
  constructor(msg: string) {
    super(msg, UnprocessableError)
  }
}

export class ConflictError extends ErrorBase {
  constructor(msg: string) {
    super(msg, ConflictError)
  }
}

export class BadRequestError extends ErrorBase {
  constructor(msg: string) {
    super(msg, BadRequestError)
  }
}

export class TooManyRequestsError extends ErrorBase {
  constructor(msg: string) {
    super(msg, TooManyRequestsError)
  }
}

export class ServerError extends ErrorBase {
  constructor(msg: string) {
    super(msg, ServerError)
  }
}
