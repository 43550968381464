export interface IChildProps {
  id: number
  firstname: string | null
  lastname: string
  sex: 'f' | 'm' | null
  birthYear: number | null
  birthMonth: number | null
  profileImage: string | null
}

export interface IChildSeed {
  firstname: string
  lastname: string
  sex: 'f' | 'm' | null
  birthYear: number | null
  birthMonth: number | null
  profileImage: string | null
}

export default class ChildEntity {
  private _props: IChildProps

  constructor(props: IChildProps) {
    this._props = props
  }

  get props(): IChildProps {
    return this._props
  }

  // Deprecated
  get birth(): string {
    let birth = this._props.birthYear ? this._props.birthYear + '年' : ''
    birth += this._props.birthMonth ? this._props.birthMonth + '月' : ''
    birth += birth ? '生まれ' : ''
    return birth
  }

  get birthDateString(): string {
    const { birthYear, birthMonth } = this._props
    if (!birthYear && !birthMonth) return ''

    const birthYearStr = birthYear ? birthYear + '年' : ''
    const birthMonthStr = birthMonth ? birthMonth + '月' : ''
    return birthYearStr + birthMonthStr + '生まれ'
  }
}

export const ChildSeedFactory = (props?: Partial<IChildSeed>): IChildSeed => {
  return {
    firstname: 'John',
    lastname: 'Smith',
    birthYear: 2002,
    birthMonth: 9,
    sex: 'm',
    profileImage: '/static/storybook/photo01.jpg',
    ...props
  }
}

export const ChildPropsFactory = (props?: Partial<IChildProps>): IChildProps => {
  return {
    id: 877937,
    firstname: '花',
    lastname: '村松',
    sex: 'f',
    birthYear: 2003,
    birthMonth: 6,
    profileImage: '/static/storybook/photo01.jpg',
    ...props
  }
}

export const ChildEntityFactory = (props?: Partial<IChildProps>): ChildEntity => {
  return new ChildEntity(ChildPropsFactory(props))
}
