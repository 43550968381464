import { GroupMember, GroupMemberSeed } from '@photocreate/snapsnap-api'
import { IGroupMemberProps, IGroupMemberSeed } from '@/entities/GroupMember'

export const toGroupMemberProps = (member: GroupMember): IGroupMemberProps => {
  const { id, email, nickname, admin, relationship_id } = member
  return {
    id,
    email,
    nickname,
    isAdmin: admin,
    relationshipId: relationship_id
  }
}

export const toGroupMemberSeed = (member: IGroupMemberSeed): GroupMemberSeed => {
  const { email, nickname, relationshipId } = member
  return {
    email,
    nickname,
    relationship_id: relationshipId
  }
}
