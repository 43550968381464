import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

export class GetPhoto implements APIRequest<Snapsnap.Photo.GetPhotoResponse> {
  response: Snapsnap.Photo.GetPhotoResponse
  path: string
  method = HTTPMethod.GET
  constructor(photoId: number) {
    this.path = `/photos/${photoId}`
  }
}

export class GetPhotoPrices implements APIRequest<Snapsnap.Photo.GetPhotoPricesResponse> {
  response: Snapsnap.Photo.GetPhotoPricesResponse
  path: string
  method = HTTPMethod.GET

  constructor(photoId: number) {
    this.path = `/photos/${photoId}/prices`
  }
}

export class RequestUnPublish implements APIRequest<Snapsnap.Photo.PostPhotoRequestUnpublishResponse> {
  response: Snapsnap.Photo.PostPhotoRequestUnpublishResponse
  path: string
  method = HTTPMethod.POST
  params: Snapsnap.Photo.PostPhotoRequestUnpublishRequest
  constructor({ photoId, reason }: { photoId: number; reason: string }) {
    this.path = `/photos/${photoId}/request_unpublish`
    this.params = {
      reason
    }
  }
}
