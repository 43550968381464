import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

export class GetAddress implements APIRequest<Snapsnap.YOLP.ZipCodeSearchResponse> {
  response: Snapsnap.YOLP.ZipCodeSearchResponse
  path = '/yolp/zip_code_search'
  method = HTTPMethod.GET
  params: { query: string }
  constructor(zipcode: string) {
    this.params = { query: zipcode }
  }
}
