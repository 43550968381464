import { Context } from '@nuxt/types'
// dataStream
import { setRecord, UserBehaviorTargetList } from '@/plugins/dataStream'
import { UserBehaviorSchema } from '@/types/dataStream'

// userAgent
import { userAgentDedicatedToDataStream, confirmDesignType, getDeviceType } from '@/plugins/userAgent'

export default async function ({ $snapsnapGateway, $profileRepository, route }: Context) {
  try {
    const { profile } = await $snapsnapGateway.Auth.loggedInCheck()
    if (!profile) return

    $profileRepository.saveProfile(profile)
    if (UserBehaviorTargetList.includes(profile.id) && route.name && profile !== null) {
      // data stream 用のパラメータを設定
      const kinesisData: UserBehaviorSchema = {
        event_type: 'user_behavior',
        current_page: route.name,
        member_id: profile.id,
        ts: new Date().getTime(),
        design_type: confirmDesignType(),
        device_type: getDeviceType(),
        ...userAgentDedicatedToDataStream
      }

      // put record の実行
      setRecord(kinesisData, profile.id)
    }
  } catch (e) {
    // TODO: error typeによる振り分け
  }
}
