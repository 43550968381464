













import Vue from 'vue'
import { BreadCrumb, parseBreadCrumbs } from '@/utils/breadCrumbs'

export default Vue.extend({
  computed: {
    breadCrumbs(): BreadCrumb[] {
      const route = this.$route
      return parseBreadCrumbs(route)
    }
  }
})
