import { ContentfulClientApi, Asset, Entry } from 'contentful'
import { IVoiceProps } from '@/entities/Voice'
import { INewsProps } from '@/entities/News'
import { createClient } from '@/infra/network/Contentful/APIClient'
import Voice from '@/gateways/ContentfulGateway/types/voice'
import News from '@/gateways/ContentfulGateway/types/news'
import { Raw } from '@/gateways/ContentfulGateway/types'
import { toVoiceProps, toNewsProps } from '@/gateways/ContentfulGateway/translator'
import { ErrorType } from '@/common/errors'

const DEFAULT_PER_PAGE = 4

const ContentType = {
  VOICE: 'voice',
  NEWS: 'news',
  FEATURED_NEWS: 'featuredNews'
}

export default class ContentfulGateway {
  constructor(private client: ContentfulClientApi) {}

  async getVoice(voiceId: IVoiceProps['id']): Promise<IVoiceProps> {
    try {
      const { fields, sys } = await this.client.getEntry<Raw<Voice>>(voiceId)
      // TODO: use translator
      const { author, content, title } = fields
      const { id } = sys

      // FIXME article_image型がおかしいので強制的にparse
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const asset = (fields as any)['article_image'] as Asset
      const articleImage = asset.fields.file.url

      return {
        id,
        articleImage,
        author,
        content,
        title
      }
    } catch (e) {
      if (e.sys && e.sys.type === 'Error' && e.sys.id === 'NotFound') {
        // eslint-disable-next-line
        throw { status: ErrorType.NOT_FOUND, message: 'Not found' }
      }
      throw e
    }
  }

  async getVoices({ page = 1, perPage = DEFAULT_PER_PAGE }: { page?: number; perPage?: number }): Promise<{ total: number; voices: IVoiceProps[] }> {
    const res = await this.client.getEntries<Raw<Voice>>({
      content_type: ContentType.VOICE,
      skip: (page - 1) * perPage,
      limit: perPage,
      order: '-sys.createdAt'
    })
    return {
      total: res.total,
      voices: res.items.map(item => toVoiceProps(item, res))
    }
  }

  async getNewsList({ page = 1, perPage = DEFAULT_PER_PAGE, today }: { page?: number; perPage?: number; today: string | null }): Promise<{ total: number; newsList: INewsProps[] }> {
    const res = await this.client.getEntries<Raw<News>>({
      content_type: ContentType.NEWS,
      skip: (page - 1) * perPage,
      limit: perPage,
      order: '-fields.startDateTime',
      'fields.endDateTime[gte]': today || undefined
    })

    return {
      total: res.total,
      newsList: res.items.map(toNewsProps)
    }
  }

  async getNews(newsId: News['id']): Promise<INewsProps> {
    try {
      const res = await this.client.getEntry<Raw<News>>(newsId)
      return toNewsProps(res)
    } catch (e) {
      if (e.sys && e.sys.type === 'Error' && e.sys.id === 'NotFound') {
        // eslint-disable-next-line
        throw { status: ErrorType.NOT_FOUND, message: 'Not found' }
      }
      throw e
    }
  }

  async getFeaturedNews(): Promise<INewsProps | null> {
    const res = await this.client.getEntries<{ news: Entry<Raw<News>> }>({
      content_type: ContentType.FEATURED_NEWS,
      limit: 1
    })

    const item = res.items[0]
    if (!item) return null

    const {
      fields: { news }
    } = item
    if (!news) return null

    return toNewsProps(news)
  }
}

export const ContentfulGatewayFactory = (): ContentfulGateway => {
  const client = createClient({
    accessToken: '59fceefbb829023353b4961933b699896e2e5d92078f5e752aaee8d7c2612dfc',
    space: 'ezs1swce23xe'
  })
  return new ContentfulGateway(client)
}
