import { SnapsnapGatewayBase } from './base'
import { GetPayPayOrderPaymentStatus, GetPayPayOrderStatus } from '@/infra/network/Snapsnap/requests/paypayOrder'

export default class Paypay extends SnapsnapGatewayBase {
  async checkPayPayOrderPaymentStatus(paypayOrderId: number) {
    const { paypay_order_id, status } = await this.apiClient.request(new GetPayPayOrderPaymentStatus(paypayOrderId))

    return {
      paypayOrderId: paypay_order_id,
      status
    }
  }

  async getPayPayOrderStatus(paypayOrderId: number) {
    const { paypay_order_id, order_num, order_id, status, error_msg } = await this.apiClient.request(new GetPayPayOrderStatus(paypayOrderId))

    return {
      paypayOrderId: paypay_order_id,
      orderId: order_id,
      orderNum: order_num,
      status,
      errorMsg: error_msg
    }
  }
}
