import { IEventProps } from '@/entities/Event'
import { IAlbumProps } from '@/entities/Album'
import { IPhotoProps } from '@/entities/Photo'
import { IFaceProps } from '@/entities/Face'
import { IEventCampaignProps } from '@/entities/EventCampaign'
import { IEventThumbnail } from '@/types/domain/event'
import { ICampaignStatusProps } from '@/entities/CampaignStatus'
import { ICartQueryStatusProps } from '@/entities/C4'

type photoId = number

export interface IEventState {
  byIds: {
    [eventId: number]: IEventProps
  }
  thumbnails: {
    [eventId: number]: Omit<IEventThumbnail, 'eventId'>
  }
  latestEvents: number[]
  albums: {
    // Use eventId as the index key
    [eventId: number]: {
      [albumId: number]: IAlbumProps
    }
  }
  photos: {
    [eventId: number]: {
      [albumId: number]: {
        [photoId: number]: IPhotoProps
      }
    }
  }
  photosByPage: {
    [eventId: number]: {
      [albumId: number]: {
        [page: number]: photoId[]
      }
    }
  }
  // Flush result everytime
  searchResult: IPhotoProps[]
  uchinokoFaces: {
    [eventId: number]: {
      [faceId: string]: IFaceProps
    }
  }
  uchinokoPhotos: {
    [eventId: number]: {
      [faceId: string]: {
        [albumId: number]: photoId[]
      }
    }
  }
  selectedUchinokoFaces: {
    [eventId: number]: {
      [faceId: string]: boolean
    }
  }
  cartQueryStatus: {
    [eventId: number]: ICartQueryStatusProps
  }
  eventCampaigns: {
    [eventId: number]: {
      [campaignId: number]: IEventCampaignProps
    }
  }
  campaignStatus: {
    [campaignId: number]: ICampaignStatusProps
  }
  similarities: Record<string, number>
}

export const initialState = (): IEventState => ({
  byIds: {},
  thumbnails: {},
  latestEvents: [],
  albums: {},
  photos: {},
  photosByPage: {},
  searchResult: [],
  uchinokoFaces: {},
  uchinokoPhotos: {},
  selectedUchinokoFaces: {},
  cartQueryStatus: {},
  eventCampaigns: {},
  campaignStatus: {},
  similarities: {}
})
