














import Vue from 'vue'

const LIMIT_COUNT = 100
export default Vue.extend({
  props: {
    iconType: {
      type: String,
      default: 'cart' // cart | information
    },
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    calcCount(): number | string {
      return LIMIT_COUNT > this.count ? this.count : '⋯'
    }
  }
})
