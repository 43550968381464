import { Store } from 'vuex'
import { RootState } from '@/store'
import * as type from '@/store/modules/cart/types'
import CartEntity, { ICartProps } from '@/entities/Cart'
import CartItemEntity, { ICartItemProps } from '@/entities/CartItem'

export default class CartRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getCart(): CartEntity | null {
    const props = this._store.state.cart.cart
    return props ? new CartEntity(props) : null
  }

  saveCart(cart: ICartProps) {
    this._store.commit(new type.StoreCart(cart))
  }

  saveCartItems(items: ICartItemProps[]) {
    this._store.commit(new type.StoreCartItems(items))
  }

  clearCartItems() {
    this._store.commit(new type.ClearCartItems())
  }

  saveDeletedCartItems(items: ICartItemProps[]) {
    this._store.commit(new type.StoreDeletedCartItems(items))
  }

  clearDeletedCartItems() {
    this._store.commit(new type.ClearDeletedCartItems())
  }

  saveCartItem(item: ICartItemProps) {
    this.saveCartItems([item])
  }

  saveDeletedCartItem(item: ICartItemProps) {
    this.saveDeletedCartItems([item])
  }

  removeCartItem(params: { photoId: number; cartItemId: number }) {
    this._store.commit(new type.RemoveCartItem(params))
  }

  removeCartItemsByPhoto(params: { photoId: number }) {
    this._store.commit(new type.RemoveCartItems(params))
  }

  removeDeletedCartItemsByPhoto(params: { photoId: number }) {
    this._store.commit(new type.RemoveDeletedCartItems(params))
  }

  getCartItemsTotalCount(): number {
    const byPhotos = Object.values(this._store.state.cart.cartItems)
    const totalCount = byPhotos.reduce((acc, ac) => {
      const items = Object.values(ac)
      acc += items.reduce((sum, item) => (sum += item.amount), 0)
      return acc
    }, 0)
    return totalCount
  }

  /** photoIdごとにまとめず、フラットなリストとして返却する */
  getCartItemsList(): CartItemEntity[] {
    return Object.values(this._store.state.cart.cartItems).reduce((acc, ac) => {
      const propsList = Object.values(ac)
      acc = [...acc, ...propsList.map(props => new CartItemEntity(props))]
      return acc
    }, [] as CartItemEntity[])
  }

  getCartItemsListByPhotos(photoIds: number[]): CartItemEntity[] {
    return photoIds.reduce((acc, ac) => {
      const byPhoto = this._store.state.cart.cartItems[ac]
      if (byPhoto) {
        const items = Object.values(byPhoto).map(props => new CartItemEntity(props))
        acc = [...acc, ...items]
      }
      return acc
    }, [] as CartItemEntity[])
  }

  getCartItemsListByEventId(eventId: number | null): CartItemEntity[] {
    return this.getCartItemsList().filter(item => item.props.eventId === eventId)
  }

  getCartItems(): Record<number, Record<number, CartItemEntity>> {
    return Object.entries(this._store.state.cart.cartItems).reduce((acc, [key, val]) => {
      const photoId = parseInt(key, 10)
      const byPhoto = val

      acc[photoId] = Object.entries(byPhoto).reduce((_acc, [_key, _val]) => {
        const cartItemId = parseInt(_key, 10)
        _acc[cartItemId] = new CartItemEntity(_val)
        return _acc
      }, {} as Record<number, CartItemEntity>)

      return acc
    }, {} as Record<number, Record<number, CartItemEntity>>)
  }

  getDeletedCartItems(): Record<number, Record<number, CartItemEntity>> {
    return Object.entries(this._store.state.cart.deletedCartItems).reduce((acc, [key, val]) => {
      const photoId = parseInt(key, 10)
      const byPhoto = val

      acc[photoId] = Object.entries(byPhoto).reduce((_acc, [_key, _val]) => {
        const cartItemId = parseInt(_key, 10)
        _acc[cartItemId] = new CartItemEntity(_val)
        return _acc
      }, {} as Record<number, CartItemEntity>)

      return acc
    }, {} as Record<number, Record<number, CartItemEntity>>)
  }

  getCartItem(photoId: number, cartItemId: number): CartItemEntity | null {
    const byPhoto = this._store.state.cart.cartItems[photoId]
    if (!byPhoto) return null

    const props = byPhoto[cartItemId]
    return props ? new CartItemEntity(props) : null
  }

  getDeletedCartItem(photoId: number, cartItemId: number): CartItemEntity | null {
    const byPhoto = this._store.state.cart.deletedCartItems[photoId]
    if (!byPhoto) return null

    const props = byPhoto[cartItemId]
    return props ? new CartItemEntity(props) : null
  }

  getCartItemByPhoto(photoId: number): Record<number, CartItemEntity> {
    const byPhoto = this._store.state.cart.cartItems[photoId]
    if (!byPhoto) return {}

    return Object.entries(byPhoto).reduce((acc, [key, val]) => {
      const id = parseInt(key, 10)
      acc[id] = new CartItemEntity(val)
      return acc
    }, {} as Record<number, CartItemEntity>)
  }

  getDownloadSetCartPhotoIds(): number[] {
    const cart = this._store.state.cart.cart
    if (!cart) return []
    return this.getCartItemsList()
      .filter(id => cart.downloadSetCartItemIds.find(p => p === id.props.id))
      .map(a => a.props.photoId)
  }
}

export const CartRepositoryFactory = (store: Store<RootState>) => {
  return new CartRepository(store)
}
