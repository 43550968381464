export interface IFAQContentProps {
  id: number
  title: string
  body: string
  sectionId: number | null
  promoted: boolean
  labelNames: string[] | undefined
}

export default class FAQContentEntity {
  private _props: IFAQContentProps

  constructor(props: IFAQContentProps) {
    this._props = props
  }

  get props(): IFAQContentProps {
    return this._props
  }
}

export const FAQContentPropsFactory = (props?: Partial<IFAQContentProps>): IFAQContentProps => {
  return {
    id: 877937,
    title: '会員退会の方法は？',
    body: 'hogehogehogehogehogehogheo',
    sectionId: 1,
    promoted: true,
    labelNames: [],
    ...props
  }
}

export const FAQContentEntityFactory = (props?: Partial<IFAQContentProps>): FAQContentEntity => {
  return new FAQContentEntity(FAQContentPropsFactory(props))
}
