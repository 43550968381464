import { IOrderSummaryProps } from '@/entities/OrderSummary'

export const Types = {
  STORE_ORDER_SUMMARIES: 'order/store_order_summaries',
  STORE_ORDER_SUMMARIES_BY_PAGE: 'order/store_order_summaries_by_page'
}

export class StoreOrderSummaries implements FluxStandardAction {
  type = Types.STORE_ORDER_SUMMARIES
  constructor(public payload: IOrderSummaryProps[]) {}
}

export class StoreOrderSummariesByPage implements FluxStandardAction {
  type = Types.STORE_ORDER_SUMMARIES_BY_PAGE
  constructor(public payload: { page: number; ids: number[] }) {}
}
