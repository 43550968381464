import { Store } from 'vuex'
import { RootState } from '@/store'
import MemberAddressEntity, { IMemberAddressProps, IMemberAddressSeed } from '@/entities/MemberAddress'
import { StoreMemberAddresses, RemoveMemberAddress, StoreInputMemberAddress, ClearInputMemberAddress } from '@/store/modules/memberAddress/types'

export default class MemberAddressRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getMemberAddresses(): MemberAddressEntity[] {
    const data = Object.values(this._store.state.memberAddress.byId)
    return data.map(props => new MemberAddressEntity(props))
  }

  saveMemberAddresses(data: IMemberAddressProps[]) {
    this._store.commit(new StoreMemberAddresses(data))
  }

  saveMemberAddress(data: IMemberAddressProps) {
    this.saveMemberAddresses([data])
  }

  getMemberAddressById(id: number) {
    const target = this._store.state.memberAddress.byId[id]
    if (!target) return null
    return new MemberAddressEntity(target)
  }

  removeMemberAddress(id: IMemberAddressProps['id']) {
    this._store.commit(new RemoveMemberAddress(id))
  }

  saveMemberAddressInputField(fields: IMemberAddressSeed) {
    this._store.commit(new StoreInputMemberAddress(fields))
  }

  getMemberAddressInputField(): IMemberAddressSeed | null {
    const fields = this._store.state.memberAddress.memberAddressInputFields
    return fields
  }

  clearMemberAddressInputField() {
    this._store.commit(new ClearInputMemberAddress())
  }
}

export const MemberAddressRepositoryFactory = (store: Store<RootState>): MemberAddressRepository => {
  return new MemberAddressRepository(store)
}
