import { IGroupProps } from '@/entities/Group'
import { IGroupMemberProps } from '@/entities/GroupMember'
import { IChildProps } from '@/entities/Child'
import SchoolEntity, { ISchoolProps } from '@/entities/School'
import { IGroupInvitationProps } from '@/entities/GroupInvitation'
import { IGroupSetting } from '@/types/domain/group'
import { Fields as childInputFieldProps } from '@/utils/validators/form/Group/Child'

export const Types = {
  STORE_GROUPS: 'group/store_groups',
  REMOVE_GROUP: 'group/remove_group',
  STORE_GROUP_SETTING: 'group/store_group_setting',
  STORE_GROUP_MEMBERS: 'group/store_group_members',
  REMOVE_GROUP_MEMBERS: 'group/remove_group_members',
  REMOVE_GROUP_MEMBER: 'group/remove_group_member',
  STORE_CHILDREN: 'group/store_children',
  REMOVE_CHILDREN: 'group/remove_children',
  REMOVE_CHILD: 'group/remove_child',
  STORE_SCHOOLS: 'group/store_schools',
  STORE_GROUP_INVITATIONS: 'group/store_group_invitations',
  REMOVE_GROUP_INVITATIONS: 'group/remove_group_invitations',
  REMOVE_GROUP_INVITATION: 'group/remove_group_invitation',
  STORE_CHILD_INPUT_FIELD: 'group/store_child_input_field',
  CLEAR_CHILD_INPUT_FIELD: 'group/clear_child_input_field',
  STORE_SCHOOLPASS_INPUT_FIELD: 'group/store_schoolpass_input_field',
  CLEAR_SCHOOLPASS_INPUT_FIELD: 'group/clear_schoolpass_input_field'
}

export class StoreGroups implements FluxStandardAction {
  type = Types.STORE_GROUPS
  constructor(public payload: IGroupProps[]) {}
}

export class RemoveGroup implements FluxStandardAction {
  type = Types.REMOVE_GROUP
  constructor(public payload: number) {}
}

export class StoreGroupSetting implements FluxStandardAction {
  type = Types.STORE_GROUP_SETTING
  constructor(public payload: { groupId: number; setting: IGroupSetting }) {}
}

export class StoreGroupMembers implements FluxStandardAction {
  type = Types.STORE_GROUP_MEMBERS
  constructor(public paylod: { groupId: number; groupMembers: IGroupMemberProps[] }) {}
}

export class RemoveGroupMembers implements FluxStandardAction {
  type = Types.REMOVE_GROUP_MEMBERS
  constructor(public payload: number) {}
}

export class RemoveGroupMember implements FluxStandardAction {
  type = Types.REMOVE_GROUP_MEMBER
  constructor(public payload: { groupId: number; groupMemberId: number }) {}
}

export class StoreChildren implements FluxStandardAction {
  type = Types.STORE_CHILDREN
  constructor(public payload: { groupId: number; children: IChildProps[] }) {}
}

export class RemoveChildren implements FluxStandardAction {
  type = Types.REMOVE_CHILDREN
  constructor(public payload: number) {}
}

export class RemoveChild implements FluxStandardAction {
  type = Types.REMOVE_CHILD
  constructor(public payload: { groupId: number; childId: number }) {}
}

export class StoreSchools implements FluxStandardAction {
  type = Types.STORE_SCHOOLS
  constructor(public payload: { groupId: number; schools: ISchoolProps[] }) {}
}

export class StoreGroupInvitations implements FluxStandardAction {
  type = Types.STORE_GROUP_INVITATIONS
  constructor(public payload: { groupId: number; groupInvitations: IGroupInvitationProps[] }) {}
}

export class RemoveGroupInvitations implements FluxStandardAction {
  type = Types.REMOVE_GROUP_INVITATIONS
  constructor(public payload: number) {}
}

export class RemoveGroupInvitation implements FluxStandardAction {
  type = Types.REMOVE_GROUP_INVITATION
  constructor(public payload: { groupId: number; groupInvitationId: number }) {}
}

export class StoreChildInputField implements FluxStandardAction {
  type = Types.STORE_CHILD_INPUT_FIELD
  constructor(public payload: { field: childInputFieldProps; isImageDeleted: boolean }) {}
}
export class ClearChildInputField implements FluxStandardAction {
  type = Types.CLEAR_CHILD_INPUT_FIELD
}
export class StoreSchoolpassInputField implements FluxStandardAction {
  type = Types.STORE_SCHOOLPASS_INPUT_FIELD
  constructor(public payload: { childId: number | null; schools: SchoolEntity[]; schoolpassHint: string | null; schoolCheckList: { [key: number]: boolean }; isRegisteringNew: boolean }) {}
}
export class ClearSchoolpassInputField implements FluxStandardAction {
  type = Types.CLEAR_SCHOOLPASS_INPUT_FIELD
}
