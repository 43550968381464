import { IProfileProps, IProfileFields, IProfileSeed } from '@/entities/Profile'

export interface IProfileState {
  profile: IProfileProps | null
  profileSignupInputFields: Pick<IProfileSeed, 'email'> | null
  profileCreateInputFields: Omit<IProfileSeed, 'email' | 'password' | 'tlsc'> | null
  profileUpdateInputFields: IProfileFields | null
}

export const initialState = (): IProfileState => ({
  profile: null,
  profileSignupInputFields: null,
  profileCreateInputFields: null,
  profileUpdateInputFields: null
})
