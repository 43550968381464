import { ContentfulClientApi, CreateClientParams } from 'contentful'

const config = {
  space: process.env.CONTENTFUL_SPACE_ID as string,
  accessToken: process.env.CONTENTFUL_DELIVERY_TOKEN as string
}

// IEで動作させるためにはlegacy clientを読み込む必要があるが、legacy clientはSSRに対応していないため、processを見て使い分ける
export const createClient = (_config?: CreateClientParams): ContentfulClientApi => {
  // if (process.server || true) {
  return require('contentful').createClient(_config || config)
  // } else {
  //   return require('contentful/dist/contentful.legacy').createClient(_config || config)
  // }
}
