import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '@/const'
import { Shipment, Payment } from '@/entities/Cart'

export interface IOrderProps {
  id: number
  orderNum: number
  orderDt: string // 注文日
  shipmentDt: string | null // 出荷指示作成日時
  isShipped: boolean // 発送済フラグ
  hasIssuedReceipt: boolean // 領収書発行済フラグ
  isReceiptExpired: boolean // 領収書発行期限切れフラグ
  hasDownloadItem: boolean // ダウンロード商品有無フラグ
  itemsCount: number
  subtotal: number
  downloadSetDiscount: number | undefined
  couponDiscount: number
  tpointDiscount: number
  total: number
  shipment: {
    lastname: string
    firstname: string
    lastnameRuby: string
    firstnameRuby: string
    tel: string
    zipcode: string
    prefecture: string
    address1: string
    address2: string
    address3: string | undefined
  }
  payment: Payment
  shipping: Shipment
  archiveDownloadUrl: string | null
  ikkatsu: {
    place1: string
    place2: string | null
    place3: string | null
    place4: string | null
  } | null
  selflabo: {
    place1: string
    place2: string | null
    place3: string | null
    place4: string | null
  } | null
  not4postcardFlag: boolean
}

export interface IOrderSeed {
  shipmentCode: Shipment['code'] | null
  paymentCode: 'credit' | 'paidy' | 'np_wiz' | 'tpoint' | 'bank' | 'cod'
  couponCode: string | null
  tpoint: number
  memberAddressId: number | null
  memberAddressHash: string | null
  notice: string | null
  creditToken: string | null
  saveCreditcardFlag: boolean
  checkSum: string
  total: number
}

export interface IPaypayOrderSeed {
  shipmentCode: 'ikkatsu' | 'mailbin' | 'takuhai' | 'download' | null
  paymentCode: 'paypay'
  couponCode: string | null
  tpoint: number
  total: number
  shipMemberAddressId: number | null
  shipMemberAddressHash: string | null
  notice: string | null
  checkSum: string
}

export interface IPaypayOrderProps {
  url: string
  deeplink: string
  expiryDate: number
}

export interface IOrderIkkatsu {
  place1: string
  place2: string | null
  place3: string | null
  place4: string | null
}

export interface IOrderSelflabo {
  place1: string
  place2: string | null
  place3: string | null
  place4: string | null
}

export default class OrderEntity {
  private _props: IOrderProps

  constructor(props: IOrderProps) {
    this._props = props
  }

  get props(): IOrderProps {
    return this._props
  }

  // 領収書発行発行対象の判定（true:領収書発行対象、false:領収書発行対象外）
  // TODO テストコード実装
  get canIssueReceipt(): boolean {
    const { payment, isShipped } = this._props

    // クレジットカード支払い or PayPay支払い、且つ、発送済み なら領収書発行対象
    if ((payment.code === 'credit' || payment.code === 'paypay') && isShipped) {
      return true
    }
    return false
  }

  get receiptUrl(): string {
    const {
      orderNum,
      shipment: { tel }
    } = this._props
    return `https://secure.photochoice.jp/track/?order_num=${orderNum}&tel=${tel}`
  }

  get orderDate(): string {
    const date = dayjs(this._props.orderDt, DATE_TIME_FORMAT)
    return date.format('YYYY年MM月DD日')
  }

  get shipmentDate(): string | null {
    const { shipmentDt } = this.props
    if (!shipmentDt) return null
    const date = dayjs(shipmentDt, DATE_TIME_FORMAT)
    return date.format('YYYY年MM月DD日')
  }

  get fullnameWithRuby(): string {
    const { lastname, firstname, lastnameRuby, firstnameRuby } = this._props.shipment
    return `${lastname} ${firstname}（${lastnameRuby} ${firstnameRuby}）`
  }
}

export const OrderSeedFactory = (props?: Partial<IOrderSeed>): IOrderSeed => {
  return {
    shipmentCode: 'mailbin',
    paymentCode: 'credit',
    couponCode: 'abcdef',
    tpoint: 200,
    memberAddressId: 1,
    memberAddressHash: '',
    notice: null,
    creditToken: null,
    saveCreditcardFlag: false,
    checkSum: 'askljnvaklga',
    total: 0,
    ...props
  }
}

export const OrderPropsFactory = (props?: Partial<IOrderProps>): IOrderProps => {
  return {
    id: 9150345,
    orderNum: 2913002,
    orderDt: '2019-03-01 10:00:00.000000',
    shipmentDt: '2019-03-03 17:00:00.000000',
    isShipped: true,
    hasIssuedReceipt: false,
    isReceiptExpired: false,
    hasDownloadItem: false,
    itemsCount: 1,
    subtotal: 465,
    couponDiscount: 0,
    tpointDiscount: 0,
    downloadSetDiscount: 0,
    total: 718,
    shipment: {
      lastname: '山田',
      firstname: '太郎',
      lastnameRuby: 'ヤマダ',
      firstnameRuby: 'タロウ',
      tel: '0312345678',
      zipcode: '1600022',
      prefecture: '東京都',
      address1: '新宿区西新宿',
      address2: '6丁目3-1',
      address3: '新宿アイランドウイング'
    },
    payment: {
      code: 'bank',
      feeAmount: 108
    },
    shipping: {
      code: 'mailbin',
      feeAmount: 500
    },
    archiveDownloadUrl: null,
    ikkatsu: null,
    selflabo: null,
    not4postcardFlag: false,
    ...props
  }
}

export const OrderEntityFactory = (props?: Partial<IOrderProps>): OrderEntity => {
  return new OrderEntity(OrderPropsFactory(props))
}
