export interface IAlbumProps {
  id: number
  name: string
  parentId: number | null
  displayOrder: number | null
}
export default class AlbumEntity {
  private _props: IAlbumProps

  constructor(props: IAlbumProps) {
    this._props = props
  }

  get props(): IAlbumProps {
    return this._props
  }
}
export interface Branch {
  data: AlbumEntity
  children: Branch[] | null
}

export const AlbumPropsFactory = (props?: Partial<IAlbumProps>): IAlbumProps => ({
  id: 1,
  parentId: null,
  name: '幸ヶ谷幼稚園 豆まき・日常の様子',
  displayOrder: null,
  ...props
})

export const AlbumEntityFactory = (props?: Partial<IAlbumProps>): AlbumEntity => {
  return new AlbumEntity(AlbumPropsFactory(props))
}
