import { INewsProps } from '@/entities/News'

export interface INewsState {
  byId: {
    [id: string]: INewsProps
  }
}

export const initialState = (): INewsState => ({
  byId: {}
})
