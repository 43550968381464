








import Vue from 'vue'
import AdManager from '@/components/shared/atoms/Ad/AdManager'
import { isMobile } from '@/utils/helper'

export default Vue.extend({
  components: {
    AdManager
  },
  computed: {
    isMobile(): boolean {
      if (isMobile) return true
      return false
    }
  }
})
