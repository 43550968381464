import { Child, ChildSeed, Snapsnap } from '@photocreate/snapsnap-api'
import { IChildProps, IChildSeed } from '@/entities/Child'

export const toChildProps = (child: Child): IChildProps => {
  const { id, first_name, last_name, sex, birth_year, birth_month, profile_image } = child
  return {
    id,
    firstname: first_name,
    lastname: last_name,
    sex,
    birthYear: birth_year,
    birthMonth: birth_month,
    profileImage: profile_image || null
  }
}

// TODO: Update用のschemaにわかりやすい名前をつける
export const toChildSchema = (child: Partial<IChildProps>): Snapsnap.Group.UpdateGroupChildRequest['child'] => {
  const { firstname, lastname, birthMonth, birthYear, sex, profileImage } = child

  return {
    first_name: firstname || '',
    last_name: lastname,
    birth_year: birthYear || undefined,
    birth_month: birthMonth || undefined,
    sex: sex || undefined,
    profile_image: profileImage || undefined
  }
}

export const toChildSeed = (child: IChildSeed): ChildSeed => {
  const { sex, birthYear, birthMonth, firstname, lastname, profileImage } = child

  return {
    first_name: firstname || '',
    last_name: lastname,
    birth_year: birthYear || undefined,
    birth_month: birthMonth || undefined,
    sex: sex || undefined,
    profile_image: profileImage || undefined
  }
}
