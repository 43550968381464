import { ISubscriptionProps } from '@/entities/Subscription'

export const Types = {
  STORE_SUBSCRIPTION: 'subscription/store_subscription'
} as const

export class StoreSubscription implements FluxStandardAction {
  type = Types.STORE_SUBSCRIPTION
  constructor(public payload: ISubscriptionProps[]) {}
}
