import { OrderItemArticle } from '@photocreate/snapsnap-api'
import { IOrderItemArticle } from '@/entities/OrderItem'
import { toPriceProps } from './price'

export const toOrderItemArticleProps = (orderItemArticle: OrderItemArticle): IOrderItemArticle => {
  const { event_id, event_name, price, amount, subtotal, discount, has_original_logo, has_image_edit, caption_str } = orderItemArticle
  return {
    eventId: event_id,
    eventName: event_name,
    price: toPriceProps(price),
    amount,
    subtotal,
    discount,
    hasOriginalLogo: has_original_logo,
    hasImageEdit: has_image_edit,
    captionStr: caption_str
  }
}
