import { Store } from 'vuex'
import { RootState } from '@/store'
import GroupEntity, { IGroupProps } from '@/entities/Group'
import GroupMemberEntity, { IGroupMemberProps } from '@/entities/GroupMember'
import ChildEntity, { IChildProps } from '@/entities/Child'
import SchoolEntity, { ISchoolProps } from '@/entities/School'
import GroupInvitationEntity, { IGroupInvitationProps } from '@/entities/GroupInvitation'
import { IGroupSetting } from '@/types/domain/group'
import { Fields as childInputFieldProps } from '@/utils/validators/form/Group/Child'
import {
  StoreGroups,
  StoreGroupMembers,
  StoreChildren,
  RemoveGroup,
  RemoveChildren,
  RemoveChild,
  RemoveGroupMembers,
  RemoveGroupMember,
  StoreSchools,
  StoreGroupInvitations,
  RemoveGroupInvitation,
  RemoveGroupInvitations,
  StoreChildInputField,
  ClearChildInputField,
  StoreSchoolpassInputField,
  ClearSchoolpassInputField
} from '@/store/modules/group/types'
import * as types from '@/store/modules/group/types'
import { uniq, pipe, curriedReduce, curriedPluck, curriedMap, nonNullable } from '@/utils/helper'

export default class GroupRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getGroups(): GroupEntity[] {
    const data = Object.values(this._store.state.group.byId)
    return data.map(props => new GroupEntity(props))
  }

  getGroupsByEvent({ eventId }: { eventId: number }): GroupEntity[] {
    const groups = this.getGroups()
    return groups.filter(group => {
      const schools = this.getSchools(group.props.id)
      return schools.some(school => school.props.eventIds.includes(eventId))
    })
  }

  saveGroups(groups: IGroupProps[]) {
    this._store.commit(new StoreGroups(groups))
  }

  removeGroup(groupId: number) {
    this._store.commit(new RemoveGroup(groupId))

    // Remove related groupMembers and children as well
    this._store.commit(new RemoveGroupMembers(groupId))
    this._store.commit(new RemoveChildren(groupId))
    this._store.commit(new RemoveGroupInvitations(groupId))
  }

  saveGroupSetting(params: { groupId: number; setting: IGroupSetting }) {
    this._store.commit(new types.StoreGroupSetting(params))
  }

  getGroupSetting(groupId: number): IGroupSetting | null {
    return this._store.state.group.settings[groupId] || null
  }

  getGroupSettings(groupIds: number[]): IGroupSetting[] {
    return groupIds.map(groupId => this._store.state.group.settings[groupId]).filter(Boolean)
  }

  getAdminGroup(): GroupEntity | null {
    return this.getGroups().find(group => group.props.isAdmin) || null
  }

  getNonAdminGroups(): GroupEntity[] {
    return this.getGroups().filter(group => !group.props.isAdmin)
  }

  getGroupById(groupId: number): GroupEntity | null {
    const props = this._store.state.group.byId[groupId]

    if (!props) return null

    return new GroupEntity(props)
  }

  getGroupMember(params: { groupId: number; groupMemberId: number }): GroupMemberEntity | null {
    const { groupId, groupMemberId } = params
    const groupMember = this._store.state.group.groupMembers[groupId]
    if (!groupMember) return null

    const props = groupMember[groupMemberId]
    return props ? new GroupMemberEntity(props) : null
  }

  getGroupMembers(groupId: number): GroupMemberEntity[] {
    const data = this._store.state.group.groupMembers[groupId]
    return data ? Object.values(data).map(props => new GroupMemberEntity(props)) : []
  }

  saveGroupMember(params: { groupId: number; groupMember: IGroupMemberProps }) {
    const { groupId, groupMember } = params
    this.saveGroupMembers({ groupId, groupMembers: [groupMember] })
  }

  saveGroupMembers(params: { groupId: number; groupMembers: IGroupMemberProps[] }) {
    this._store.commit(new StoreGroupMembers(params))
  }

  removeGroupMember(params: { groupId: number; groupMemberId: number }) {
    this._store.commit(new RemoveGroupMember(params))
  }

  getChildById(params: { groupId: number; childId: number }): ChildEntity | null {
    const { groupId, childId } = params
    const children = this._store.state.group.children[groupId]
    if (!children) return null

    const props = children[childId]
    return props ? new ChildEntity(props) : null
  }

  getChildren(groupId: number): ChildEntity[] {
    const data = this._store.state.group.children[groupId]
    return data ? Object.values(data).map(props => new ChildEntity(props)) : []
  }

  getChildrenByEvent(eventId: number): { [groupId: number]: ChildEntity[] } {
    const schoolsByEvent = this.getSchoolsByEvent(eventId)
    return Object.entries(schoolsByEvent).reduce((acc, [key, val]) => {
      const groupId = Number(key)
      const schools = val
      const children = pipe(
        () => schools,
        curriedPluck('props'),
        curriedPluck('children'),
        curriedReduce((_acc, _ac) => [..._acc, ..._ac], [] as number[]), // flatten
        uniq,
        curriedMap(id => this.getChildById({ groupId, childId: id })),
        _ => _.filter(nonNullable)
      )
      if (!children.length) return acc
      acc[groupId] = children
      return acc
    }, {} as { [groupId: number]: ChildEntity[] })
  }

  saveChild(params: { groupId: number; child: IChildProps }) {
    const { groupId, child } = params
    this._store.commit(new StoreChildren({ groupId, children: [child] }))
  }

  saveChildren(params: { groupId: number; children: IChildProps[] }) {
    this._store.commit(new StoreChildren(params))
  }

  removeChild(params: { groupId: number; childId: number }) {
    this._store.commit(new RemoveChild(params))
  }

  saveGroup(group: IGroupProps) {
    this.saveGroups([group])
  }

  /**
   * School
   */
  getSchoolById(params: { groupId: number; schoolId: number }): SchoolEntity | null {
    const { groupId, schoolId } = params
    const schools = this._store.state.group.schools[groupId]
    if (!schools) return null

    const props = schools[schoolId]
    return props ? new SchoolEntity(props) : null
  }

  getSchools(groupId: number): SchoolEntity[] {
    const data = this._store.state.group.schools[groupId]
    return data ? Object.values(data).map(props => new SchoolEntity(props)) : []
  }

  getSchoolsByChild({ groupId, childId }: { groupId: number; childId: number }): SchoolEntity[] {
    const schools = this.getSchools(groupId)
    return schools.filter(school => school.isRelatedToChild(childId))
  }

  getSchoolsByEvent(eventId: number): { [groupId: number]: SchoolEntity[] } {
    const allSchools = this._store.state.group.schools
    return Object.entries(allSchools).reduce((acc, [key, val]) => {
      const groupId = Number(key)
      const schools = Object.values(val)
      const targetSchools = schools.filter(({ eventIds }) => eventIds.includes(eventId))
      if (!targetSchools.length) return acc
      acc[groupId] = targetSchools.map(props => new SchoolEntity(props))
      return acc
    }, {} as { [groupId: number]: SchoolEntity[] })
  }

  saveSchool(params: { groupId: number; school: ISchoolProps }) {
    const { groupId, school } = params
    this._store.commit(new StoreSchools({ groupId, schools: [school] }))
  }

  saveSchools(params: { groupId: number; schools: ISchoolProps[] }) {
    this._store.commit(new StoreSchools(params))
  }

  getGroupInvitation(params: { groupId: number; groupInvitationId: number }): GroupInvitationEntity | null {
    const { groupId, groupInvitationId } = params
    const groupInvitations = this._store.state.group.groupInvitations[groupId]
    if (!groupInvitations) return null

    const props = groupInvitations[groupInvitationId]
    return props ? new GroupInvitationEntity(props) : null
  }

  getGroupInvitations(groupId: number): GroupInvitationEntity[] {
    const data = this._store.state.group.groupInvitations[groupId]
    return data ? Object.values(data).map(props => new GroupInvitationEntity(props)) : []
  }

  saveGroupInvitation(params: { groupId: number; groupInvitation: IGroupInvitationProps }) {
    const { groupId, groupInvitation } = params
    this._store.commit(new StoreGroupInvitations({ groupId, groupInvitations: [groupInvitation] }))
  }

  saveGroupInvitations(params: { groupId: number; groupInvitations: IGroupInvitationProps[] }) {
    this._store.commit(new StoreGroupInvitations(params))
  }

  removeGroupInvitations(groupId: number) {
    this._store.commit(new RemoveGroupInvitations(groupId))
  }

  removeGroupInvitation(params: { groupId: number; groupInvitationId: number }) {
    this._store.commit(new RemoveGroupInvitation(params))
  }

  /**
   * 登録系
   */
  saveChildInputField(params: { field: childInputFieldProps; isImageDeleted: boolean }) {
    this._store.commit(new StoreChildInputField(params))
  }

  getChildInputField(): { field: childInputFieldProps; isImageDeleted: boolean } | null {
    const childInputField = this._store.state.group.childInputField
    return childInputField
  }

  clearChildInputField() {
    this._store.commit(new ClearChildInputField())
  }

  getSchoolpassInputField(): { childId: number | null; schools: SchoolEntity[]; schoolpassHint: string | null; schoolCheckList: { [key: number]: boolean }; isRegisteringNew: boolean } | null {
    const data = this._store.state.group.schoolpassInputField
    return data
  }

  saveSchoolpassInputField(params: { childId: number | null; schools: SchoolEntity[]; schoolpassHint: string | null; schoolCheckList: { [key: number]: boolean }; isRegisteringNew: boolean }) {
    this._store.commit(new StoreSchoolpassInputField(params))
  }

  clearSchoolpassInputField() {
    this._store.commit(new ClearSchoolpassInputField())
  }
}

export const GroupRepositoryFactory = (store: Store<RootState>): GroupRepository => {
  return new GroupRepository(store)
}
