export interface ICoordinate {
  top: number
  left: number
  bottom: number
  right: number
}
export interface IRegisterCartQueryProps {
  eventId: number
  photoId: number
  coordinate: ICoordinate
}

export interface ICartQueryStatusProps {
  hasCartQuery: boolean
  hasOrderHistory: boolean
}

export default class CartQueryEntity {
  private _props: ICartQueryStatusProps

  constructor(props: ICartQueryStatusProps) {
    this._props = props
  }

  get props(): ICartQueryStatusProps {
    return this._props
  }
}
