import { Context } from '@nuxt/types'
import Vue from 'vue'
import { APIClient as SnapsnapAPIClient } from '@/infra/network/Snapsnap/APIClient'
import { APIClient as VeriTransAPIClient } from '@/infra/network/VeriTrans/APIClient'
import { APIClient as FeatureFlagAPIClient } from '@/infra/network/FeatureFlag/APIClient'
import { createClient } from '@/infra/network/Contentful/APIClient'
import CartRepository from '@/repositories/CartRepository'
import MemberAddressRepository from '@/repositories/MemberAddressRepository'
import EventRepository from '@/repositories/EventRepository'
import GroupRepository from '@/repositories/GroupRepository'
import SnapsnapGateway from '@/gateways/SnapsnapGateway'
import VeriTransGateway from '@/gateways/VeriTransGateway'
import ContentfulGateway from '@/gateways/ContentfulGateway'
import FeatureFlagGateway from '@/gateways/FeatureFlagGateway'
import FAQRepository from '@/repositories/FAQRepository'
import PagerRepository from '@/repositories/PagerRepository'
import PriceRepository from '@/repositories/PriceRepository'
import OrderRepository from '@/repositories/OrderRepository'
import OrderItemRepository from '@/repositories/OrderItemRepository'
import OrderSummaryRepository from '@/repositories/OrderSummaryRepository'
import MetaRepository from '@/repositories/MetaRepository'
import NewsRepository from '@/repositories/NewsRepository'
import ProfileRepository from '@/repositories/ProfileRepository'
import VoiceRepository from '@/repositories/VoiceRepository'
import ToastRepository from '@/repositories/ToastRepository'
import SubscriptionRepository from '@/repositories/SubscriptionRepository'
import SummaryRepository from '@/repositories/SummaryRepository'
import PresentationRepository from '@/repositories/PresentationRepository'

export default (ctx: Context) => {
  const { store } = ctx
  const snapsnapAPIClient = new SnapsnapAPIClient(new MetaRepository(store))
  const veriTransAPIClient = new VeriTransAPIClient()
  const contentfulAPIClient = createClient()
  const featureFlagAPIClient = new FeatureFlagAPIClient()

  Vue.prototype.$cartRepository = new CartRepository(store)
  Vue.prototype.$memberAddressRepository = new MemberAddressRepository(store)
  Vue.prototype.$eventRepository = new EventRepository(store)
  Vue.prototype.$groupRepository = new GroupRepository(store)
  Vue.prototype.$snapsnapGateway = new SnapsnapGateway(snapsnapAPIClient)
  Vue.prototype.$veriTransGateway = new VeriTransGateway(veriTransAPIClient)
  Vue.prototype.$contentfulGateway = new ContentfulGateway(contentfulAPIClient)
  Vue.prototype.$featureFlagGateway = new FeatureFlagGateway(featureFlagAPIClient)
  Vue.prototype.$FAQRepository = new FAQRepository(store)
  Vue.prototype.$pagerRepository = new PagerRepository(store)
  Vue.prototype.$priceRepository = new PriceRepository(store)
  Vue.prototype.$orderRepository = new OrderRepository(store)
  Vue.prototype.$orderItemRepository = new OrderItemRepository(store)
  Vue.prototype.$orderSummaryRepository = new OrderSummaryRepository(store)
  Vue.prototype.$metaRepository = new MetaRepository(store)
  Vue.prototype.$newsRepository = new NewsRepository(store)
  Vue.prototype.$voiceRepository = new VoiceRepository(store)
  Vue.prototype.$toastRepository = new ToastRepository()
  Vue.prototype.$profileRepository = new ProfileRepository(store)
  Vue.prototype.$summaryRepository = new SummaryRepository(store)
  Vue.prototype.$subscriptionRepository = new SubscriptionRepository(store)
  Vue.prototype.$presentationRepository = new PresentationRepository(store)

  ctx.$cartRepository = new CartRepository(store)
  ctx.$memberAddressRepository = new MemberAddressRepository(store)
  ctx.$eventRepository = new EventRepository(store)
  ctx.$groupRepository = new GroupRepository(store)
  ctx.$FAQRepository = new FAQRepository(store)
  ctx.$pagerRepository = new PagerRepository(store)
  ctx.$priceRepository = new PriceRepository(store)
  ctx.$orderRepository = new OrderRepository(store)
  ctx.$orderItemRepository = new OrderItemRepository(store)
  ctx.$orderSummaryRepository = new OrderSummaryRepository(store)
  ctx.$newsRepository = new NewsRepository(store)
  ctx.$metaRepository = new MetaRepository(store)
  ctx.$voiceRepository = new VoiceRepository(store)
  ctx.$profileRepository = new ProfileRepository(store)
  ctx.$toastRepository = new ToastRepository()
  ctx.$subscriptionRepository = new SubscriptionRepository(store)
  ctx.$summaryRepository = new SummaryRepository(store)
  ctx.$presentationRepository = new PresentationRepository(store)
  ctx.$snapsnapGateway = new SnapsnapGateway(snapsnapAPIClient)
  ctx.$veriTransGateway = new VeriTransGateway(veriTransAPIClient)
  ctx.$contentfulGateway = new ContentfulGateway(contentfulAPIClient)
  ctx.$featureFlagGateway = new FeatureFlagGateway(featureFlagAPIClient)
}
