import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'
import { Snapsnap } from '@photocreate/snapsnap-api'
import { groupCacheGroup } from '@/const/cacheGroups'

export class Login implements APIRequest<Snapsnap.Auth.LoginResponse> {
  response: Snapsnap.Auth.LoginResponse
  path = '/login'
  method = HTTPMethod.POST
  constructor(public params: Snapsnap.Auth.LoginRequest) {}
}

export class Logout implements APIRequest<Snapsnap.Auth.LogoutResponse> {
  response: Snapsnap.Auth.LogoutResponse
  path = '/logout'
  method = HTTPMethod.POST
  deleteAllCaches = true
}

export class LoginCheck implements APIRequest<Snapsnap.Auth.GetLoginProfileResponse> {
  response: Snapsnap.Auth.GetLoginProfileResponse
  path = '/login_profile'
  method = HTTPMethod.GET
}

export class PostPasswordForgot implements APIRequest<Snapsnap.Auth.PasswordForgotResponse> {
  response: Snapsnap.Auth.PasswordForgotResponse
  path = '/password_forgot'
  method = HTTPMethod.POST
  params: Snapsnap.Auth.PasswordForgotRequest
  constructor(params: { email: string }) {
    this.params = params
  }
}

export class PostPasswordReset implements APIRequest<Snapsnap.Auth.PasswordResetResponse> {
  response: Snapsnap.Auth.PasswordResetResponse
  path = '/password_reset'
  method = HTTPMethod.POST
  constructor(public params: Snapsnap.Auth.PasswordResetRequest) {}
}

export class PostVerifyPasswordResetToken implements APIRequest<Snapsnap.Auth.VerifyPasswordResetTokenResponse> {
  response: Snapsnap.Auth.VerifyPasswordResetTokenResponse
  path = '/verify_password_reset_token'
  method = HTTPMethod.POST
  constructor(public params: Snapsnap.Auth.VerifyPasswordResetTokenRequest) {
    this.params = params
  }
}

export class PostSignup implements APIRequest<Snapsnap.Auth.SignupResponse> {
  response: Snapsnap.Auth.SignupResponse
  path = '/signup'
  method = HTTPMethod.POST
  params: Snapsnap.Auth.SignupRequest
  constructor(params: Snapsnap.Auth.SignupRequest) {
    this.params = params
  }
}

export class PostVerifySignupStore implements APIRequest<Snapsnap.Auth.VerifySignupStoreResponse> {
  response: Snapsnap.Auth.VerifySignupStoreResponse
  path = '/verify_signup_store'
  method = HTTPMethod.POST
  params: Snapsnap.Auth.VerifySignupStoreRequest
  constructor(params: Snapsnap.Auth.VerifySignupStoreRequest) {
    this.params = params
  }
}

export class PostAcceptGroupInvitation implements APIRequest<Snapsnap.Group.AcceptGroupInvitationResponse> {
  response: Snapsnap.Group.AcceptGroupInvitationResponse
  path = '/accept_group_invitation'
  method = HTTPMethod.POST
  params: Snapsnap.Group.AcceptGroupInvitationRequest
  deleteCachesAfterSuccess = groupCacheGroup

  constructor(params: { token: string }) {
    this.params = {
      invitation_token: params.token
    }
  }
}

export class PostVerifyGroupInvitationToken implements APIRequest<Snapsnap.Auth.VerifyGroupInvitationTokenResponse> {
  response: Snapsnap.Auth.VerifyGroupInvitationTokenResponse
  path = '/verify_group_invitation_token'
  method = HTTPMethod.POST
  params: Snapsnap.Auth.VerifyGroupInvitationTokenRequest
  constructor(params: { token: string; email: string }) {
    this.params = params
  }
}

export class Certify implements APIRequest<Snapsnap.Auth.CertifyMemberResponse> {
  response: Snapsnap.Auth.CertifyMemberResponse
  path = '/certify'
  method = HTTPMethod.POST
  params: Snapsnap.Auth.CertifyMemberRequest

  constructor(token: string) {
    this.params = {
      token
    }
  }
}

export class PostSignoff implements APIRequest<Snapsnap.Auth.SignoffResponse> {
  response: Snapsnap.Auth.SignoffResponse
  path = '/signoff'
  method = HTTPMethod.POST
  deleteAllCaches = true
}
