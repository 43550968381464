import { MutationTree } from 'vuex'
import { ISubscriptionState } from '@/store/modules/subscription/state'
import { Types, StoreSubscription } from '@/store/modules/subscription/types'

export const mutations: MutationTree<ISubscriptionState> = {
  [Types.STORE_SUBSCRIPTION]: (state, action: StoreSubscription) => {
    const subscriptions = action.payload
    const hash = subscriptions.reduce((acc: ISubscriptionState['byId'], ac) => ((acc[ac.magazineId] = ac), acc), {})

    state.byId = {
      ...state.byId,
      ...hash
    }
  }
}
