// TODO: 仕様をドキュメント化 - 20190808 Shin Ando
import { Context } from '@nuxt/types'
import { Dictionary } from '@/types/utils'
import { extractTargetFields } from '@/utils/helper'
import dayjs from 'dayjs'

// dataStream
import { setRecord, UserBehaviorTargetList } from '@/plugins/dataStream'
import { UserBehaviorSchema } from '@/types/dataStream'

// userAgent
import { userAgentDedicatedToDataStream, confirmDesignType, getDeviceType } from '@/plugins/userAgent'

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
const redirectToSignupTarget = ['families-groupId-join']

export default async function ({ $snapsnapGateway, $profileRepository, redirect, route, query, $gtm }: Context) {
  try {
    const { profile } = await $snapsnapGateway.Auth.loggedInCheck()
    if (!profile) throw new Error('not logged in')
    $profileRepository.saveProfile(profile)

    if (UserBehaviorTargetList.includes(profile.id) && route.name && route.name !== 'order' && profile !== null) {
      // data stream 用のパラメータを設定
      const kinesisData: UserBehaviorSchema = {
        event_type: 'user_behavior',
        current_page: route.name,
        member_id: profile.id,
        ts: new Date().getTime(),
        design_type: confirmDesignType(),
        device_type: getDeviceType(),
        ...userAgentDedicatedToDataStream
      }

      // put record の実行
      setRecord(kinesisData, profile.id)
    }

    // gtm userIDをpush
    const now = dayjs().format(DATE_FORMAT)
    const fromDate = localStorage.getItem('last_login_date')
    if ((profile && fromDate && dayjs(now).diff(fromDate, 'hour') >= 24) || (profile && !fromDate)) {
      // 条件はログイン履歴がない、または24時間以上経過した
      $gtm.push({ user_id: profile.id })
      localStorage.setItem('last_login_date', dayjs().format(DATE_FORMAT))
    }
  } catch (e) {
    // TODO: error typeによる振り分け
    // emailが存在する場合は会員登録画面にpresetする必要があるのでforward. tokenも同様
    const forwardTarget = ['email', 'token']
    const forwardQuery = forwardTarget.reduce((acc, ac) => {
      if (query[ac]) acc[ac] = query[ac]
      return acc
    }, {} as Dictionary<string | (string | null)[]>)
    const forwardParams = route.params

    const redirectPath = route.name && redirectToSignupTarget.includes(route.name) ? '/signup' : '/login'
    const redirectQuery: Dictionary<string | (string | null)[] | undefined> = {
      ...forwardQuery,
      ...forwardParams,
      redirectTo: route.name
    }
    // TODO: use helper
    const existedKeys = Object.keys(redirectQuery).reduce((acc, ac) => ((acc[ac] = !!redirectQuery[ac]), acc), {} as { [key in keyof typeof redirectQuery]: boolean })
    const extracted = extractTargetFields(redirectQuery, existedKeys) as Dictionary<string | (string | null)[]>

    // gtm sessionが切れたら、last_login_dateを削除
    localStorage.removeItem('last_login_date')

    // ログアウト時にprofileRepositoryの中身をリセットする
    $profileRepository.removeProfile()

    return redirect(redirectPath, extracted)
  }
}
