import { RouteConfig, Route } from 'vue-router'
import { routes, RouteMeta } from '@/router'

const PARAMETER_REGEXP = /^:(.+)$/

// nameに該当するrouteを再帰的に探索するメソッド
export function getRoute(_name: string, _routes: RouteConfig[], parents: RouteConfig[] = []): { route: (RouteConfig & { meta?: RouteMeta }) | null; parents: RouteConfig[] } {
  let _parents = parents
  let result: RouteConfig | null = null

  // forEachで書くと目的のデータが見つかってもbreak出来ず、余分なループが発生するのでsomeを利用しています
  _routes.some(route => {
    if (route.name === _name) {
      result = route
      return true
    }

    // ヒットしなければ下層に_parents stackをforwardする
    if (route.children) {
      const newParentsStack = [..._parents, route]
      const r = getRoute(_name, route.children, newParentsStack)
      if (r.route) {
        // childrenにtargetが見つかったらparents stackを上書き
        result = r.route
        _parents = r.parents
        return true
      }
    }
  })

  return {
    route: result,
    parents: _parents
  }
}

export function buildUrl(name: string, params: Route['params'] | Route['query'] = {}): string {
  const { route, parents } = getRoute(name, routes)
  if (!route) throw new Error(`Route: ${name} is not defined.`)
  const _params = params

  // parameterとして利用したkeyを保存する変数
  const accumulator: string[] = []

  function transformPath(path: string) {
    return path
      .split('/')
      .map(p => {
        const match = p.match(PARAMETER_REGEXP)
        if (match) {
          const key = match[1]
          if (_params[key] === undefined) throw new Error(`Parameter: ${key} is not defined.`)
          const value = _params[key]
          // parameterとして利用したkeyを保存
          accumulator.push(key)
          return value
        } else {
          return p
        }
      })
      .join('/')
  }

  const parentPaths = parents.map(p => transformPath(p.path)).join('/')
  const path = transformPath(route.path)

  const base = parentPaths ? parentPaths + '/' + path : path

  // TODO: use helper
  const queries = _params as Route['query']
  const queryParameterStr = Object.entries(queries)
    .filter(([key]) => !accumulator.includes(key))
    .map(([key, val]) => {
      return `${key}=${val}`
    })
    .join('&')

  return base + (queryParameterStr ? '?' + queryParameterStr : '')
}
