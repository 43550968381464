import { INewsProps } from '@/entities/News'

export const Types = {
  STORE_NEWS_LIST: 'news/store_news_list'
}

export class StoreNewsList implements FluxStandardAction {
  type = Types.STORE_NEWS_LIST
  constructor(public payload: INewsProps[]) {}
}
