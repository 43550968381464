import { IOrderProps, IPaypayOrderProps } from '@/entities/Order'

export interface IOrderState {
  byId: {
    [id: number]: IOrderProps
  }
  paypayOrderResponse: IPaypayOrderProps
}

export const initialState = (): IOrderState => ({
  byId: {},
  paypayOrderResponse: {
    url: '',
    deeplink: '',
    expiryDate: 0
  }
})
