import { Store } from 'vuex'
import 'reflect-metadata'
import { singleton } from 'tsyringe'
import { RootState } from '@/store'
import { UchinokoData, UchinokoEventPhotos } from '@/types/domain/uchinoko'
import * as types from '@/store/modules/event/types'
import EventEntity, { IEventProps } from '@/entities/Event'
import AlbumEntity, { IAlbumProps, Branch } from '@/entities/Album'
import FaceEntity, { IFaceProps } from '@/entities/Face'
import PhotoEntity, { IPhotoProps } from '@/entities/Photo'
import EventCampaignEntity, { IEventCampaignProps } from '@/entities/EventCampaign'
import CartQueryEntity, { ICartQueryStatusProps } from '@/entities/C4'
import CampaignStatusEntity, { ICampaignStatusProps } from '@/entities/CampaignStatus'
import { IEventThumbnail } from '@/types/domain/event'

@singleton()
export default class EventRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  /**
   * Event
   */
  getEventsByIds(eventIds: number[]): { [eventId: number]: EventEntity } {
    const propsList = eventIds.map(id => this._store.state.event.byIds[id]).filter(Boolean)
    return propsList.reduce((acc, ac) => {
      acc[ac.id] = new EventEntity(ac)
      return acc
    }, {} as { [eventId: number]: EventEntity })
  }

  saveEvents(events: IEventProps[]) {
    this._store.commit(new types.StoreEvents(events))
  }

  getEvent(id: number): EventEntity | null {
    const state = this._store.state.event
    const event = state.byIds[id]
    return event ? new EventEntity(event) : null
  }

  saveEventThumbnails(thumbnails: IEventThumbnail[]) {
    this._store.commit(new types.StoreEventThumbnails(thumbnails))
  }

  getEventThumbnails() {
    const allEventIds = Object.keys(this._store.state.event.byIds).map(Number)
    return allEventIds.reduce((acc, ac) => {
      const data = this._store.state.event.thumbnails[ac]
      if (!data) return acc
      acc[ac] = data
      return acc
    }, {} as { [eventId: number]: Omit<IEventThumbnail, 'eventId'> })
  }

  /**
   * Album
   */
  saveAlbums(eventId: number, albums: IAlbumProps[]) {
    this._store.commit(new types.StoreAlbums({ eventId, albums }))
  }

  saveAlbum(eventId: number, album: IAlbumProps) {
    this.saveAlbums(eventId, [album])
  }

  getAlbum(eventId: number, albumId: number): AlbumEntity | null {
    const albums = this._store.state.event.albums[eventId]
    if (!albums) return null

    const props = albums[albumId]
    return props ? new AlbumEntity(props) : null
  }

  getAlbums(eventId: number): AlbumEntity[] {
    const event = this._store.state.event.albums[eventId]
    if (!event) return []
    const albums = Object.values(event)
    return albums.map(album => new AlbumEntity(album))
  }

  getAlbumsTree(eventId: number): Branch[] {
    const albums = this.getAlbums(eventId)

    // Albumsを、それぞれのparendIdをkeyにした連想配列で返す。ValueはAlbumEntityの配列。
    // parendIdが存在しないもの = rootのものについては専用key = rootを割り振っている。
    const byParents = albums.reduce((acc, ac) => {
      const parentId = ac.props.parentId
      if (parentId === null) {
        if (!acc['root']) acc['root'] = []
        acc['root'].push(ac)
        return acc
      }
      if (!acc[parentId]) acc[parentId] = []
      acc[parentId].push(ac)
      return acc
    }, {} as Record<number | 'root', AlbumEntity[]>)

    const getBranches = (list: AlbumEntity[]): Branch[] => {
      return list
        .sort((a, b) => {
          const displayOrderA = a.props.displayOrder
          const displayOrderB = b.props.displayOrder
          if (displayOrderA === null) return 0
          if (displayOrderB === null) return 0
          return displayOrderA === displayOrderB ? 0 : displayOrderA > displayOrderB ? 1 : -1
        })
        .map(data => {
          const children = byParents[data.props.id]
          return {
            data,
            children: children ? getBranches(children) : null
          }
        })
    }

    const root = byParents['root'] || []

    return getBranches(root)
  }

  getSimilarities() {
    return this._store.state.event.similarities
  }

  // かおが存在するtreeのみを返却(rootのみをfilter out)
  getUchinokoAlbumsTree(eventId: number): Branch[] {
    const albumsTree = this.getAlbumsTree(eventId)
    const uchinokoEventPhotosByAlbums = this.getUchinokoEventPhotosByAlbums(eventId)

    // 各ブランチの検証結果をmemo化してキャッシュしている
    const memo: Record<number, boolean> = {}
    const hasUchinoko = (branch: Branch): boolean => {
      const albumId = branch.data.props.id
      const hasMemo = memo[albumId] !== undefined
      /* istanbul ignore next */
      if (hasMemo) return memo[albumId]

      if (uchinokoEventPhotosByAlbums[albumId]) {
        memo[albumId] = true
        return true
      }

      const progenyResult = branch.children ? branch.children.some(hasUchinoko) : false
      memo[albumId] = progenyResult
      return progenyResult
    }

    return albumsTree.filter(hasUchinoko)
  }

  /**
   * 現在のAlbumから親方向への子供の系統を取得し、配列にして返す
   *
   * 例：
   * - { id: 1, parentId: 10 }
   * - { id: 10, parentId: 100 }
   * - { id: 100 } : 親
   *
   * のそれぞれのAlbumsがStoreに格納されている場合、albumId = 1を引数に渡すと
   *
   * [{ id: 1, parentId: 10 }, { id: 10, parendId: 100 }]
   *
   * という並びでデータが返却される
   */
  getAlbumChildLineage(eventId: number, albumId: number): AlbumEntity[] {
    const lineage: AlbumEntity[] = []

    const searchParent = (id: number) => {
      const album = this.getAlbum(eventId, id)
      if (!album) return

      // Check parent
      const parentId = album.props.parentId
      if (!parentId) return

      // Register self
      lineage.push(album)

      searchParent(parentId)
    }

    searchParent(albumId)

    return lineage
  }

  /**
   * Photo
   */
  getPhotos(params: { eventId: number; albumId: number }): PhotoEntity[] {
    const { eventId, albumId } = params
    const event = this._store.state.event.photos[eventId]
    if (!event) return []
    const photos = event[albumId]
    return photos ? Object.values(photos).map(props => new PhotoEntity(props)) : []
  }

  getPhotosByIds(params: { eventId: number; albumId: number; ids: number[] }): PhotoEntity[] {
    const { eventId, albumId, ids } = params
    const byEvent = this._store.state.event.photos[eventId]
    if (!byEvent) return []
    const byAlbum = byEvent[albumId]
    if (!byAlbum) return []
    const propsList = ids.map(id => byAlbum[id]).filter(Boolean)
    return propsList.map(props => new PhotoEntity(props))
  }

  getPhotosByPage(params: { eventId: number; albumId: number; page: number }): PhotoEntity[] {
    const { eventId, albumId, page } = params
    const byEvent = this._store.state.event.photosByPage[eventId]
    if (!byEvent) return []
    const byAlbum = byEvent[albumId]
    if (!byAlbum) return []
    const targetIds = byAlbum[page] || []
    return this.getPhotosByIds({ eventId, albumId, ids: targetIds })
  }

  getPhoto(params: { eventId: number; albumId: number; photoId: number }): PhotoEntity | null {
    const { eventId, albumId, photoId } = params
    const event = this._store.state.event.photos[eventId]
    if (!event) return null
    const photos = event[albumId]
    if (!photos) return null
    const props = photos[photoId]
    return props ? new PhotoEntity(props) : null
  }

  savePhotos(params: { eventId: number; albumId: number; photos: IPhotoProps[] }) {
    this._store.commit(new types.StorePhotos(params))
  }

  savePhotosByPage(params: { eventId: number; albumId: number; page: number; photos: IPhotoProps[] }) {
    const { eventId, albumId, page, photos } = params
    const ids = photos.map(photo => photo.id)

    this.savePhotos({ eventId, albumId, photos })
    this._store.commit(new types.StorePhotosByPage({ eventId, albumId, page, ids }))
  }

  savePhoto(params: { eventId: number; albumId: number; photo: IPhotoProps }) {
    const { eventId, albumId, photo } = params
    this.savePhotos({ eventId, albumId, photos: [photo] })
  }

  /**
   * Search
   */
  getSearchResult(): PhotoEntity[] {
    return this._store.state.event.searchResult.map(props => new PhotoEntity(props))
  }

  saveSearchResult(result: IPhotoProps[]) {
    this._store.commit(new types.StoreSearchResult(result))
  }

  clearSearchResult() {
    this._store.commit(new types.ClearSearchResult())
  }

  /**
   * Uchinoko
   */
  saveUchinokoData({ eventId, data }: { eventId: number; data: UchinokoData[] }) {
    /* 顔検索結果をStore(state.uchinokoFaces)に保存する前に、state.uchinokoFaces[eventId]の中身をリセットする。 */
    this._store.commit(new types.StoreResetUchinokoFaces({ eventId }))

    /* 顔検索結果をStore(state.uchinokoPhotos)に保存する前に、state.uchinokoPhotos[eventId]の中身をリセットする。 */
    this._store.commit(new types.StoreResetUchinokoPhotos({ eventId }))

    data.forEach(({ face, photos, albums }) => {
      // Save photos
      albums.forEach(album => {
        const albumPhotos = album.photoIds.map(id => photos.find(p => p.id === id)).filter(Boolean) as IPhotoProps[]
        this.savePhotos({ eventId, albumId: album.id, photos: albumPhotos })
      })

      // Save uchinoko face
      this.saveUchinokoFace({ eventId, face })

      // Save uchinoko event photos
      this.saveUchinokoEventPhotos({ eventId, faceId: face.id, albums })
    })
  }

  saveSimilarities(
    similarities: {
      photo_id: number
      similarity: number
    }[]
  ) {
    const reduced = similarities.reduce((acc, ac) => {
      const id = String(ac.photo_id)
      acc[id] = ac.similarity
      return acc
    }, {} as Record<string, number>)

    this._store.commit(new types.StoreSimilarities(reduced))
  }

  saveUchinokoFaces(params: { eventId: number; faces: IFaceProps[] }) {
    // UchinokoFaceを保存する際は、選択状態も初期化する。
    this._store.commit(new types.StoreUchinokoFaces(params))
    this._store.commit(new types.InitSelectedUchinokoFaces(params.eventId))
  }

  saveUchinokoFace(params: { eventId: number; face: IFaceProps }) {
    const { eventId, face } = params
    this.saveUchinokoFaces({ eventId, faces: [face] })
  }

  resetUchinokoData(eventId: number) {
    this._store.commit(new types.StoreResetUchinokoFaces({ eventId }))

    this._store.commit(new types.StoreResetUchinokoPhotos({ eventId }))
  }

  getUchinokoFace(eventId: number, faceId: string): FaceEntity | null {
    const byEvent = this._store.state.event.uchinokoFaces[eventId]
    if (!byEvent) return null
    const props = byEvent[faceId]
    return props ? new FaceEntity(props) : null
  }

  getUchiokoFaces(eventId: number): FaceEntity[] {
    const byEvent = this._store.state.event.uchinokoFaces[eventId]
    if (!byEvent) return []
    return Object.values(byEvent).map(props => new FaceEntity(props))
  }

  updateSelectedUchinokoFaces(params: { eventId: number; data: { [faceId: string]: boolean } }) {
    this._store.commit(new types.UpdateSelectedUchinokoFaces(params))
  }

  getSelectedUchinokoFaces(eventId: number): { [faceId: string]: boolean } {
    return this._store.state.event.selectedUchinokoFaces[eventId] || {}
  }

  saveUchinokoEventPhotos(params: { eventId: number; faceId: string; albums: UchinokoData['albums'] }) {
    const { eventId, faceId, albums } = params
    albums.forEach(album => {
      const { id, photoIds } = album
      this._store.commit(new types.StoreUchinokoPhotos({ eventId, albumId: id, faceId, photoIds }))
    })
  }

  /**
   * イベント内に含まれる全てのかお画像を、faceIdとalbumIdをkeyにした連想配列で取得する
   *
   * 例：
   * {
   *   // faceId
   *   "face1": {
   *     // albumId
   *     100: {
   *       // photoId
   *       1000: photoEntityA,
   *       1001: photoEntityB
   *     },
   *     200: {
   *       2000: photoEntityC
   *     }
   *   }
   * }
   */
  getUchinokoEventPhotos(eventId: number): UchinokoEventPhotos {
    const byEvent = this._store.state.event.uchinokoPhotos[eventId]
    if (!byEvent) return {}

    const hash = Object.entries(byEvent).reduce((acc, [faceId, byFace]) => {
      // Create Albums hash
      const albums = Object.entries(byFace).reduce((_acc, [key, val]) => {
        const albumId = parseInt(key, 10)
        const photos = this.getPhotosByIds({ eventId, albumId, ids: val })
        _acc[albumId] = photos.reduce((hash, photo) => ((hash[photo.props.id] = photo), hash), {} as Record<number, PhotoEntity>)
        return _acc
      }, {} as Record<number, Record<number, PhotoEntity>>)

      acc[faceId] = albums
      return acc
    }, {} as UchinokoEventPhotos)
    return hash
  }

  /**
   * 任意のFaceIdに合致するデータのみ表示
   */
  getUchinokoEventPhotosByFaces(eventId: number): UchinokoEventPhotos {
    const data = this.getUchinokoEventPhotos(eventId)
    const selectedUchinokoFaces = this.getSelectedUchinokoFaces(eventId)
    const selectedFaceIds = Object.keys(selectedUchinokoFaces).filter(faceId => selectedUchinokoFaces[faceId])

    return Object.entries(data).reduce((acc, [faceId, byFace]) => {
      if (selectedFaceIds.includes(faceId)) {
        acc[faceId] = byFace
      }
      return acc
    }, {} as UchinokoEventPhotos)
  }

  /**
   * 任意のFaceIdに合致するデータをAlbumごとにグルーピング
   */
  getUchinokoEventPhotosByAlbums(eventId: number): { [albumId: number]: { [photoId: number]: PhotoEntity } } {
    const byFaces = Object.values(this.getUchinokoEventPhotosByFaces(eventId))

    return byFaces.reduce((acc, ac) => {
      Object.entries(ac).forEach(([key, val]) => {
        const albumId = parseInt(key, 10)
        const byAlbum = val
        acc[albumId] = {
          ...acc[albumId],
          ...byAlbum
        }
      })
      return acc
    }, {} as { [albumId: number]: { [photoId: number]: PhotoEntity } })
  }

  getUchinokoEventPhotosByAlbumsCount(eventId: number): number {
    const byAlbums = this.getUchinokoEventPhotosByAlbums(eventId)
    return Object.values(byAlbums).reduce((acc, ac) => ((acc += Object.keys(ac).length), acc), 0)
  }

  saveCartQueryStatus(props: { eventId: number; cartQueryStatus: ICartQueryStatusProps }) {
    this._store.commit(new types.StoreCartQueryStatus(props))
  }

  getCartQueryStatus(eventId: number): CartQueryEntity | null {
    const byEvent = this._store.state.event.cartQueryStatus[eventId]
    if (!byEvent) return null
    return new CartQueryEntity(byEvent)
  }

  /**
   * EventCampaign
   */
  saveEventCampaigns(props: { eventId: number; campaigns: IEventCampaignProps[] }) {
    const commitObject = new types.StoreEventCampaigns(props)
    this._store.commit(commitObject)
  }

  getEventCampaigns(eventId: number): EventCampaignEntity[] {
    const byEvent = this._store.state.event.eventCampaigns[eventId]
    if (!byEvent) return []

    return Object.values(byEvent).map(props => new EventCampaignEntity(props))
  }

  /**
   * CampaignStatus
   */
  getCampaignStatus(campaignId: number): CampaignStatusEntity | null {
    const campaignStatus = this._store.state.event.campaignStatus[campaignId]
    if (!campaignStatus) return null
    return new CampaignStatusEntity(campaignStatus)
  }

  saveCampaignStatus(props: ICampaignStatusProps[]) {
    this._store.commit(new types.StoreCampaignStatus(props))
  }
}

export const EventRepositoryFactory = (store: Store<RootState>): EventRepository => {
  return new EventRepository(store)
}
