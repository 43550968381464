import { MutationTree } from 'vuex'
import Vue from 'vue'
import { IMemberAddressState, initialState } from '@/store/modules/memberAddress/state'
import { Types, StoreMemberAddresses, RemoveMemberAddress, StoreInputMemberAddress } from '@/store/modules/memberAddress/types'

export const mutations: MutationTree<IMemberAddressState> = {
  [Types.STORE_MEMBER_ADDRESSES]: (state, action: StoreMemberAddresses) => {
    const memberAddresses = action.payload
    const hash = memberAddresses.reduce((acc: IMemberAddressState['byId'], ac) => ((acc[ac.id] = ac), acc), {})

    state.byId = {
      ...state.byId,
      ...hash
    }
  },
  [Types.REMOVE_MEMBER_ADDRESS]: (state, action: RemoveMemberAddress) => {
    const memberAddressId = action.payload
    Vue.delete(state.byId, memberAddressId)
  },
  [Types.STORE_MEMBER_ADDRESS_INPUT_FIELD]: (state, action: StoreInputMemberAddress) => {
    state.memberAddressInputFields = action.payload
  },
  [Types.CLEAR_MEMBER_ADDRESS_INPUT_FIELD]: state => {
    state.memberAddressInputFields = initialState().memberAddressInputFields
  }
}
