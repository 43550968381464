import dayjs from 'dayjs'
import { Entry } from 'contentful'
import { DATE_TIME_FORMAT } from '@/const'
import { INewsProps } from '@/entities/News'
import News from '@/gateways/ContentfulGateway/types/news'
import { transtormEntry } from '@/gateways/ContentfulGateway/translator'
import { Raw } from '@/gateways/ContentfulGateway/types'

export function toNewsProps(data: Entry<Raw<News>>): INewsProps {
  const { id, content, title, category, startDateTime, endDateTime }: News = transtormEntry(data)

  function formatDate(dt: string): string {
    const parsed = dayjs(dt, 'YYYY-MM-DDTHH:mmZ')
    return parsed.format(DATE_TIME_FORMAT)
  }

  return {
    id,
    content,
    title,
    category: category || 3,
    startDateTime: formatDate(startDateTime),
    endDateTime: formatDate(endDateTime)
  }
}
