import { SnapsnapGatewayBase } from './base'
import {
  GetMemberAddresses,
  PostMemberAddress,
  GetMemberAddress,
  PutMemberAddress,
  DeleteMemberAddress,
  PutMemberPassword,
  PostMemberEmailResetRequest,
  PostMemberEmailReset,
  GetMemberSubscriptions,
  PutMemberSubscriptions,
  PutProfile
} from '@/infra/network/Snapsnap/requests/profile'
import { toSubscriptionSchema, toSubscriptionProps } from '@/gateways/SnapsnapGateway/translator'
import { ISubscriptionProps } from '@/entities/Subscription'
import { IMemberAddressProps, IMemberAddressSeed } from '@/entities/MemberAddress'
import { toMemberAddressProps, toMemberAddressSeed, toMemberAddressSchema } from './translator/memberAddress'
import { toProfileFields, toProfileProps } from './translator/profile'
import { IProfileFields } from '@/entities/Profile'

export default class Profile extends SnapsnapGatewayBase {
  async updateProfile(fields: IProfileFields) {
    const schema = toProfileFields(fields)
    const { profile } = await this.apiClient.request(new PutProfile(schema))
    return toProfileProps(profile)
  }

  async getMemberAddresses(): Promise<IMemberAddressProps[]> {
    const { addresses } = await this.apiClient.request(new GetMemberAddresses())
    return addresses.map(toMemberAddressProps)
  }

  async createMemberAddress(props: IMemberAddressSeed): Promise<IMemberAddressProps> {
    const schema = toMemberAddressSeed(props)
    const { address } = await this.apiClient.request(new PostMemberAddress(schema))
    return toMemberAddressProps(address)
  }

  async getMemberAddress(memberAddressId: number): Promise<IMemberAddressProps> {
    const { address } = await this.apiClient.request(new GetMemberAddress(memberAddressId))
    return toMemberAddressProps(address)
  }

  async updateMemberAddress(params: { memberAddressId: number; memberAddress: Partial<IMemberAddressProps> }): Promise<IMemberAddressProps> {
    const { memberAddressId, memberAddress } = params
    const schema = toMemberAddressSchema(memberAddress)
    const { address } = await this.apiClient.request(new PutMemberAddress({ memberAddressId, memberAddress: schema }))
    return toMemberAddressProps(address)
  }

  async deleteMemberAddress(memberAddressId: number) {
    await this.apiClient.request(new DeleteMemberAddress(memberAddressId))
  }

  async updateMemberPassword(params: { password: string; newPassword: string }) {
    const { password, newPassword } = params
    const memberPassword = { password, new_password: newPassword }
    return await this.apiClient.request(new PutMemberPassword({ memberPassword }))
  }

  async memberEmailResetRequest(email: string) {
    return await this.apiClient.request(new PostMemberEmailResetRequest(email))
  }

  async memberEmailReset(params: { email: string; token: string }) {
    const { email, token } = params
    return await this.apiClient.request(new PostMemberEmailReset({ email, token }))
  }

  async getMemberSubscriptions(): Promise<ISubscriptionProps[]> {
    const { subscriptions } = await this.apiClient.request(new GetMemberSubscriptions())
    return subscriptions.map(toSubscriptionProps)
  }

  async updateMemberSubscriptions(params: { magazineId: number; readFlag: boolean }[]) {
    const subscriptions = params.map(param => toSubscriptionSchema(param))
    return await this.apiClient.request(new PutMemberSubscriptions({ subscriptions }))
  }
}
