import { MutationTree } from 'vuex'
import Vue from 'vue'
import { ICartItemProps } from '@/entities/CartItem'
import { ICartState, initialState } from '@/store/modules/cart/state'
import { Types, StoreCartItems, StoreCart, RemoveCartItem, RemoveCartItems, StoreDeletedCartItems, RemoveDeletedCartItems } from '@/store/modules/cart/types'

export const mutations: MutationTree<ICartState> = {
  [Types.STORE_CART]: (state, action: StoreCart) => {
    const cart = action.payload
    state.cart = cart
  },
  [Types.STORE_CART_ITEMS]: (state, action: StoreCartItems) => {
    const cartItems = action.payload
    state.cartItems = cartItems.reduce((acc, ac) => {
      const photoId = ac.photoId
      acc[photoId] = {
        ...acc[photoId],
        [ac.id]: ac
      }
      return acc
    }, state.cartItems as Record<number, Record<number, ICartItemProps>>)
  },
  [Types.CLEAR_CART_ITEMS]: state => {
    state.cartItems = initialState().cartItems
  },
  [Types.REMOVE_CART_ITEM]: (state, action: RemoveCartItem) => {
    const { cartItemId, photoId } = action.payload
    if (!state.cartItems[photoId]) return

    Vue.delete(state.cartItems[photoId], cartItemId)
  },
  [Types.REMOVE_CART_ITEMS]: (state, action: RemoveCartItems) => {
    const { photoId } = action.payload

    Vue.delete(state.cartItems, photoId)
  },
  [Types.STORE_DELETED_CART_ITEMS]: (state, action: StoreDeletedCartItems) => {
    const cartItems = action.payload
    state.deletedCartItems = cartItems.reduce((acc, ac) => {
      const photoId = ac.photoId
      acc[photoId] = {
        ...acc[photoId],
        [ac.id]: ac
      }
      return acc
    }, state.deletedCartItems as Record<number, Record<number, ICartItemProps>>)
  },
  [Types.REMOVE_DELETED_CART_ITEM]: (state, action: RemoveDeletedCartItems) => {
    const { photoId } = action.payload

    Vue.delete(state.deletedCartItems, photoId)
  },
  [Types.CLEAR_DELETED_CART_ITEMS]: state => {
    state.deletedCartItems = initialState().deletedCartItems
  }
}
