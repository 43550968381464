import { IMemberAddressProps, IMemberAddressSeed } from '@/entities/MemberAddress'

export interface IMemberAddressState {
  byId: {
    [id: number]: IMemberAddressProps
  }
  memberAddressInputFields: IMemberAddressSeed | null
}

export const initialState = (): IMemberAddressState => ({
  byId: {},
  memberAddressInputFields: null
})
