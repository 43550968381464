import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'
import { IRegisterCartQueryProps } from '@/entities/C4'

const ROOT = '/c4'

export class Detect implements APIRequest<Snapsnap.C4.UchinokoDetectResponse> {
  response: Snapsnap.C4.UchinokoDetectResponse
  path = ROOT + '/detect'
  method = HTTPMethod.POST
  params: Snapsnap.C4.UchinokoDetectRequest
  constructor(s3_uri: string) {
    this.params = {
      s3_uri
    }
  }
}

export class RegisterCartQuery implements APIRequest<Snapsnap.C4.EventCartQueryPostResponse> {
  response: Snapsnap.C4.EventCartQueryPostResponse
  path: string
  method = HTTPMethod.POST
  params: Snapsnap.C4.EventCartQueryPostRequest
  constructor(params: IRegisterCartQueryProps) {
    const { eventId, photoId, coordinate } = params
    this.path = ROOT + `/events/${eventId}/cart_query`
    this.params = {
      photo_id: photoId,
      coordinate
    }
  }
}

export class DeleteCartQuery implements APIRequest<Snapsnap.C4.EventCartQueryDeleteRequest> {
  response: Snapsnap.C4.EventCartQueryDeleteResponse
  path: string
  method = HTTPMethod.DELETE
  params: Snapsnap.C4.EventCartQueryDeleteRequest
  constructor(params: { eventId: number }) {
    const { eventId } = params
    this.path = ROOT + `/events/${eventId}/cart_query`
  }
}

export class EventUchinokoSearch implements APIRequest<Snapsnap.C4.EventUchinokoSearchResponse> {
  response: Snapsnap.C4.EventUchinokoSearchResponse
  path: string
  method = HTTPMethod.GET
  params: Snapsnap.C4.EventUchinokoSearchRequest
  constructor(params: { eventId: number; recommend?: boolean; ignoreOrderHistory?: boolean }) {
    const { eventId, recommend, ignoreOrderHistory } = params
    this.path = ROOT + `/events/${eventId}/photos/uchinoko_search`
    this.params = {
      recommend,
      ignore_order_history: ignoreOrderHistory
    }
  }
}
export class EventCartQuerySearch implements APIRequest<Snapsnap.C4.EventCartQuerySearchResponse> {
  response: Snapsnap.C4.EventCartQuerySearchResponse
  path: string
  method = HTTPMethod.GET
  params: Snapsnap.C4.EventCartQuerySearchRequest
  constructor(params: { eventId: number; recommend?: boolean }) {
    const { eventId, recommend } = params
    this.path = ROOT + `/events/${eventId}/photos/cart_query_search`
    this.params = {
      recommend
    }
  }
}
