import { IPriceProps } from '@/entities/Price'
import { IEventPhotoPrices } from '@/types/domain/event'

export interface IPriceState {
  eventPhotoPrices: IEventPhotoPrices[] | null
  byPhotoIds: {
    // Use photoId as the index key
    [id: number]: {
      [id: number]: IPriceProps
    }
  }
}

export const initialState = (): IPriceState => ({
  byPhotoIds: {},
  eventPhotoPrices: null
})
