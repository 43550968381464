import { SnapsnapGatewayBase } from './base'
import {
  GetZendeskSections,
  GetZendeskArticles,
  GetZendeskCategory,
  GetZendeskSection,
  GetZendeskArticle,
  GetZendeskPromotedArticles,
  SearchZendeskArticles
} from '@/infra/network/Snapsnap/requests/zendesk'
import { IFAQSectionProps } from '@/entities/FAQSection'
import { IFAQContentProps } from '@/entities/FAQContent'
import { IFAQCategoryProps } from '@/entities/FAQCategory'
import { toSectionProps, toContentProps, toCategoryProps } from './translator/faq'

export default class FAQ extends SnapsnapGatewayBase {
  async getCategory(categoryId: number): Promise<IFAQCategoryProps> {
    const { category } = await this.apiClient.request(new GetZendeskCategory(categoryId))
    return toCategoryProps(category)
  }

  async getSections(categoryId: number): Promise<IFAQSectionProps[]> {
    const { sections } = await this.apiClient.request(new GetZendeskSections(categoryId))
    return sections.map(toSectionProps)
  }

  async getSection(sectionId: number): Promise<IFAQSectionProps> {
    const { section } = await this.apiClient.request(new GetZendeskSection(sectionId))
    return toSectionProps(section)
  }

  async getPromotedContents(): Promise<IFAQContentProps[]> {
    const { articles } = await this.apiClient.request(new GetZendeskPromotedArticles())
    return articles.map(toContentProps)
  }

  async getContents(sectionId: number): Promise<IFAQContentProps[]> {
    const { articles } = await this.apiClient.request(new GetZendeskArticles(sectionId))
    return articles.map(toContentProps)
  }

  async getContent(contentId: number): Promise<IFAQContentProps> {
    const { article } = await this.apiClient.request(new GetZendeskArticle(contentId))
    return toContentProps(article)
  }

  async searchContents(query: string) {
    const { articles } = await this.apiClient.request(new SearchZendeskArticles(query))
    return articles.map(toContentProps)
  }
}
