import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'
import { eventCacheGroup } from '@/const/cacheGroups'

export class SearchEventPhotos implements APIRequest<Snapsnap.Event.SearchEventPhotosResponse> {
  response: Snapsnap.Event.SearchEventPhotosResponse
  path: string
  method = HTTPMethod.POST
  params: Snapsnap.Event.SearchEventPhotosRequest
  constructor({ eventId, photoNos }: { eventId: number; photoNos: string[] }) {
    this.path = `/events/${eventId}/photos/search`
    this.params = {
      photo_nos: photoNos
    }
  }
}

export class GetEvent implements APIRequest<Snapsnap.Event.GetEventResponse> {
  response: Snapsnap.Event.GetEventResponse
  path: string
  method = HTTPMethod.GET

  constructor(eventId: number) {
    this.path = `/events/${eventId}`
  }
}

export class UnlockEvent implements APIRequest<Snapsnap.Event.UnlockEventResponse> {
  response: Snapsnap.Event.UnlockEventResponse
  path: string
  method = HTTPMethod.POST
  params: Snapsnap.Event.UnlockEventRequest
  deleteCachesAfterSuccess = eventCacheGroup

  constructor({ eventId, password }: { eventId: number; password: string }) {
    this.path = `/events/${eventId}/unlock`
    this.params = {
      password
    }
  }
}

export class GetEventThumbnails implements APIRequest<Snapsnap.Event.GetEventThumbnailsResponse> {
  response: Snapsnap.Event.GetEventThumbnailsResponse
  path = '/events/thumbnails'
  method = HTTPMethod.GET
  params: Snapsnap.Event.GetEventThumbnailsRequest
  constructor({ eventIds }: { eventIds: number[] }) {
    this.params = {
      event_ids: eventIds
    }
  }
}

export class GetEventPhotoPrices implements APIRequest<Snapsnap.Event.GetEventPhotoPricesResponse> {
  response: Snapsnap.Event.GetEventPhotoPricesResponse
  path: string
  method = HTTPMethod.GET

  constructor(eventId: number) {
    this.path = `/events/${eventId}/photos/prices`
  }
}

export class GetCartQueryStatus implements APIRequest<Snapsnap.Event.GetCartQueryStatusResponse> {
  response: Snapsnap.Event.GetCartQueryStatusResponse
  path: string
  method = HTTPMethod.GET
  constructor(eventId: number) {
    this.path = `/events/${eventId}/cart_query_status`
  }
}
