

















import Vue from 'vue'
import { ErrorType } from '@/common/errors'
import NotFound from '@/containers/Error/NotFound'
import ChatBot from '@/containers/ChatBot'
import ServerError from '@/containers/Error/ServerError'

export default Vue.extend({
  components: {
    NotFound,
    ServerError,
    ChatBot
  },
  props: {
    error: {
      type: Object as () => { statusCode: number; message: string },
      required: true
    }
  },
  methods: {
    checkStatusCode(statusCode: keyof typeof ErrorType) {
      return this.error.statusCode === ErrorType[statusCode]
    }
  }
})
