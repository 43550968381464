import axios, { AxiosResponse } from 'axios'
import Config from '@/types/config'
import { NetworkClient } from '@/infra/network/NetworkClient'
import { APIRequest } from '@/infra/network/APIRequest'
import { APIError } from '@/infra/network/APIError'
import { HTTPMethod } from '@/infra/network/types'

interface HttpError extends Error {
  response?: {
    status: number
  }
  message: string
}

export class APIClient implements NetworkClient {
  baseURL: string
  timeout: number = 15 * 1000
  appConfig: Config['app']

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.appConfig = process.env.APP_CONFIG as any

    const isAgreedMode = process.env.FLAG_AGREED === 'enabled' && process.env.NODE_ENV !== 'production'

    if (!isAgreedMode) {
      this.baseURL = this.appConfig ? this.appConfig.externalServices.veriTrans.base : ''
    } else {
      this.baseURL = 'http://127.0.0.1:3010/veriTrans'
    }
  }

  request<U>(request: APIRequest<U>): Promise<U> {
    const isRead = request.method === HTTPMethod.GET

    return new Promise<U>((resolve, reject) => {
      axios
        .request({
          url: request.path,
          method: request.method,
          params: isRead && request.params,
          data: !isRead && request.params,
          timeout: this.timeout,
          baseURL: request.baseURL || this.baseURL
          // 2021/03/30 veritrans障害のため下記2点削除
          // withCredentials: true,
          // headers: this.createHeaders()
        })
        .then(data => {
          const response = request.parse ? request.parse(data) : this.parse<U>(data)
          resolve(response)
        })
        .catch(err => {
          const apiError = this.normalizeError(err)
          reject(apiError)
        })
    })
  }

  // Default parser
  private parse<U>(data: AxiosResponse): U {
    return data.data
  }

  // Convert axios error into APIError
  private normalizeError(error: HttpError): APIError {
    return {
      status: error.response && error.response.status,
      message: error.message,
      raw: error
    }
  }

  // Create headers
  private createHeaders() {
    let header = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      xhrFields: true,
      withCredentials: true,
      'Access-Control-Allow-Origin': '*'
    } as object

    // For Dev use only
    if (process.env.NODE_ENV !== 'production' && process.env.FLAG_AGREED === 'enabled') {
      header = {}
    }

    return header
  }
}
