import SummaryRepository from '@/repositories/SummaryRepository'
import ProfileRepository from '@/repositories/ProfileRepository'
import NewsRepository from '@/repositories/NewsRepository'
import NewsEntity from '@/entities/News'

export interface IPresenterParams {
  profileRepository: ProfileRepository
  summaryRepository: SummaryRepository
  newsRepository: NewsRepository
  featuredNewsId: string | null
}

export interface IPresenter {
  isLoggedIn: boolean
  totalItemsCount: number
  news: NewsEntity[]
}

export default ({ summaryRepository, profileRepository, newsRepository, featuredNewsId }: IPresenterParams): IPresenter => {
  const featuredNews = featuredNewsId ? newsRepository.getNewsById(featuredNewsId) : null
  const newsList = featuredNews ? [featuredNews] : []
  return {
    totalItemsCount: summaryRepository.getTotalItemsCount(),
    isLoggedIn: !!profileRepository.getProfile(),
    news: newsList
  }
}
