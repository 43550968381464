import { ICartProps } from '@/entities/Cart'
import { ICartItemProps } from '@/entities/CartItem'

export interface ICartState {
  cart: ICartProps | null
  cartItems: {
    [photoId: number]: {
      [id: number]: ICartItemProps
    }
  }
  deletedCartItems: {
    [photoId: number]: {
      [id: number]: ICartItemProps
    }
  }
}

export const initialState = (): ICartState => {
  return {
    cart: null,
    cartItems: {},
    deletedCartItems: {}
  }
}
