




















import Vue from 'vue'
import Footer from '@/components/shared/organisms/Footer'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default Vue.extend({
  components: {
    Footer
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    hideLogo: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    isInModalActions: {
      type: Boolean,
      default: false
    },
    isPhotoDetailModal: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    active: {
      immediate: true,
      handler() {
        clearAllBodyScrollLocks()
      }
    }
  },
  updated() {
    this.modalOpenDisableBodyScroll()
  },
  destroyed() {
    clearAllBodyScrollLocks()
  },
  methods: {
    modalOpenDisableBodyScroll() {
      const modal = this.$refs['modal'] as Element
      if (modal) {
        disableBodyScroll(modal)
      }
    }
  }
})
