import { config } from 'aws-sdk/global'
import Kinesis from 'aws-sdk/clients/kinesis'
import { CognitoIdentityCredentials } from 'aws-sdk/lib/core'
import {
  AddDeleteCartItemSchema,
  SubmitOrderSchema,
  AlbumListSchema,
  FaceSearchAssitSchema,
  ScreenShotSchema,
  ScreenShotPhotoSchema,
  AppLockSchema,
  EventPhotoSchema,
  EventPhotosSchema,
  CartDownloadSetModalLinkImpressionSchema,
  DownloadOptionBranchSchema,
  DownloadOptionBranchDownloadSetButtonClickSchema,
  DownloadOptionBranchOrderButtonClickSchema,
  DownloadOptionPhotoChoiceSubmitSchema,
  DownloadOptionCartLimitErrorSchema,
  NengaBannerClickSchema,
  CartQueryRegistrationFailureSchema,
  FaceDetectModalImpressionSchema,
  FaceDetectModalClickSchema,
  FaceDetectModalCloseSchema,
  FaceDetectSearchClickSchema,
  FaceDetectClearClickSchema,
  FaceDetectDisableClickSchema,
  AdBannerClickSchema,
  AdBannerImpressionSchema,
  SurveyImpressionsSchema,
  SurveyClicksSchema,
  SurveAnswersSchema,
  FaceSearchAddCart,
  additionalFaceSearchButtonClickSchema,
  additionalFaceSearchButtonImpressionSchema,
  RecommendedFaceSearchPvSchema,
  UserBehaviorSchema,
  MagazineSubscribeSignupSchema,
  MagazineSubscribeUpdateSchema
} from '@/types/dataStream'

const apiVersion = '2013-12-02'
const StreamName = 'snapsnap-frontend-datalake'

// cognitoの設定
config.region = 'ap-northeast-1'
config.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: process.env.AWS_CONFIG_IDENTITY_POOL_ID || ''
})

const kinesis = new Kinesis({
  apiVersion
})

type KinesisDataStreamParams =
  | AddDeleteCartItemSchema
  | SubmitOrderSchema
  | AlbumListSchema
  | FaceSearchAssitSchema
  | ScreenShotSchema
  | ScreenShotPhotoSchema
  | AppLockSchema
  | EventPhotoSchema
  | EventPhotosSchema
  | CartDownloadSetModalLinkImpressionSchema
  | DownloadOptionBranchSchema
  | DownloadOptionBranchDownloadSetButtonClickSchema
  | DownloadOptionBranchOrderButtonClickSchema
  | DownloadOptionPhotoChoiceSubmitSchema
  | DownloadOptionCartLimitErrorSchema
  | NengaBannerClickSchema
  | CartQueryRegistrationFailureSchema
  | FaceDetectModalImpressionSchema
  | FaceDetectModalClickSchema
  | FaceDetectModalCloseSchema
  | FaceDetectSearchClickSchema
  | FaceDetectClearClickSchema
  | FaceDetectDisableClickSchema
  | AdBannerClickSchema
  | AdBannerImpressionSchema
  | SurveyImpressionsSchema
  | SurveyClicksSchema
  | SurveAnswersSchema
  | UserBehaviorSchema
  | FaceSearchAddCart
  | additionalFaceSearchButtonClickSchema
  | additionalFaceSearchButtonImpressionSchema
  | RecommendedFaceSearchPvSchema
  | MagazineSubscribeSignupSchema
  | MagazineSubscribeUpdateSchema

let recordArray = [] as {
  Data: string
  PartitionKey: string
}[]

let isTimer = false as boolean

const setTimer = () => {
  setTimeout(() => {
    isTimer = false
    kinesis.putRecords(
      {
        Records: recordArray,
        StreamName
      },
      err => {
        if (err) {
          console.error(err)
        }
      }
    )
    recordArray = []
  }, 1000)
}

export const setRecord = (data: KinesisDataStreamParams, userId: number) => {
  const userIdString = userId.toString()
  if (!isTimer) {
    isTimer = true
    setTimer()
  }
  recordArray.push({
    Data: JSON.stringify(data),
    PartitionKey: userIdString
  })
}

export const UserBehaviorTargetList = [
  4378658,
  3702802,
  4283406,
  5079440,
  4588910,
  4165917,
  4983842,
  2700354,
  3074128,
  4341024,
  4213975,
  3919077,
  4348213,
  3757866,
  3115049,
  3607589,
  3930349,
  4857212,
  4440624,
  2470769,
  3491211,
  4574497,
  5018586,
  3566683,
  3591920,
  4843391,
  4484193,
  4180011,
  4529427,
  4482958,
  2231831,
  3665732,
  4270641,
  4363143,
  4787030,
  3215905,
  4002863,
  4823499,
  2542927,
  4623532,
  4853370,
  5254006,
  3756143,
  5217697,
  1060759,
  4598978,
  5079789,
  5052511,
  4242591,
  5060990,
  3412952,
  3869235,
  5206259,
  4656903,
  4627602,
  4223233,
  4397800,
  5105741,
  4305178,
  4761727,
  4336642,
  4484450,
  5136027,
  4517494,
  2100330,
  2985789,
  4645376,
  4550405,
  5102782,
  4960850,
  5352784,
  5060649
]
