import { MutationTree } from 'vuex'
import { IFAQState } from '@/store/modules/faq/state'
import { Types, StoreContents, StoreSections, StoreCategories } from '@/store/modules/faq/types'
import { IFAQSectionProps } from '@/entities/FAQSection'

export const mutations: MutationTree<IFAQState> = {
  [Types.STORE_CONTENTS]: (state, action: StoreContents) => {
    const contents = action.payload
    const hash = contents.reduce((acc: IFAQState['contents'], ac) => ((acc[ac.id] = ac), acc), {}) as IFAQState['contents']

    state.contents = {
      ...state.contents,
      ...hash
    }
  },
  [Types.STORE_SECTIONS]: (state, action: StoreSections) => {
    const { categoryId, sections } = action.payload
    const hash = sections.reduce((acc, ac) => ((acc[ac.id] = ac), acc), {} as Record<number, IFAQSectionProps>)

    state.sections = {
      ...state.sections,
      [categoryId]: {
        ...state.sections[categoryId],
        ...hash
      }
    }
  },
  [Types.STORE_CATEGORIES]: (state, action: StoreCategories) => {
    const categories = action.payload
    const hash = categories.reduce((acc, ac) => ((acc[ac.id] = ac), acc), {} as IFAQState['categories'])

    state.categories = {
      ...state.categories,
      ...hash
    }
  }
}
