import { APIRequest } from '@/infra/network/APIRequest'
import { Snapsnap } from '@photocreate/snapsnap-api'
import { HTTPMethod } from '@/infra/network/types'

export class GetZendeskCategory implements APIRequest<Snapsnap.Zendesk.GetZendeskCategoryResponse> {
  response: Snapsnap.Zendesk.GetZendeskCategoryResponse
  path: string
  method = HTTPMethod.GET
  constructor(categoryId: number) {
    this.path = `/zendesk/categories/${categoryId}`
  }
}

export class GetZendeskSections implements APIRequest<Snapsnap.Zendesk.GetZendeskCategorySectionsResponse> {
  response: Snapsnap.Zendesk.GetZendeskCategorySectionsResponse
  path: string
  method = HTTPMethod.GET
  constructor(categoryId: number) {
    this.path = `/zendesk/categories/${categoryId}/sections`
  }
}

export class GetZendeskSection implements APIRequest<Snapsnap.Zendesk.GetZendeskSectionResponse> {
  response: Snapsnap.Zendesk.GetZendeskSectionResponse
  path: string
  method = HTTPMethod.GET
  constructor(sectionId: number) {
    this.path = `/zendesk/sections/${sectionId}`
  }
}

export class GetZendeskPromotedArticles implements APIRequest<Snapsnap.Zendesk.GetZendeskArticlesResponse> {
  response: Snapsnap.Zendesk.GetZendeskArticlesResponse
  path: '/zendesk/articles'
  method = HTTPMethod.GET
  params: Snapsnap.Zendesk.GetZendeskArticlesRequest = {
    promoted: true
  }
}

export class GetZendeskArticles implements APIRequest<Snapsnap.Zendesk.GetZendeskArticlesResponse> {
  response: Snapsnap.Zendesk.GetZendeskArticlesResponse
  path: string
  method = HTTPMethod.GET
  constructor(sectionId: number) {
    this.path = `/zendesk/sections/${sectionId}/articles`
  }
}

export class GetZendeskArticle implements APIRequest<Snapsnap.Zendesk.GetZendeskArticleResponse> {
  response: Snapsnap.Zendesk.GetZendeskArticleResponse
  path: string
  method = HTTPMethod.GET
  constructor(articleId: number) {
    this.path = `/zendesk/articles/${articleId}`
  }
}

export class SearchZendeskArticles implements APIRequest<Snapsnap.Zendesk.SearchZendeskArticlesResponse> {
  response: Snapsnap.Zendesk.SearchZendeskArticlesResponse
  path = '/zendesk/articles/search'
  method = HTTPMethod.GET
  params: Snapsnap.Zendesk.SearchZendeskArticlesRequest
  constructor(query: string) {
    this.params = {
      query
    }
  }
}
