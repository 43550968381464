import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'
import { Snapsnap } from '@photocreate/snapsnap-api'

export class GetPayPayOrderPaymentStatus implements APIRequest<Snapsnap.Paypay.GetPaypayOrderPaymentStatusResponse> {
  response: Snapsnap.Paypay.GetPaypayOrderPaymentStatusResponse
  path: string
  method = HTTPMethod.GET
  constructor(paypayOrderId: number) {
    this.path = `/paypay/${paypayOrderId}/payment_status`
  }
}
export class GetPayPayOrderStatus implements APIRequest<Snapsnap.Paypay.GetPaypayOrderStatusResponse> {
  response: Snapsnap.Paypay.GetPaypayOrderStatusResponse
  path: string
  method = HTTPMethod.GET
  constructor(paypayOrderId: number) {
    this.path = `/paypay/${paypayOrderId}/order_status`
  }
}
