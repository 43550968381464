import Vue from 'vue'
import { v1 } from 'uuid'
import { Toast } from '@/types/domain/toast'

export default class ToastRepository {
  private _state: { queue: Toast[]; current: Toast | null }

  constructor() {
    this._state = Vue.observable({ queue: [], current: null })
  }

  getQueues() {
    return this._state.queue
  }

  addQueue(seed: Pick<Toast, 'text' | 'type'>) {
    this._state.queue.push({
      id: v1(),
      shown: false,
      ...seed
    })
  }

  removeQueue(index: number) {
    this._state.queue.splice(index, 1)
  }
}

export const ToastRepositoryFactory = (): ToastRepository => {
  return new ToastRepository()
}
