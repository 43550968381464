








import Vue from 'vue'
import { RouteMeta } from '@/router'

export default Vue.extend({
  head() {
    const meta = (this.$route.meta || {}) as RouteMeta
    const { head, title } = meta
    if (!title) {
      return {
        titleTemplate: ''
      }
    }
    if (head && head.meta) {
      return {
        title,
        meta: head.meta
      }
    }
    return {
      title
    }
  }
})
