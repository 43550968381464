import { IFAQSectionProps } from '@/entities/FAQSection'
import { IFAQContentProps } from '@/entities/FAQContent'
import { IFAQCategoryProps } from '@/entities/FAQCategory'

export const Types = {
  STORE_SECTIONS: 'faq/store_sections',
  STORE_CONTENTS: 'faq/store_contents',
  STORE_CATEGORIES: 'faq/store_categories'
}

export class StoreSections implements FluxStandardAction {
  type = Types.STORE_SECTIONS
  constructor(public payload: { categoryId: number; sections: IFAQSectionProps[] }) {}
}

export class StoreContents implements FluxStandardAction {
  type = Types.STORE_CONTENTS
  constructor(public payload: IFAQContentProps[]) {}
}

export class StoreCategories implements FluxStandardAction {
  type = Types.STORE_CATEGORIES
  constructor(public payload: IFAQCategoryProps[]) {}
}
