import { SnapsnapGatewayBase } from './base'
import { Coupon } from '@/types/domain/order'
import { IIkkatsu, ISelflabo } from '@/entities/Cart'
import { ISubscription } from '@/types/domain/member'
import { IOrderProps, IOrderSeed, IPaypayOrderProps, IPaypayOrderSeed } from '@/entities/Order'
import { GetOrder, VerifyCouponCode, CreateOrder, CreatePayPayOrder } from '@/infra/network/Snapsnap/requests/order'
import { toOrderProps, toOrderSeed, toPayPayOrderSeed, toPayPayOrderProps } from './translator/order'
import { toSubscriptionSchema } from './translator/subscription'

export default class Order extends SnapsnapGatewayBase {
  async getOrder(orderId: number): Promise<IOrderProps> {
    const { order } = await this.apiClient.request(new GetOrder(orderId))
    return toOrderProps(order)
  }

  async verifyCouponCode(code: string): Promise<Coupon> {
    const { coupon } = await this.apiClient.request(new VerifyCouponCode(code))
    const { coupon_type, discount } = coupon

    return {
      type: coupon_type,
      amount: discount
    }
  }

  async createOrder(params: { seed: IOrderSeed; subscription: ISubscription; ikkatsu: IIkkatsu | null; selflabo: ISelflabo | null }): Promise<IOrderProps> {
    const { seed, subscription, ikkatsu, selflabo } = params
    const { order } = await this.apiClient.request(
      new CreateOrder({
        order: toOrderSeed(seed),
        subscription: toSubscriptionSchema(subscription),
        ikkatsu,
        selflabo
      })
    )
    return toOrderProps(order)
  }

  async createPayPayOrder(params: { seed: IPaypayOrderSeed; subscription: ISubscription; ikkatsu: IIkkatsu | null; selflabo: ISelflabo | null }): Promise<IPaypayOrderProps> {
    const { seed, subscription, ikkatsu, selflabo } = params
    const response = await this.apiClient.request(
      new CreatePayPayOrder({
        order: toPayPayOrderSeed(seed),
        subscription: toSubscriptionSchema(subscription),
        ikkatsu,
        selflabo
      })
    )
    return toPayPayOrderProps(response)
  }
}
