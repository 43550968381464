import { SnapsnapGatewayBase } from './base'
import { IVoiceProps, VoicePropsFactory } from '@/entities/Voice'

export default class Voice extends SnapsnapGatewayBase {
  // FIXME: Contentfulを利用する
  // eslint-disable-next-line
  async getVoice(voiceId: number): Promise<IVoiceProps> {
    console.log(voiceId)
    return VoicePropsFactory()
  }
}
