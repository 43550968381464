








import Vue from 'vue'
import HeaderIcon from '@/components/shared/atoms/Icon/HeaderIcon'

export default Vue.extend({
  components: {
    HeaderIcon
  },
  props: {
    count: {
      type: Number,
      default: 0
    }
  }
})
