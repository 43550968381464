import { MutationTree } from 'vuex'
import Vue from 'vue'
import { IGroupMemberProps } from '@/entities/GroupMember'
import { IChildProps } from '@/entities/Child'
import { ISchoolProps } from '@/entities/School'
import { IGroupInvitationProps } from '@/entities/GroupInvitation'
import { IGroupState, initialState } from '@/store/modules/group/state'
import {
  Types,
  StoreGroups,
  StoreGroupMembers,
  StoreChildren,
  RemoveGroup,
  RemoveGroupMembers,
  RemoveGroupMember,
  RemoveChildren,
  RemoveChild,
  StoreSchools,
  StoreGroupInvitations,
  RemoveGroupInvitations,
  RemoveGroupInvitation,
  StoreGroupSetting,
  StoreChildInputField,
  StoreSchoolpassInputField
} from '@/store/modules/group/types'

export const mutations: MutationTree<IGroupState> = {
  [Types.STORE_GROUPS]: (state, action: StoreGroups) => {
    const groups = action.payload
    groups.forEach(group => {
      state.byId = {
        ...state.byId,
        [group.id]: group
      }
    })
  },
  [Types.REMOVE_GROUP]: (state, action: RemoveGroup) => {
    const groupId = action.payload
    Vue.delete(state.byId, groupId)
  },
  [Types.STORE_GROUP_SETTING]: (state, action: StoreGroupSetting) => {
    const { groupId, setting } = action.payload
    state.settings = {
      ...state.settings,
      [groupId]: setting
    }
  },
  [Types.STORE_GROUP_MEMBERS]: (state, action: StoreGroupMembers) => {
    const { groupId, groupMembers } = action.paylod
    const hash = groupMembers.reduce((acc: { [id: number]: IGroupMemberProps }, ac) => ((acc[ac.id] = ac), acc), {})

    state.groupMembers = {
      ...state.groupMembers,
      [groupId]: {
        ...state.groupMembers[groupId],
        ...hash
      }
    }
  },
  [Types.REMOVE_GROUP_MEMBERS]: (state, action: RemoveGroupMembers) => {
    const groupId = action.payload
    Vue.delete(state.groupMembers, groupId)
  },
  [Types.REMOVE_GROUP_MEMBER]: (state, action: RemoveGroupMember) => {
    const { groupId, groupMemberId } = action.payload
    const groupMembers = state.groupMembers[groupId]
    if (!groupMembers) return

    Vue.delete(state.groupMembers[groupId], groupMemberId)
  },
  [Types.STORE_CHILDREN]: (state, action: StoreChildren) => {
    const { groupId, children } = action.payload
    const hash = children.reduce((acc: { [id: number]: IChildProps }, ac) => ((acc[ac.id] = ac), acc), {})

    state.children = {
      ...state.children,
      [groupId]: {
        ...state.children[groupId],
        ...hash
      }
    }
  },
  [Types.REMOVE_CHILDREN]: (state, action: RemoveChildren) => {
    const groupId = action.payload
    Vue.delete(state.children, groupId)
  },
  [Types.REMOVE_CHILD]: (state, action: RemoveChild) => {
    const { groupId, childId } = action.payload
    const children = state.children[groupId]
    if (!children) return

    Vue.delete(state.children[groupId], childId)
  },
  [Types.STORE_SCHOOLS]: (state, action: StoreSchools) => {
    const { groupId, schools } = action.payload
    const hash = schools.reduce((acc: { [id: number]: ISchoolProps }, ac) => ((acc[ac.id] = ac), acc), {})

    state.schools = {
      ...state.schools,
      [groupId]: {
        ...state.schools[groupId],
        ...hash
      }
    }
  },
  [Types.STORE_GROUP_INVITATIONS]: (state, action: StoreGroupInvitations) => {
    const { groupId, groupInvitations } = action.payload
    const hash = groupInvitations.reduce((acc, ac) => ((acc[ac.id] = ac), acc), {} as Record<number, IGroupInvitationProps>)

    state.groupInvitations = {
      ...state.groupInvitations,
      [groupId]: {
        ...state.groupInvitations[groupId],
        ...hash
      }
    }
  },
  [Types.REMOVE_GROUP_INVITATIONS]: (state, action: RemoveGroupInvitations) => {
    const groupId = action.payload
    Vue.delete(state.groupInvitations, groupId)
  },
  [Types.REMOVE_GROUP_INVITATION]: (state, action: RemoveGroupInvitation) => {
    const { groupId, groupInvitationId } = action.payload
    const groupInvitations = state.groupInvitations[groupId]
    if (!groupInvitations) return

    Vue.delete(state.groupInvitations[groupId], groupInvitationId)
  },
  [Types.STORE_CHILD_INPUT_FIELD]: (state, action: StoreChildInputField) => {
    state.childInputField = action.payload
  },
  [Types.CLEAR_CHILD_INPUT_FIELD]: state => {
    state.childInputField = initialState().childInputField
  },
  [Types.STORE_SCHOOLPASS_INPUT_FIELD]: (state, action: StoreSchoolpassInputField) => {
    state.schoolpassInputField = action.payload
  },
  [Types.CLEAR_SCHOOLPASS_INPUT_FIELD]: state => {
    state.schoolpassInputField = initialState().schoolpassInputField
  }
}
