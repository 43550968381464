import { Snapsnap } from '@photocreate/snapsnap-api'
import { APIRequest } from '@/infra/network/APIRequest'
import { HTTPMethod } from '@/infra/network/types'

export class GetCreditCardInfo implements APIRequest<Snapsnap.Credit.GetCreditCardInfoResponse> {
  response: Snapsnap.Credit.GetCreditCardInfoResponse
  path = '/credit'
  method = HTTPMethod.GET
}

export class CreateOrUpdateCreditCardInfo implements APIRequest<Snapsnap.Credit.PostCreditCardInfoResponse> {
  response: Snapsnap.Credit.PostCreditCardInfoResponse
  path = '/credit'
  method = HTTPMethod.POST
  params: Snapsnap.Credit.PostCreditCardInfoRequest
  constructor(creditToken: string) {
    this.params = {
      credit_token: creditToken
    }
  }
}

export class DeleteCreditCardInfo implements APIRequest<Snapsnap.Credit.DeleteCreditCardInfoResponse> {
  response: Snapsnap.Credit.DeleteCreditCardInfoResponse
  path = '/credit/delete_all'
  method = HTTPMethod.DELETE
}
