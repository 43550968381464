
































import Vue from 'vue'
import { RawLocation } from 'vue-router'
import { oneOf } from '@/utils/helper'

export default Vue.extend({
  props: {
    to: {
      type: [String, Object as () => RawLocation],
      required: true
    },
    size: {
      type: String,
      default: 'md',
      validator: oneOf(['sm', 'md', 'lg'])
    },
    type: {
      type: String,
      default: 'primary',
      validator: oneOf(['primary', 'secondary'])
    },
    id: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'a'
    },
    external: {
      type: Boolean,
      default: () => false
    },
    targetBlank: {
      type: Boolean,
      default: false
    },
    download: {
      type: String,
      default: null
    }
  },
  computed: {
    typeClassName(): string {
      return `-${this.type}`
    },
    sizeClassName(): string {
      return `-${this.size}`
    }
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
    handleChange(event: { target: HTMLInputElement }) {
      if (!this.disabled) {
        this.$emit('change', event.target.files)
      }
    }
  }
})
