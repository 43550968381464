import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '@/const'

export interface INewsProps {
  id: string
  startDateTime: string
  endDateTime: string
  category: number
  title: string
  content: string
}

export default class NewsEntity {
  private _props: INewsProps

  constructor(props: INewsProps) {
    this._props = props
  }

  get props(): INewsProps {
    return this._props
  }

  get newsDate(): string {
    const date = dayjs(this._props.startDateTime, DATE_TIME_FORMAT)
    return date.format('YYYY.MM.DD')
  }

  get dateUnixtime(): number {
    return dayjs(this._props.startDateTime, DATE_TIME_FORMAT).unix()
  }

  get categoryClass(): string {
    const { category } = this._props
    switch (category) {
      case 1:
        return 'release'
      case 2:
        return 'important'
      case 3:
        return 'info'
      default:
        return ''
    }
  }

  get categoryStr(): string {
    const { category } = this._props
    switch (category) {
      case 1:
        return 'ニュース'
      case 2:
        return '重要なお知らせ'
      case 3:
        return 'お知らせ'
      default:
        return ''
    }
  }
}

export const NewsPropsFactory = (props?: Partial<INewsProps>): INewsProps => {
  return {
    id: '877937',
    startDateTime: '2019-08-29 00:00:00.000000',
    endDateTime: '2099-12-31 00:00:00.000000',
    category: 1,
    title: 'サイトリニューアルについて',
    content: 'スナップスナップのサイトがリニューアルしました。新しくなったスナップスナップをよろしくお願いいたします。',
    ...props
  }
}

export const NewsEntityFactory = (props?: Partial<INewsProps>): NewsEntity => {
  return new NewsEntity(NewsPropsFactory(props))
}
