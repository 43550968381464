import { SnapsnapGatewayBase } from './base'
import { toAddress } from './translator/yolp'
import { GetAddress } from '@/infra/network/Snapsnap/requests/yolp'

export default class Yolp extends SnapsnapGatewayBase {
  async getAddress(zipcode: string) {
    const result = await this.apiClient.request(new GetAddress(zipcode))
    return toAddress(result)
  }
}
