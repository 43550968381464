import { Price } from '@photocreate/snapsnap-api'
import { IPriceProps } from '@/entities/Price'

export const toPriceProps = (photo: Price): IPriceProps => {
  const { id, name, price, article_id, is_download, is_moovin, display_order } = photo
  return {
    id,
    name,
    isDownload: is_download,
    isMoovin: is_moovin,
    articleId: article_id,
    displayOrder: display_order,
    price
  }
}
