




import Vue from 'vue'
import { isDOMAccessible } from '@/utils/dom'

interface IData {
  pushFlag: boolean
}

export default Vue.extend({
  props: {
    adId: {
      type: String,
      default: 'div-gpt-ad-1487655737902-0'
    },
    unit: {
      type: String,
      default: '/8336322/snapsnap_pc_728x90'
    },
    size: {
      type: Array as () => number[],
      default: null
    }
  },
  data(): IData {
    return {
      pushFlag: false
    }
  },
  computed: {
    isScriptLoaded(): boolean {
      return isDOMAccessible() && !!googletag
    },
    adSlotWidth(): string {
      if (this.size) return this.size[0] + 'px'
      return 'auto'
    },
    adSlotHeight(): string {
      if (this.size) return this.size[1] + 'px'
      return 'auto'
    }
  },
  watch: {
    isScriptLoaded: {
      immediate: true,
      handler(val: boolean) {
        if (val) this.pushAd()
      }
    },
    $route(to, from) {
      if (to.path !== from.path && this.pushFlag) {
        this.pushAd()
        this.pushDisplay()
      }
    }
  },
  mounted() {
    // TODO:恒久対応
    setTimeout(() => {
      this.pushDisplay()
    }, 10)
    this.pushFlag = true
  },
  destroyed() {
    try {
      this.pushFlag = false
      window.googletag.destroySlots()
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    pushAd() {
      let slotType: string | number[] = []
      if (this.size) slotType = this.size
      if (!this.size) slotType = 'fluid'
      // sizeが入っていなければ、レスポンシブサイズ指定の'fluid'が入る
      googletag.cmd.push(() => {
        googletag.defineSlot(this.unit, slotType, this.adId).addService(googletag.pubads())
        googletag.pubads().enableSingleRequest()
        googletag.enableServices()
      })
    },
    pushDisplay() {
      googletag.cmd.push(() => {
        googletag.display(this.adId)
      })
    }
  }
})
