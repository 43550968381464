import { Route } from 'vue-router'
import { routes, RouteMeta } from '@/router'
import { buildUrl, getRoute } from '@/utils/url'

export interface BreadCrumb {
  name: string
  to?: string
}

export function parseBreadCrumbs(route: Route): BreadCrumb[] {
  const params = route.params
  const matched = route.matched
  const breadCrumbs: BreadCrumb[] = [{ name: 'トップ', to: '/' }]

  // 再帰的にparentを探索して、祖先から順にbreadCrumbにpushするメソッド
  function findParent(_parentName: string) {
    const res = getRoute(_parentName, routes)
    const parent = res.route
    /* istanbul ignore next */
    if (!parent || !parent.name) return
    /* istanbul ignore next */
    if (!parent.meta || !parent.meta.breadCrumb) return
    if (parent.meta.breadCrumb.parentName) {
      findParent(parent.meta.breadCrumb.parentName)
    }

    breadCrumbs.push({
      name: parent.meta.breadCrumb.name,
      to: buildUrl(parent.name, params)
    })
  }

  matched.forEach((match, index) => {
    /* istanbul ignore next */
    if (!match.name) return
    /* istanbul ignore next */
    const meta = (match.meta || {}) as RouteMeta
    if (!meta.breadCrumb) return
    const { parentName, name } = meta.breadCrumb

    // vue-routerが解決するtreeの外でparentが指定されている場合
    if (parentName) {
      findParent(parentName)
    }

    // 最後の要素はtoを設定しない
    const isLastIndex = index + 1 === matched.length

    // FIXME: 現在は isLastIndex == false に該当するパンくず仕様がないのでカバレッジから外す。
    // 仕様が追加されたらignoreを外してください。 - 20190708 Shin Ando
    /* istanbul ignore next */
    breadCrumbs.push({
      name,
      to: isLastIndex ? undefined : buildUrl(match.name, params)
    })
  })
  return breadCrumbs
}
