import { Store } from 'vuex'
import { RootState } from '@/store'
import * as types from '@/store/modules/orderItem/types'
import OrderItemEntity, { IOrderItemProps } from '@/entities/OrderItem'
import { StoreOrderItems } from '@/store/modules/orderItem/types'

export default class OrderItemRepository {
  private _store: Store<RootState>

  constructor(store: Store<RootState>) {
    this._store = store
  }

  getOrderItems(orderId: number): OrderItemEntity[] {
    const data = this._store.state.orderItem.orderItems[orderId]
    return data ? Object.values(data).map(props => new OrderItemEntity(props)) : []
  }
  // TODO:getOrderItemsは一括全取得ではなくページングで取得します。後ほど変更かけます。

  saveOrderItems(params: { orderItems: IOrderItemProps[] }) {
    this._store.commit(new StoreOrderItems(params))
  }

  saveOrderItem({ orderItem }: { orderItem: IOrderItemProps }) {
    this.saveOrderItems({ orderItems: [orderItem] })
  }

  getOrderItemById(params: { orderId: number; orderItemId: number }): OrderItemEntity | null {
    const { orderId, orderItemId } = params
    const byOrder = this._store.state.orderItem.orderItems[orderId]
    if (!byOrder) return null

    const props = byOrder[orderItemId]
    return props ? new OrderItemEntity(props) : null
  }

  getOrderItemsByIds(params: { orderId: number; ids: number[] }): OrderItemEntity[] {
    const { orderId, ids } = params
    const byOrder = this._store.state.orderItem.orderItems[orderId]
    if (!byOrder) return []
    const propsList = ids.map(id => byOrder[id]).filter(Boolean)
    return propsList.map(props => new OrderItemEntity(props))
  }

  getOrderItemsByPage(params: { orderId: number; page: number }): OrderItemEntity[] {
    const { orderId, page } = params
    const byOrder = this._store.state.orderItem.orderItemsByPage[orderId]
    if (!byOrder) return []
    const targetIds = byOrder[page] || []
    return this.getOrderItemsByIds({ orderId, ids: targetIds })
  }

  saveOrderItemsByPage(params: { orderId: number; page: number; orderItems: IOrderItemProps[] }) {
    const { orderId, page, orderItems } = params
    const ids = orderItems.map(orderItem => orderItem.id)

    this.saveOrderItems({ orderItems })
    this._store.commit(new types.StoreOrderItemsByPage({ orderId, page, ids }))
  }

  getDownloadableOrderItemsByPage(params: { orderId: number; page: number }): OrderItemEntity[] {
    const { orderId, page } = params
    const byOrder = this._store.state.orderItem.downloadableOrderItemsByPage[orderId]
    if (!byOrder) return []
    const targetIds = byOrder[page] || []
    return this.getOrderItemsByIds({ orderId, ids: targetIds })
  }

  saveDownloadableOrderItemsByPage(params: { orderId: number; page: number; orderItems: IOrderItemProps[] }) {
    const { orderId, page, orderItems } = params
    const ids = orderItems.map(orderItem => orderItem.id)

    this.saveOrderItems({ orderItems })
    this._store.commit(new types.StoreDownloadableOrderItemsByPage({ orderId, page, ids }))
  }
}

export const OrderItemRepositoryFactory = (store: Store<RootState>): OrderItemRepository => {
  return new OrderItemRepository(store)
}
