// TODO: 仕様をドキュメント化 - 20190808 Shin Ando
import { Context } from '@nuxt/types'

// dataStream
import { setRecord, UserBehaviorTargetList } from '@/plugins/dataStream'
import { UserBehaviorSchema } from '@/types/dataStream'

// userAgent
import { userAgentDedicatedToDataStream, confirmDesignType, getDeviceType } from '@/plugins/userAgent'

export default function ({ $profileRepository, route }: Context) {
  try {
    const profile = $profileRepository.getProfile()
    if (profile && profile.props.id && UserBehaviorTargetList.includes(profile.props.id) && route.name) {
      // data stream 用のパラメータを設定
      const kinesisData: UserBehaviorSchema = {
        event_type: 'user_behavior',
        current_page: route.name,
        member_id: profile.props.id,
        ts: new Date().getTime(),
        design_type: confirmDesignType(),
        device_type: getDeviceType(),
        ...userAgentDedicatedToDataStream
      }

      // put record の実行
      setRecord(kinesisData, profile.props.id)
    }
  } catch (e) {
    console.log(e)
  }
}
