export interface IFAQSectionProps {
  id: number
  name: string
  categoryId: number | null
  outdated: boolean
}

export default class FAQSectionEntity {
  private _props: IFAQSectionProps

  constructor(props: IFAQSectionProps) {
    this._props = props
  }

  get props(): IFAQSectionProps {
    return this._props
  }
}

export const FAQSectionPropsFactory = (props?: Partial<IFAQSectionProps>): IFAQSectionProps => {
  return {
    id: 877937,
    name: '会員登録について',
    categoryId: 100,
    outdated: false,
    ...props
  }
}

export const FAQSectionEntityFactory = (props?: Partial<IFAQSectionProps>): FAQSectionEntity => {
  return new FAQSectionEntity(FAQSectionPropsFactory(props))
}
