import { IFamilyProps } from '@/entities/Family'

export interface IFamilyState {
  byIds: {
    [key: number]: IFamilyProps
  }
  currentFamily: number | null
}

export const initialState = (): IFamilyState => ({
  byIds: {},
  currentFamily: null
})
