import { MemberAddress, MemberAddressSeed } from '@photocreate/snapsnap-api'
import { IMemberAddressProps, IMemberAddressSeed } from '@/entities/MemberAddress'

export const toMemberAddressProps = (memberAddress: MemberAddress): IMemberAddressProps => {
  const { id, lastname, firstname, lastname_ruby, firstname_ruby, tel, zipcode, prefecture, address1, address2, address3, hash } = memberAddress
  return {
    id,
    lastname,
    firstname,
    lastnameRuby: lastname_ruby,
    firstnameRuby: firstname_ruby,
    tel: tel || null,
    zipcode: zipcode || null,
    prefecture,
    address1: address1 || null,
    address2: address2 || null,
    address3: address3 || null,
    hash
  }
}

export const toMemberAddressSchema = (memberAddress: Partial<IMemberAddressProps>): Partial<MemberAddress> => {
  const { id, firstname, lastname, firstnameRuby, lastnameRuby, tel, zipcode, prefecture, address1, address2, address3, hash } = memberAddress

  return {
    id,
    firstname,
    lastname,
    firstname_ruby: firstnameRuby,
    lastname_ruby: lastnameRuby,
    tel: tel || undefined,
    zipcode: zipcode || undefined,
    prefecture,
    address1: address1 || undefined,
    address2: address2 || undefined,
    address3: typeof address3 === 'string' ? address3 : undefined, // 空文字でupdateできるように
    hash
  }
}

export const toMemberAddressSeed = (memberAddress: IMemberAddressSeed): MemberAddressSeed => {
  const { firstname, lastname, firstnameRuby, lastnameRuby, tel, zipcode, prefecture, address1, address2, address3 } = memberAddress

  return {
    firstname,
    lastname,
    firstname_ruby: firstnameRuby,
    lastname_ruby: lastnameRuby,
    tel,
    zipcode,
    prefecture,
    address1,
    address2,
    address3: address3 || undefined
  }
}
