import dayjs from 'dayjs'
import { DATE_TIME_FORMAT } from '@/const'
import CartEntity from '@/entities/Cart'

export type publishStatus = 'waiting' | 'republish' | 'close' | 'publish' | 'error'

export interface IEventProps {
  id: number
  name: string
  eventStartDt: string
  eventEndDt: string
  orderEndDt: string
  schoolShipFlag: boolean
  sponsoredPrintFlag: boolean
  not4saleFlag: boolean
  moovinFlag: boolean
  publishStatus: publishStatus
  notRepublicFlag: boolean
  passwordUnlockFlag: boolean
  passwordType: string
  defaultCaption: string | null
  leadText: string
  announcementText: string
  selflaboFlag: boolean
  not4postcardFlag: boolean
  downloadSetFlag: boolean
  spotlightFlag: boolean
}

export default class EventEntity {
  private _props: IEventProps

  constructor(props: IEventProps) {
    this._props = props
  }

  get props(): IEventProps {
    return this._props
  }

  get showImage(): boolean {
    // FIXME
    return true
  }

  get eventPeriodStr(): string {
    const eventStartDt = dayjs(this._props.eventStartDt, DATE_TIME_FORMAT).format('YYYY年M月D日')
    const eventEndDt = this._props.eventEndDt ? dayjs(this._props.eventEndDt, DATE_TIME_FORMAT).format('YYYY年M月D日') : ''
    return eventStartDt === eventEndDt ? eventStartDt : eventEndDt ? eventStartDt + '~' + eventEndDt : eventStartDt
  }

  get eventNum(): string {
    return 'E' + this._props.id
  }

  get orderEndDtUnixtime(): number {
    return dayjs(this._props.orderEndDt, DATE_TIME_FORMAT).unix()
  }

  get eventStartDtUnixtime(): number {
    return dayjs(this._props.eventStartDt, DATE_TIME_FORMAT).unix()
  }

  // 1度の注文で個別配送/一括配送を混在することはできないので、
  // 現在のカートの状態とイベントの配送方法に互換性があるか検証するフラグ
  isCompatibleWithCartShipmentIkkatsu(cart: CartEntity | null, cartitemsCount: number): boolean {
    if (!cart) return false
    if (!cartitemsCount) return true // カートが空の時は常に正
    const { schoolShipFlag } = this._props

    return (cart.isIkkatsu && schoolShipFlag) || (!cart.isIkkatsu && !schoolShipFlag)
  }

  isCompatibleWithCartShipmentSelflabo(cart: CartEntity | null, cartitemsCount: number): boolean {
    if (!cart) return false
    if (!cartitemsCount) return true // カートが空の時は常に正
    const { selflaboFlag } = this._props

    return (cart.isSelflabo && selflaboFlag) || (!cart.isSelflabo && !selflaboFlag)
  }

  getRemainingDays(from: string): number {
    const _from = dayjs(from, DATE_TIME_FORMAT)
    const _orderEndDay = dayjs(this._props.orderEndDt, DATE_TIME_FORMAT)
    const diff = _orderEndDay.diff(_from, 'day')
    return diff
  }

  get isPasswordLocked(): boolean {
    return !this._props.passwordUnlockFlag && this._props.passwordType === 'common'
  }

  get isEventClose(): boolean {
    return this._props.publishStatus === 'close'
  }
}

export const EventPropsFactory = (props?: Partial<IEventProps>): IEventProps => ({
  id: 1,
  name: '2月9日 冬のスペシャル犬キャンプ(引率スタッフ撮影)',
  eventStartDt: '2019-01-16 00:00:00.000',
  eventEndDt: '2019-01-17 00:00:00.000',
  orderEndDt: '2019-02-16 00:00:00.000',
  schoolShipFlag: false,
  sponsoredPrintFlag: false,
  not4saleFlag: false,
  moovinFlag: false,
  publishStatus: 'publish',
  notRepublicFlag: false,
  passwordUnlockFlag: true,
  passwordType: 'common',
  leadText: '思い出のお写真を是非お手元に！',
  defaultCaption: '冬のスペシャル犬キャンプ(引率スタッフ撮影) 2019年2月9日',
  announcementText:
    '<p>＜閲覧・購入に関するお問い合わせはこちら＞<br>カスタマーサポート<br>' +
    'TEL：03-5909-5880（営業時間：平日10時～18時 ※土日祝を除く）<br>' +
    'E-mail：support@photocreate.co.jp<br><br>＜撮影に関するご要望はこちら＞<br>' +
    '撮影:園の先生たち<br>TEL：000-000-0000</p>',
  selflaboFlag: false,
  not4postcardFlag: false,
  downloadSetFlag: false,
  spotlightFlag: false,
  ...props
})

export const EventEntityFactory = (props?: Partial<IEventProps>): EventEntity => {
  return new EventEntity(EventPropsFactory(props))
}
