import { Payment, Shipment } from '@/entities/Cart'
import { TupleToUnion } from '@/types/utils'

export const TAX_RATE = 0.1 // ceil(price * (1 + TAX_RATE) = total or floor(total / (1 + TAX_RATE)) = price
export const TPOINT_RATE = 200 // floor(price / TPOINT_RATE) = point

export const sex = {
  f: '女性',
  m: '男性'
} as const

export const FACE_THRESHOLD = 0.8

export const CREDIT_BRANDS = ['amex', 'diners', 'jcb', 'master', 'visa'] as const
export type CREDIT_BRAND = TupleToUnion<typeof CREDIT_BRANDS>

export const DEFAULT_SUBSCRIPTION_ID = 1

export const EMAIL_DOMAINS = [
  '@au.com',
  '@ab.auone-net.jp',
  '@aol.com',
  '@ae.auone-net.jp',
  '@ares.eonet.ne.jp',
  '@bma.biglobe.ne.jp',
  '@blue.plala.or.jp',
  '@beach.ocn.ne.jp',
  '@blue.ocn.ne.jp',
  '@bell.ocn.ne.jp',
  '@c.vodafone.ne.jp',
  '@cilas.net',
  '@coast.ocn.ne.jp',
  '@chic.ocn.ne.jp',
  '@cameo.plala.or.jp',
  '@docomo.ne.jp',
  '@disney.ne.jp',
  '@docomonet.jp',
  '@dream.jp',
  '@d.vodafone.ne.jp',
  '@ezweb.ne.jp',
  '@excite.co.jp',
  '@eos.ocn.ne.jp',
  '@email.plala.or.jp',
  '@etude.ocn.ne.jp',
  '@feel.ocn.ne.jp',
  '@fork.ocn.ne.jp',
  '@flute.ocn.ne.jp',
  '@friend.ocn.ne.jp',
  '@fa2.so-net.ne.jp',
  '@gmail.com',
  '@gaia.eonet.ne.jp',
  '@gol.com',
  '@grace.ocn.ne.jp',
  '@galaxy.ocn.ne.jp',
  '@hotmail.com',
  '@hotmail.co.jp',
  '@h.vodafone.ne.jp',
  '@hi3.enjoy.ne.jp',
  '@hera.eonet.ne.jp',
  '@i.softbank.jp',
  '@icloud.com',
  '@iris.eonet.ne.jp',
  '@infoseek.jp',
  '@iris.ocn.ne.jp',
  '@jcom.home.ne.jp',
  '@jcom.zaq.ne.jp',
  '@juno.ocn.ne.jp',
  '@jupiter.ocn.ne.jp',
  '@jeans.ocn.ne.jp',
  '@k.vodafone.ne.jp',
  '@kcc.zaq.ne.jp',
  '@khaki.plala.or.jp',
  '@kkh.biglobe.ne.jp',
  '@kind.ocn.ne.jp',
  '@live.jp',
  '@leto.eonet.ne.jp',
  '@labonetwork.co.jp',
  '@lapis.plala.or.jp',
  '@leaf.ocn.ne.jp',
  '@me.com',
  '@mineo.jp',
  '@msn.com',
  '@mac.com',
  '@maia.eonet.ne.jp',
  '@nifty.com',
  '@nike.eonet.ne.jp',
  '@nifty.ne.jp',
  '@n.vodafone.ne.jp',
  '@na.commufa.jp',
  '@outlook.jp',
  '@outlook.com',
  '@ozzio.jp',
  '@orion.ocn.ne.jp',
  '@oct-net.ne.jp',
  '@pony.ocn.ne.jp',
  '@plum.plala.or.jp',
  '@peace.ocn.ne.jp',
  '@par.odn.ne.jp',
  '@poem.ocn.ne.jp',
  '@q.vodafone.ne.jp',
  '@qq.com',
  '@qc.commufa.jp',
  '@quartz.ocn.ne.jp',
  '@qa2.so-net.ne.jp',
  '@rakuten.jp',
  '@r.vodafone.ne.jp',
  '@road.ocn.ne.jp',
  '@rainbow.plala.or.jp',
  '@rose.plala.or.jp',
  '@softbank.ne.jp',
  '@sky.plala.or.jp',
  '@sea.plala.or.jp',
  '@s.vodafone.ne.jp',
  '@snow.plala.or.jp',
  '@t.vodafone.ne.jp',
  '@tbz.t-com.ne.jp',
  '@tenor.ocn.ne.jp',
  '@tone.ne.jp',
  '@tg.commufa.jp',
  '@u01.gate01.com',
  '@uqmobile.jp',
  '@utopia.ocn.ne.jp',
  '@umin.ac.jp',
  '@uma.bbiq.jp',
  '@vega.ocn.ne.jp',
  '@vesta.ocn.ne.jp',
  '@ve.cat-v.ne.jp',
  '@violet.plala.or.jp',
  '@voice.ocn.ne.jp',
  '@willcom.com',
  '@wh.commufa.jp',
  '@wind.ocn.ne.jp',
  '@wine.ocn.ne.jp',
  '@wing.ocn.ne.jp',
  '@xj.commufa.jp',
  '@xa2.so-net.ne.jp',
  '@xb3.so-net.ne.jp',
  '@xc4.so-net.ne.jp',
  '@xd5.so-net.ne.jp',
  '@yahoo.co.jp',
  '@yahoo.ne.jp',
  '@ybb.ne.jp',
  '@ymobile.ne.jp',
  '@yahoo.com'
]

export const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県'
]

export const RELATIONS = {
  MO: 'mother',
  FA: 'father',
  GM: 'grandmother',
  GF: 'grandfather',
  AU: 'aunt',
  UN: 'uncle',
  MS: 'myself',
  ED: 'educators',
  OT: 'others'
}

export const RELATIONSHIPS: Record<string, { key: string; name: string }> = {
  1: {
    key: 'mother',
    name: '母'
  },
  2: {
    key: 'father',
    name: '父'
  },
  4: {
    // 3:祖母（母方） 父方と母方統合
    key: 'grandmother',
    name: '祖母'
  },
  6: {
    // 5:祖父（母方） 父方と母方統合
    key: 'grandfather',
    name: '祖父'
  },
  8: {
    // 7:おば（母方） 父方と母方統合
    key: 'aunt',
    name: 'おば'
  },
  10: {
    // 9:おじ（母方） 父方と母方統合
    key: 'uncle',
    name: 'おじ'
  },
  11: {
    key: 'myself',
    name: '本人'
  },
  12: {
    key: 'educators',
    name: 'スクール関係者'
  },
  13: {
    key: 'others',
    name: 'その他'
  }
}

export const PAYMENT_CODE: { [key in Payment['code']]: { name: string; description: string; fee: string } } = {
  credit: {
    name: 'クレジット決済',
    description: '',
    fee: '0'
  },
  paidy: {
    name: 'Paidy翌月払い（コンビニ・銀行）',
    description: '',
    fee: ''
  },
  np_wiz: {
    name: '後払い',
    description: '',
    fee: '220'
  },
  tpoint: {
    name: 'Tポイント利用',
    description: '',
    fee: ''
  },
  bank: {
    name: '銀行振込',
    description: '',
    fee: ''
  },
  cod: {
    name: '代金引換',
    description: '',
    fee: '370'
  },
  paypay: {
    name: 'PayPay',
    description: '',
    fee: '0'
  }
}

// TODO: 要改善
export const SHIPMENT_CODE: { [key in Shipment['code']]: { name: string; description: string; fee: string } } = {
  ikkatsu: {
    name: 'お子さまの園・学校へお届け',
    description: '',
    fee: ''
  },
  mailbin: {
    name: 'メール便',
    description: 'お客さまの捺印・署名をいただかず、郵便受け等に投函されます。手渡しや不在票の投函はできません。',
    fee: '187'
  },
  takuhai: {
    name: '宅配便',
    description: '配達時に受領確認の捺印・署名が必要な配送方法です。',
    fee: '770'
  },
  cod: {
    name: '代金引換',
    description: '配達時に受領確認の捺印・署名が必要な配送方法です。',
    fee: '370'
  },
  download: {
    name: 'ダウンロード',
    description: '',
    fee: ''
  }
}

export const INQUIRY_CONTENTS: Record<string, { name: string; template: string; categoryIds: number[] }> = {
  registration: {
    name: '新規会員登録について',
    template: '【エラーメッセージ】\n【操作内容】',
    categoryIds: [360001627512]
  },
  tpoint_cooperation: {
    name: 'Tポイント連携について',
    template: '【エラーメッセージ】\n【操作内容】',
    categoryIds: [360001627512, 360001626191]
  },
  login: {
    name: 'ログインについて',
    template: '【スクール名】\n【都道府県（スクール）】\n【エラーメッセージ】\n【操作内容】',
    categoryIds: [360001626191]
  },
  password: {
    name: 'スクールパス・閲覧パスワードについて',
    template: '【スクール名】\n【都道府県（スクール）】\n【イベント名】\n【開催日】\n【開催都道府県】\n【お問い合わせ内容】',
    categoryIds: [360001626191]
  },
  photo_deletion: {
    name: '写真非掲載の依頼',
    template: '【被写体とのご関係】\n【スクール名】\n【イベント名】\n【イベントID（Eで始まる番号）】\n【開催日】\n【写真番号】\n【非掲載理由】',
    categoryIds: [360001626191, 19781031]
  },
  item: {
    name: '商品について',
    template: '【スクール名】\n【都道府県（スクール）】\n【お問い合わせ内容】',
    categoryIds: [360001626191]
  },
  operation: {
    name: '操作方法について',
    template: '【開催日】\n【イベント名】\n【スクール名】\n【都道府県（スクール）】\n【スクールパス・閲覧パスワード】\n【エラーメッセージ】\n【操作内容】',
    categoryIds: [360001626191]
  },
  publish: {
    name: '写真掲載について',
    template: '【開催日】\n【開催都道府県】\n【イベント名】\n【スクール名】\n【スクールパス・閲覧パスワード】\n【エラーメッセージ】\n【お問い合わせ内容】',
    categoryIds: [360001626191]
  },
  delivery: {
    name: '配送について',
    template: '【スクール名】\n【都道府県（スクール）】\n【イベント名】\n【お問い合わせ内容】',
    categoryIds: [360001627572]
  },
  after_delivery: {
    name: '配送について（注文後）',
    template: '【注文者さまのお名前】\n【注文時に頂いたお電話番号】\n【注文時に頂いたメールアドレス】\n【お問い合わせ内容】',
    categoryIds: [360001627572]
  },
  payment: {
    name: 'お支払いについて',
    template: '【スクール名】\n【都道府県（スクール）】\n【イベント名】\n【お問い合わせ内容】',
    categoryIds: [360001627572]
  },
  after_payment: {
    name: 'お支払いについて（注文後）',
    template: '【注文者さまのお名前】\n【注文時に頂いたお電話番号】\n【注文時に頂いたメールアドレス】\n【お問い合わせ内容】',
    categoryIds: [360001627572]
  },
  np_payment: {
    name: 'NP後払いについて',
    template: '【スクール名】\n【都道府県（スクール）】\n【イベント名】\n【お問い合わせ内容】',
    categoryIds: [360001627572]
  },
  after_np_payment: {
    name: 'NP後払いについて（注文後）',
    template: '【注文者さまのお名前】\n【注文時に頂いたお電話番号】\n【注文時に頂いたメールアドレス】\n【お問い合わせ内容】',
    categoryIds: [360001627572]
  },
  item_quality: {
    name: '商品の品質について',
    template: '【注文者さまのお名前】\n【注文時に頂いたお電話番号】\n【注文時に頂いたメールアドレス】\n【お問い合わせ内容】',
    categoryIds: [360001627592]
  },
  about_order: {
    name: '注文について',
    template: '【注文者さまのお名前】\n【注文時に頂いたお電話番号】\n【注文時に頂いたメールアドレス】\n【お問い合わせ内容】',
    categoryIds: [360001627592]
  },
  download: {
    name: 'ダウンロードについて',
    template: '【注文者さまのお名前】\n【注文時に頂いたお電話番号】\n【注文時に頂いたメールアドレス】\n【エラーメッセージ】\n【操作内容】',
    categoryIds: [360001627592]
  },
  tpoint: {
    name: 'Tポイントについて',
    template: '【注文者さまのお名前】\n【注文時に頂いたお電話番号】\n【注文時に頂いたメールアドレス】\n【お問い合わせ内容】',
    categoryIds: [360001627592]
  },
  enquete: {
    name: 'アンケートについて',
    template: '【注文者さまのお名前】\n【注文時に頂いたお電話番号】\n【注文時に頂いたメールアドレス】\n【お問い合わせ内容】',
    categoryIds: [360001627592]
  }
}

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSS'
export const DATE_FORMAT = 'YYYY-MM-DD'
