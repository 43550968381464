






import Vue from 'vue'
import { oneOf } from '@/utils/helper'

export default Vue.extend({
  props: {
    to: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    target: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '',
      validator: oneOf(['', 'orange'])
    },
    external: {
      type: Boolean,
      default: false
    }
  }
})
