import { PropOptions } from 'vue'

export function oneOf(keys: string[]): PropOptions['validator'] {
  return value => {
    return keys.indexOf(value) !== -1
  }
}

export function calcSchoolYear(year: number, month: number) {
  if (year <= 0 || month <= 0 || 13 <= month) {
    throw new Error('Out of range!')
  }

  return month >= 4 && month <= 12 ? year : year - 1
}

// Deprecated
// Returns correctly typed indexer type array
export function ObjectKeys<T>(obj: object): T[] {
  return Object.keys(obj) as any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const range = (min: number, max: number): number[] => {
  if (min > max) {
    throw new Error('The first argument must be smaller than the second arg.')
  }

  if (min === max) {
    throw new Error('Both of the given arguments must not be identical to each other.')
  }

  const nums: number[] = []
  for (let i = min; i <= max; i += 1) {
    nums.push(i)
  }
  return nums
}

// EnableCheckFlgがtrueのform fieldのみを抽出するヘルパー
export const extractTargetFields = <T>(fields: T, enableCheckFlg: { [key in keyof T]: boolean }): Partial<T> => {
  const fieldNames = Object.keys(fields) as (keyof T)[]
  const enabledFields = fieldNames.filter(name => enableCheckFlg[name])
  const targetFields = enabledFields.reduce((acc: Partial<T>, ac) => ((acc[ac] = fields[ac]), acc), {})
  return targetFields
}

export const sortBy = <T extends object>(key: keyof T) => (a: T, b: T) => {
  const keyOfA = a[key]
  const keyofB = b[key]

  return keyOfA === keyofB ? 0 : keyOfA > keyofB ? 1 : -1
}

export const pluck = <T extends object, K extends keyof T>(data: T[], key: K): T[K][] => data.map(item => item[key])

export const uniq = <T>(arr: T[]) => arr.filter((elm, i, self) => self.indexOf(elm) === i)

// filter関数に渡すためのuser defined type-guard
export const nonNullable = <T>(arg: T | null): arg is T => arg !== null

/* eslint-disable @typescript-eslint/no-explicit-any */
export function pipe<T1>(first: (...args: any[]) => T1): T1
export function pipe<T1, T2>(first: (...args: any[]) => T1, second: (a: T1) => T2): T2
export function pipe<T1, T2, T3>(first: (...args: any[]) => T1, second: (a: T1) => T2, third: (a: T2) => T3): T3
export function pipe<T1, T2, T3, T4>(first: (...args: any[]) => T1, second: (a: T1) => T2, third: (a: T2) => T3, fourth: (a: T3) => T4): T4
export function pipe<T1, T2, T3, T4, T5>(first: (...args: any[]) => T1, second: (a: T1) => T2, third: (a: T2) => T3, fourth: (a: T3) => T4, fifth: (a: T4) => T5): T5
export function pipe<T1, T2, T3, T4, T5, T6>(first: (...args: any[]) => T1, second: (a: T1) => T2, third: (a: T2) => T3, fourth: (a: T3) => T4, fifth: (a: T4) => T5, sixth: (a: T5) => T6): T6
export function pipe<T1, T2, T3, T4, T5, T6, T7>(
  first: (...args: any[]) => T1,
  second: (a: T1) => T2,
  third: (a: T2) => T3,
  fourth: (a: T3) => T4,
  fifth: (a: T4) => T5,
  sixth: (a: T5) => T6,
  seventh: (a: T6) => T7
): T7
export function pipe<T1, T2, T3, T4, T5, T6, T7, T8>(
  first: (...args: any[]) => T1,
  second: (a: T1) => T2,
  third: (a: T2) => T3,
  fourth: (a: T3) => T4,
  fifth: (a: T4) => T5,
  sixth: (a: T5) => T6,
  seventh: (a: T6) => T7,
  eighth: (a: T7) => T8
): T8
export function pipe(first: Function, ...args: Function[]): any {
  return args && args.length ? args.reduce((result, next) => next(result), first()) : first()
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/* istanbul ignore next */
export const isMobile = typeof window !== 'undefined' && typeof window.matchMedia === 'function' && window.matchMedia('(max-width: 768px)').matches

/* istanbul ignore next */
export const isDesktop = typeof window !== 'undefined' && typeof window.matchMedia === 'function' && window.matchMedia('(min-width: 769px)').matches

/**
 * Curried functions
 * TODO: genericなcurrying functionを実装する
 */

/* istanbul ignore next */
export const curriedPluck = <T extends object, K extends keyof T>(key: K) => (arr: T[]) => pluck(arr, key)

/* istanbul ignore next */
export const curriedSome = <T, F extends (arg: T) => boolean>(fn: F) => (arr: T[]) => arr.some(fn)

/* istanbul ignore next */
export const curriedMap = <T, F extends (arg: T) => any>(fn: F) => (arr: T[]) => arr.map<ReturnType<F>>(fn) // eslint-disable-line @typescript-eslint/no-explicit-any

/* istanbul ignore next */
export const curriedReduce = <T, P extends T, F extends (previousValue: T, currentValue: T, currentIndex?: number, array?: T[]) => T>(fn: F, initialValue: T) => (arr: P[]) =>
  arr.reduce(fn, initialValue)
