import { IPriceProps } from '@/entities/Price'
import { IEventPhotoPrices } from '@/types/domain/event'

export const Types = {
  STORE_PRICES: 'event/store_prices',
  STORE_EVENT_PHOTO_PRICES: 'event/store_event_photo_prices',
  CLEAR_EVENT_PHOTO_PRICES: 'event/clear_event_photo_prices'
}

export class StorePrices implements FluxStandardAction {
  type = Types.STORE_PRICES
  constructor(public payload: { photoId: number; prices: IPriceProps[] }) {}
}

export class StoreEventPhotoPrices implements FluxStandardAction {
  type = Types.STORE_EVENT_PHOTO_PRICES
  constructor(public payload: IEventPhotoPrices[]) {}
}

export class ClearEventPhotoPrices implements FluxStandardAction {
  type = Types.CLEAR_EVENT_PHOTO_PRICES
}
