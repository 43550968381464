




import Vue from 'vue'
import { isDOMAccessible } from '@/utils/dom'

interface IData {
  initialized: boolean
}

export default Vue.extend({
  data(): IData {
    return {
      initialized: false
    }
  },
  computed: {
    isChatBotRoute(): boolean {
      return !!this.$route.meta.showChatBot
    }
  },
  watch: {
    isChatBotRoute: {
      immediate: true,
      handler(val: boolean) {
        if (!this.initialized) return
        if (val) {
          this.show()
        } else {
          this.hide()
        }
      }
    },
    initialized: {
      immediate: true,
      handler(val: IData['initialized']) {
        if (!val) return

        // 初期化処理はroute metaの値に関わらず全てのページで行うため、
        // 初期化完了時にisChatBotRoute == falseのページでは非表示処理を行う
        if (!this.isChatBotRoute) this.hide()
      }
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      if (!isDOMAccessible()) return

      // eslint-disable-next-line
      if (window.ul_widget === void 0) {
        window.ULObject = 'ul_widget'
        window.ul_widget =
          window.ul_widget ||
          function () {
            // eslint-disable-next-line
            ;(window.ul_widget.q = window.ul_widget.q || []).push(arguments)
          }
        window.ul_widget.l = new Date().getTime()
        const el = document.createElement('script')
        el.async = true
        el.src = 'https://support-widget.nakanohito.jp/chatbot.js'
        const target = document.getElementsByTagName('script')[0]
        if (!target.parentNode) return
        target.parentNode.insertBefore(el, target)
      }
      ul_widget('init', { id: 544 })
      this.initialized = true
    },
    hide() {
      ul_widget('hide')
    },
    show() {
      ul_widget('show')
    }
  }
})
