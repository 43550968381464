export interface IEventCampaignField {
  id: string
  label: string
  fieldType: 'radio' | 'check'
  required: boolean
  choices: {
    label: string
    value: string
  }[]
}

export interface IEventCampaignProps {
  id: number
  name: string
  campaignCode: string
  targetPrefectures: string[]
  fields: IEventCampaignField[]
}

export default class EventCampaignEntity {
  private _props: IEventCampaignProps

  constructor(props: IEventCampaignProps) {
    this._props = props
  }

  get props(): IEventCampaignProps {
    return this._props
  }
}

export const EventCampaignFieldFactory = (props?: Partial<IEventCampaignField>): IEventCampaignField => {
  return {
    id: 'caption1',
    label: '性別',
    fieldType: 'radio',
    required: true,
    choices: [
      {
        label: '男',
        value: 'male'
      },
      {
        label: '女',
        value: 'female'
      },
      {
        label: 'その他',
        value: 'others'
      }
    ],
    ...props
  }
}

export const EventCampaignPropsFactory = (props?: Partial<IEventCampaignProps>): IEventCampaignProps => {
  return {
    id: 100,
    campaignCode: 'cd02',
    name: '春の大感謝祭',
    targetPrefectures: ['東京都', '北海道'],
    fields: [
      EventCampaignFieldFactory(),
      EventCampaignFieldFactory({
        id: 'caption1',
        label: '地域',
        choices: [
          {
            label: '西日本',
            value: 'west-jp'
          },
          {
            label: '東日本',
            value: 'east-jp'
          }
        ]
      })
    ],
    ...props
  }
}

export const EventCampaignEntityFactory = (props?: Partial<IEventCampaignProps>): EventCampaignEntity => {
  return new EventCampaignEntity(EventCampaignPropsFactory(props))
}
