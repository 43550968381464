import { MutationTree } from 'vuex'
import { IFamilyState } from '@/store/modules/family/state'
import { Types, StoreFamilies, StoreCurrentFamily } from '@/store/modules/family/types'

export const mutations: MutationTree<IFamilyState> = {
  [Types.STORE_FAMILIES]: (state, action: StoreFamilies) => {
    const families = action.payload
    families.forEach(family => {
      state.byIds = {
        ...state.byIds,
        [family.id]: family
      }
    })
  },
  [Types.STORE_CURRENT_FAMILY]: (state, action: StoreCurrentFamily) => {
    const cureentFamily = action.payload
    state.currentFamily = cureentFamily
  }
}
